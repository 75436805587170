import {Typography} from '@mui/material';
import MainLayout from "../components/MainLayout";
import {useTranslation} from "react-i18next";
import TerminalsContainer from "../components/terminals/TerminalsContainer";

const TerminalsPage = () => {
    const{t} = useTranslation();
    return <MainLayout>
        <Typography
            variant={'h5'}
            mb={2}
            sx={{ marginLeft: '24px'}}
        >
            {t('terminals.title')}</Typography>
        <TerminalsContainer />
    </MainLayout>
}

export default TerminalsPage;