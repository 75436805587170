import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const ExpandableContacts = ({ data }) => {
    const { t } = useTranslation();
    const contacts = data.contact ? Object.entries(data.contact) : [];

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6">{t('billing.clients.contact.title')}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3}}>
                {contacts.length > 0 ? (
                    contacts.map(([type, value], index) => (
                        <Box key={index}>
                            <Typography><strong>{t('billing.clients.contact.' + type)}:</strong> {value}</Typography>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body2">{t('billing.clients.noContacts')}</Typography>
                )}
            </Box>
        </Box>
    );
};

export default ExpandableContacts;