import axios from "../config/axios";

const BillingFarmService = {

    fetchAllFarms: async () => {
        try {
            const response = await axios.get(`/billing/farms`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    fetchFarmById: async (id) => {
        try {
            const response = await axios.get(`/billing/farms/${id}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    updateFarm: async (id, farm) => {
        try {
            const response = await axios.put(`/billing/farms/${id}`, farm);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    createFarm: async (farm) => {
        try {
            const response = await axios.post(`/billing/farms`, farm);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    fetchFarmcontrolFarms: async () => {
        try {
            const response = await axios.get(`/billing/farms/farmcontrolFarms`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    fetchFarmsWithoutBilling: async () => {
        try {
            const response = await axios.get(`/billing/farms/without-billing`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    changeStatusFarm: async (id) => {
        try {
            const response = await axios.put(`/billing/farms/status/${id}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },
};

export default BillingFarmService;