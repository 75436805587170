import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import AuxTableService from "../../../services/AuxTableService";
import ExportService from "../../../services/ExportService";
import {Alert, Box, Button, Snackbar, TextField} from "@mui/material";
import SelectField from "../../form/SelectField";
import ExportButton from "../../form/ExportButton";
import {Add} from "@mui/icons-material";
import DataTableComponent from "../../dataTable/DataTableComponent";
import {getColumns} from "./config";
import BillingProductService from "../../../services/BillingProductService";
import BillingPlanService from "../../../services/BillingPlanService";
import BillingPlanModal from "./BillingPlanModal";
import GenericDialog from "../../form/Dialog/GenericDialog";
import Loading from "../../Loading";

const BillingPlansContainer = () => {
    const { t } = useTranslation();
    const newPlan = {
        code: '',
        description: '',
        product: '',
        saasSaleType: '',
        period: '',
        price: 0.0,
        discountPercentage: 0.0,
        valueMonthly: 0.0,
        valueYearly: 0.0,
        observations: ''
    }
    const [plans, setPlans] = useState([]);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [codeFilter, setCodeFilter] = useState('');
    const [descriptionFilter, setDescriptionFilter] = useState('');
    const [productFilter, setProductFilter] = useState('');
    const [plan, setPlan] = useState(newPlan);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);
    const [saasSaleTypes, setSaasSaleTypes] = useState([]);
    const [billingPeriods, setBillingPeriods] = useState([]);
    const [products, setProducts] = useState([]);
    const [changeStatusDialog, setChangeStatusDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPlans();
        fetchProducts();
        fetchPeriods();
        fetchSaasSaleTypes();
    }, []);

    useEffect(() => {
        filterPlans();

        // eslint-disable-next-line
    }, [codeFilter, descriptionFilter, productFilter, plans]);

    const fetchPeriods = async () => {
        try {
            const response = await AuxTableService.fetchPeriods();
            setBillingPeriods(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching Periods:', error);
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await BillingProductService.fetchAllProducts();
            setProducts(response.filter(product => product.status === 'ACTIVE').sort((a, b) => a.code.localeCompare(b.code)));
        } catch (error) {
            console.error('Error fetching Billing products:', error);
        }
    };

    const fetchSaasSaleTypes = async () => {
        try {
            const response = await AuxTableService.fetchAllSaasSalesTypes();
            setSaasSaleTypes(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching Billing Saas Sale Types:', error);
        }
    };

    const fetchPlans = async () => {
        try {
            setLoading(true)
            const response = await BillingPlanService.fetchAllPlans();
            const sortedPlans = response.sort((a, b) => a.code.localeCompare(b.code));
            setPlans(sortedPlans);
            setFilteredPlans(sortedPlans);
        } catch (error) {
            console.error('Error fetching Hw Inventory audit:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleEditClick = (plan) => {
        setPlan(plan);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleChangeStatusClick = (plan) => {
        setPlan(plan);
        setChangeStatusDialog(true);
    };

    const changeStatusPlan = async () => {
        try {
            await BillingPlanService.changeStatusPlan(plan?.code);
            setSnackbarOpen({open: true, severity: 'success', text: t('billing.plans.status.saveSuccess')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('billing.plans.status.saveFailure')});
            console.error('Error changing status of plan :', error);
        }
    };

    const handleChangeStatus = () => {
        changeStatusPlan()
            .then(async () => {
                setChangeStatusDialog(false);
                await fetchPlans();
            })
            .catch((error) => {
                console.error('Error in handleChangeStatus:', error)
            });
    }

    const handleAddNewClick = () => {
        setPlan(newPlan);
        setIsEditing(false);
        setModalOpen(true);
    };

    const handleSave = async (planToStore) => {
        setPlan(newPlan);
        setModalOpen(false);
        if(isEditing){
            try {
                await BillingPlanService.updatePlan(planToStore.code, planToStore);
            } catch (error) {
                console.error('Error updating plan:', error);
            }
        } else {
            try {
                await BillingPlanService.createPlan(planToStore);
            } catch (error) {
                console.error('Error adding new plan:', error);
            }
        }

        fetchPlans()
        setSnackbarOpen({open: true, severity: 'success', text: t('billing.plans.saveSuccess')});
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const filterPlans = () => {
        let filtered = plans;

        if (codeFilter) {
            filtered = filtered.filter(plan => plan.code.toLowerCase().includes(codeFilter.toLowerCase()));
        }

        if (descriptionFilter) {
            filtered = filtered.filter(plan => plan.description.toLowerCase().includes(descriptionFilter.toLowerCase()));
        }

        if (productFilter) {
            filtered = filtered.filter(plan => plan.product === productFilter)
        }

        setFilteredPlans(filtered);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPlan({ ...plan, [name]: value });
    };

    const handleClearFilters = () => {
        setCodeFilter('');
        setDescriptionFilter('');
        setProductFilter('');
    };

    const handleExport = (format) => {
        const dataToExport = filteredPlans;
        const fileName = 'billing_plans';

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }

    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2 }}>
                    <SelectField
                        label={t('billing.plans.code')}
                        value={codeFilter}
                        handleChange={(e) => setCodeFilter(e.target.value)}
                        items={plans.map(plan => ({
                            value: plan.code,
                            text: plan.code,
                        }))}
                    />
                    <TextField
                        label={t('billing.plans.description')}
                        value={descriptionFilter}
                        onChange={(e) => setDescriptionFilter(e.target.value)}
                    />
                    <SelectField
                        label={t('billing.plans.product')}
                        value={productFilter}
                        handleChange={(e) => setProductFilter(e.target.value)}
                        items={products.map(product => ({
                            value: product.code,
                            text: product.name,
                        }))}
                    />

                    <Button variant="contained" color="primary" onClick={handleClearFilters}>
                        {t('generic.clearFilter')}
                    </Button>

                    <ExportButton onExport={handleExport} />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewClick}
                    startIcon={<Add />}
                    sx={{ padding: '10px 20px', fontSize: '16px' }}
                >
                    {t('generic.addNew')}
                </Button>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <DataTableComponent columns={getColumns(t, handleEditClick, handleChangeStatusClick)} data={filteredPlans} pagination />
            )}

            <BillingPlanModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                isEditing={isEditing}
                plan={plan}
                products={products}
                saasSaleTypes={saasSaleTypes}
                periods={billingPeriods}
                handleInputChange={handleInputChange}
                handleSave={handleSave}
            />
            <GenericDialog
                open={changeStatusDialog}
                handleClose={() => setChangeStatusDialog(false)}
                handleConfirm={handleChangeStatus}
                title={t('billing.plans.status.dialog.title')}
                text={`${t('billing.plans.status.dialog.text')} ${t(plan.isActive ? 'generic.inactive' : 'generic.active')}.`}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{width: '100%'}}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BillingPlansContainer;