import React from 'react'
import DataTable from 'react-data-table-component';
import {useTranslation} from "react-i18next";

const DataTableComponent = (
    {
        columns,
        data,
        pagination,
        onRowClicked,
        expandableRows,
        expandableRowExpanded,
        expandableRowsComponent,
        customStyles = {
            rows: {
                style: {
                    cursor: onRowClicked ? 'pointer' : 'default',
                },
            },
        },
        noDataComponent = 'generic.tableData.noItems',
    }) => {
    const {t} = useTranslation();


    return (
        <DataTable
            columns={columns}
            data={data}
            pagination={pagination}
            noDataComponent={t(noDataComponent)}
            paginationComponentOptions={{
                rowsPerPageText: t('generic.tableData.rowsPerPage')
            }}
            onRowClicked={onRowClicked}
            customStyles={customStyles}
            expandableRows={expandableRows}
            expandableRowExpanded={expandableRowExpanded}
            expandableRowsComponent={expandableRowsComponent}
        />
    )
}

export default DataTableComponent