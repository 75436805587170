import React from 'react';
import {Typography} from "@mui/material";
import DisconnectedTerminalList from "./DisconnectedTerminalList";
import {useTranslation} from "react-i18next";

const DisconnectedTerminalsContainer = () => {
    const {t} = useTranslation()
    return (
        <>
            <Typography variant={'h5'} mb={2}>{t('dashboard.disconnectedTerminals.title')}</Typography>
            <DisconnectedTerminalList />
        </>
    )
}

export default DisconnectedTerminalsContainer