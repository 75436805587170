import {TextField, Box, Button, Modal, Typography, Switch, FormControlLabel} from "@mui/material";
import SelectField from "../../form/SelectField";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

const HwComponentModal = ({ open, handleClose, isEditing, hwComponent, hwSuppliers, saasSalesTypes, handleInputChange, handleTerminalSwitchChange, handleSave }) => {
    const {t} = useTranslation();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid = hwComponent.hardware && hwComponent.hwSupplier && hwComponent.saasSalesType;

        setIsSaveDisabled(!isFormValid);
    }, [hwComponent]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%', borderRadius: 2, boxShadow: 24 }}>
                <Typography variant={"h3"} sx={{ marginBottom: 2, textAlign: 'center' }}>
                    {isEditing ? t('billing.hw.components.editHwComponent') : t('billing.hw.components.newHwComponent')}
                </Typography>
                <TextField
                    name="hardware"
                    label={t('billing.hw.components.hardware')}
                    value={hwComponent.hardware}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={isEditing}
                    sx={{m: 1}}
                />
                <SelectField
                    name="hwSupplier"
                    label={t('billing.hw.components.hwSupplier')}
                    value={hwComponent.hwSupplier}
                    handleChange={handleInputChange}
                    items={hwSuppliers.map(hwSupplier => ({
                        value: hwSupplier.name,
                        text: hwSupplier.name,
                    }))}
                    fullWidth
                    sx={{m: 1}}
                />
                <SelectField
                    name="saasSalesType"
                    label={t('billing.hw.components.saasSalesType')}
                    value={hwComponent.saasSalesType}
                    handleChange={handleInputChange}
                    items={saasSalesTypes.map(saasSalesType => ({
                        value: saasSalesType.name,
                        text: saasSalesType.name,
                    }))}
                    fullWidth
                    sx={{m: 1}}
                />
                <TextField
                    name="observations"
                    label={t('billing.hw.components.observations')}
                    value={hwComponent.observations}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{m: 1}}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={hwComponent.terminal}
                            onChange={handleTerminalSwitchChange}
                            name="terminal"
                            color="primary"
                        />
                    }
                    label={t('billing.hw.components.terminal')}
                    sx={{ m: 1 }}
                />
                <Box sx={{ gap: 4}}>
                    <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120}} disabled={isSaveDisabled}>
                        {t('generic.save')}
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120, backgroundColor: 'gray',
                        color: 'white'}}>
                        {t('generic.cancel')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default HwComponentModal;