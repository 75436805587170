import { useTranslation } from "react-i18next";
import TerminalService from "../../services/TerminalService";
import React, { useCallback, useEffect, useState } from "react";
import Loading from "../Loading";
import { getColumns } from "./config";
import { Box, Button, TextField } from "@mui/material";
import DataTableComponent from "../dataTable/DataTableComponent";
import SelectField from "../form/SelectField";

const TerminalsContainer = () => {
    const { t } = useTranslation();
    const [terminals, setTerminals] = useState([]);
    const [filteredTerminals, setFilteredTerminals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState('');
    const [profileFilter, setProfileFilter] = useState([]);
    const [terminalStatusFilter, setTerminalStatusFilter] = useState([]);
    const [connectionStatusFilter, setConnectionStatusFilter] = useState('');

    const fetchTerminals = async () => {
        try {
            setLoading(true);
            const response = await TerminalService.fetchTerminals();
            const sortedTerminals = response.sort((a, b) => new Date(b.lastComm) - new Date(a.lastComm));
            setTerminals(sortedTerminals);
        } catch (error) {
            console.error('Error fetching terminals: ', error);
        } finally {
            setLoading(false);
        }
    };

    const filterTerminals = useCallback(() => {
        let filtered = terminals;
        const lowerCaseSearchFilter = searchFilter.toLowerCase();

        if (searchFilter) {
            filtered = filtered.filter(terminal =>
                terminal.displayName.toLowerCase().includes(lowerCaseSearchFilter) ||
                terminal.farm.toLowerCase().includes(lowerCaseSearchFilter) ||
                terminal.serialNumber.toLowerCase().includes(lowerCaseSearchFilter)
            );
        }

        if (profileFilter.length > 0) {
            filtered = filtered.filter(terminal => profileFilter.includes(terminal.profile));
        }

        if (terminalStatusFilter.length > 0) {
            filtered = filtered.filter(terminal => terminalStatusFilter.includes(terminal.terminalStatus));
        }

        if (connectionStatusFilter !== '') {
            const isConnected = connectionStatusFilter === 'true';
            filtered = filtered.filter(terminal => terminal.connectionStatus === isConnected);
        }

        setFilteredTerminals(filtered);
    }, [terminals, searchFilter, profileFilter, terminalStatusFilter, connectionStatusFilter]);

    const handleClearFilters = () => {
        setSearchFilter('');
        setProfileFilter([]);
        setTerminalStatusFilter([]);
        setConnectionStatusFilter('');
    };

    const handleProfileChange = (event) => {
        const { target: { value } } = event;
        setProfileFilter(typeof value === 'string' ? value.split(',') : value);
    };

    const handleTerminalStatusChange = (event) => {
        const { target: { value } } = event;
        setTerminalStatusFilter(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        fetchTerminals();
    }, []);

    useEffect(() => {
        filterTerminals();
    }, [filterTerminals]);

    const uniqueProfiles = [...new Set(terminals.map(terminal => terminal.profile))];
    const uniqueTerminalStatuses = [...new Set(terminals.map(terminal => terminal.terminalStatus))];

    const terminalStatusItems = uniqueTerminalStatuses.map(status => ({
        value: status,
        text: t(`terminals.statuses.${status}`)
    }));

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, margin: 1, alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <TextField
                            label={t('generic.search')}
                            value={searchFilter}
                            onChange={(e) => setSearchFilter(e.target.value)}
                            fullWidth
                            sx={{m: 1, minWidth: 300}}
                        />

                        <SelectField
                            label={t('terminals.profile')}
                            name="profile"
                            value={profileFilter}
                            handleChange={handleProfileChange}
                            items={uniqueProfiles.map(profile => ({ value: profile, text: profile }))}
                            multiple
                            fullWidth
                        />

                        <SelectField
                            label={t('terminals.terminalStatus')}
                            name="terminalStatus"
                            value={terminalStatusFilter}
                            handleChange={handleTerminalStatusChange}
                            items={terminalStatusItems}
                            multiple
                            fullWidth
                        />

                        <SelectField
                            label={t('terminals.connectionStatus')}
                            name="connectionStatus"
                            value={connectionStatusFilter}
                            handleChange={(e) => setConnectionStatusFilter(e.target.value)}
                            items={[
                                { value: "true", text: t('terminals.connected') },
                                { value: "false", text: t('terminals.disconnected') }
                            ]}
                            fullWidth={false}
                        />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" color="primary" onClick={handleClearFilters}>
                    {t('generic.clearFilter')}
                </Button>
            </Box>

            {loading ? (
                <Loading />
            ) : (
                <DataTableComponent
                    columns={getColumns(t)}
                    data={filteredTerminals}
                    pagination
                    expandableRows
                />
            )}
        </Box>
    );
};

export default TerminalsContainer;