import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthService from '../services/AuthService';
import {TextField, Button, Typography, Box, Alert, Grid} from '@mui/material';
import SelectField from "../components/form/SelectField";
import farmLogo from "../assets/farm-logo.svg";
import {UserRoles} from "../enums/UserRoles";


const SignUpPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState('pt-PT');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLanguageChange = (event) => {
        const { value } = event.target;
        setLanguage(value);
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        setSuccessMessage('');
        setErrorMessage('');
        try {
            await AuthService.register(username, password, email, [UserRoles.ROLE_USER], language);
            setSuccessMessage('Registration successful! Please ask for account verification. Redirecting to login page...');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            console.error('Registration failed:', error);
            setErrorMessage('Registration failed. Please try again.');
            setIsSubmitting(false);
        }
    };

    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid item xs={12} sm={8} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4 }}>
                <Typography component="h1" variant="h3" sx={{ mb: 4 }} className={"c-primary"}>
                    Backoffice
                </Typography>
                <Box className={"w-500 h-180"}>
                    <img alt="farm-logo" src={farmLogo}/>
                </Box>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <Box component="form" onSubmit={handleRegister} sx={{ width: '100%', maxWidth: 400, mt: 2 }}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{ m: 1 }}
                    />
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ m: 1 }}
                    />
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ m: 1 }}
                    />
                    <SelectField
                        label="Language"
                        name="language"
                        value={language}
                        handleChange={handleLanguageChange}
                        items={[
                            { value: 'en-EN', text: 'English' },
                            { value: 'pt-PT', text: 'Português' },
                            { value: 'es-ES', text: 'Español' }
                        ]}
                        fullWidth
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ m: 1}}
                        disabled={isSubmitting}
                    >
                        Register
                    </Button>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            Already have an account? <Link to="/login">Login</Link>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={false} sm={4} md={4} className="c-bg-farm">

            </Grid>
        </Grid>
    );
}

export default SignUpPage;