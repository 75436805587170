import {TextField, Box, Button, Modal, Typography} from "@mui/material";
import SelectField from "../../form/SelectField";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {NumericFormat} from "react-number-format";

const BillingSaasModal = ({ open, handleClose, isEditing, saasBilling, farms, plans, handleInputChange, handleCurrencyChange, handleDateInputChange, handleSave }) => {
    const {t} = useTranslation();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid = saasBilling.farmId && saasBilling.planCode &&
            saasBilling.beginDate && saasBilling.endDate && saasBilling.quantity;

        setIsSaveDisabled(!isFormValid);
    }, [saasBilling]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%', borderRadius: 2, boxShadow: 24 }}>
                <Typography variant={"h3"} sx={{ marginBottom: 2, textAlign: 'center' }}>
                    {isEditing ? t('billing.saas.editSaasBilling') : t('billing.saas.newSaasBilling')}
                </Typography>
                <SelectField
                    name="farmId"
                    label={t('billing.saas.farm')}
                    value={saasBilling.farmId}
                    handleChange={handleInputChange}
                    items={farms.map(farm => ({
                        value: farm.id,
                        text: farm.name,
                    }))}
                    fullWidth
                />
                <SelectField
                    name="planCode"
                    label={t('billing.saas.plan')}
                    value={saasBilling.planCode}
                    handleChange={handleInputChange}
                    items={plans.map(plan => ({
                        value: plan.code,
                        text: plan.description,
                    }))}
                    fullWidth
                />
                <TextField
                    label={t('billing.saas.beginDate')}
                    name="beginDate"
                    type="date"
                    value={saasBilling.beginDate.split('T')[0]}
                    onChange={handleDateInputChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ m: 1 }}
                />
                <TextField
                    label={t('billing.saas.endDate')}
                    name="endDate"
                    type="date"
                    value={saasBilling.endDate.split('T')[0]}
                    onChange={handleDateInputChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ m: 1 }}
                />
                <TextField
                    name="quantity"
                    label={t('billing.saas.quantity')}
                    value={saasBilling.quantity}
                    type={"number"}
                    inputProps={{step:1}}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ m: 1 }}
                />
                <NumericFormat
                    customInput={TextField}
                    name="discount"
                    label={t('billing.saas.customDiscount')}
                    value={saasBilling.discount}
                    onValueChange={(values) => handleCurrencyChange('discount', values.value)}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix=" %"
                    fullWidth
                    sx={{ m: 1 }}
                />
                <TextField
                    name="obs"
                    label={t('billing.saas.obs')}
                    value={saasBilling.obs}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{m: 1}}
                />
                <Box sx={{ gap: 4}}>
                    <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120}} disabled={isSaveDisabled}>
                        {t('generic.save')}
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120, backgroundColor: 'gray',
                        color: 'white'}}>
                        {t('generic.cancel')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default BillingSaasModal;