import i18n from "i18next";

let LanguageService = {
    supportedLanguages: ['en-US', 'pt-PT', 'es-ES'],

    defaultLanguage: 'en-US',

    currentLanguage: 'en-US',

    isLanguageSupported: function (language) {
        return LanguageService.supportedLanguages.find(lang => lang.includes(language))
    },

    getBrowserLanguage: function () {
        return navigator.language || LanguageService.defaultLanguage;
    },

    getCurrentLanguage: function () {
        return this.currentLanguage;
    },

    setDefaultLanguage: function () {
        let browserLanguage = LanguageService.getBrowserLanguage();
        LanguageService.setUserLanguage(browserLanguage);
    },

    setUserLanguage: function (language) {
        if(language) {
            i18n.changeLanguage(language);
            localStorage.setItem('userLanguage', language);
        } else {
            language = language.indexOf('_') !== -1? language.replace('_', '-') : language;
            const langSupported = LanguageService.isLanguageSupported(language)
            if(langSupported) {
                this.currentLanguage = langSupported;
                i18n.changeLanguage(langSupported);
                localStorage.setItem('userLanguage', langSupported);
            } else {
                i18n.changeLanguage(language);
                localStorage.setItem('userLanguage', language);
            }
        }

    }
};

export default LanguageService;