import axios from "../config/axios";

const BillingProductService = {

    fetchAllProducts: async () => {
        try {
            const response = await axios.get(`/billing/products`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    updateProduct: async (code, product) => {
        try {
            const response = await axios.put(`/billing/products/${code}`, product);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    createProduct: async (product) => {
        try {
            const response = await axios.post(`/billing/products`, product);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    deleteProduct: async (code) => {
        try {
            const response = await axios.delete(`/billing/products/${code}`);
            return response.data;
        } catch (error){
            throw error;
        }
    }
};

export default BillingProductService;