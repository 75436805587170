import React from 'react'
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import BillingNotificationAuditContainer
    from "../../components/billing/notificationAudit/BillingNotificationAuditContainer";

const BillingVoiceAndSMSPage = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.notifications.title')}</Typography>
        <BillingNotificationAuditContainer />
    </MainLayout>
}

export default BillingVoiceAndSMSPage;