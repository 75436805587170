import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Alert, Box, Button, Snackbar, TextField, Tabs, Tab, Switch, FormControlLabel} from '@mui/material';
import { Add } from '@mui/icons-material';
import BillingFarmService from "../../../services/BillingFarmService";
import SelectField from "../../form/SelectField";
import AuxTableService from "../../../services/AuxTableService";
import ExportButton from "../../form/ExportButton";
import ExportService from "../../../services/ExportService";
import DataTableComponent from "../../dataTable/DataTableComponent";
import {getColumns} from "./config";
import GenericDialog from "../../form/Dialog/GenericDialog";
import FarmsWithoutBillingView from "./FarmsWithoutBillingView";
import Loading from "../../Loading";

const BillingFarmsContainer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [farms, setFarms] = useState([]);
    const [farmsWithoutBilling, setFarmsWithoutBilling] = useState([]);
    const [groups, setGroups] = useState([]);
    const [filteredFarms, setFilteredFarms] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [groupFilter, setGroupFilter] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showOnlyErrorFarms, setShowOnlyErrorFarms] = useState(false);
    const location = useLocation();

    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);
    const [changeStatusDialog, setChangeStatusDialog] = useState(false);
    const [farm, setFarm] = useState({});
    const [farmsWithoutBillingLoading, setFarmsWithoutBillingLoading] = useState(true);

    useEffect(() => {
        fetchFarms();
        fetchGroups();
        fetchFarmsWithoutBilling();
    }, []);

    useEffect(() => {
        if (location.state?.success) {
            setSnackbarOpen({open: true, severity: 'success', text: location.state.message || t('billing.farms.saveSuccess')});
        }
    }, [location, t]);

    useEffect(() => {
        filterFarms();
        // eslint-disable-next-line
    }, [nameFilter, groupFilter, showOnlyErrorFarms, farms]);

    const fetchFarms = async () => {
        try {
            setLoading(true)
            const response = await BillingFarmService.fetchAllFarms();
            const sortedFarms = response.sort((a, b) => a.name.localeCompare(b.name));
            setFarms(sortedFarms);
        } catch (error) {
            console.error('Error fetching farms:', error);
        } finally {
            setLoading(false)
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await AuxTableService.fetchAllBillingGroups();
            const sortedGroups = response.sort((a, b) => a.name.localeCompare(b.name));
            setGroups(sortedGroups);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchFarmsWithoutBilling = async () => {
        try {
            setFarmsWithoutBillingLoading(true)
            const response = await BillingFarmService.fetchFarmsWithoutBilling();
            const sortedFarms = response.sort((a, b) => new Date(b.creationTimestampUtc) - new Date(a.creationTimestampUtc));
            setFarmsWithoutBilling(sortedFarms);
        } catch (error) {
            console.error('Error fetching farms without billing:', error);
        } finally {
            setFarmsWithoutBillingLoading(false)
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleChangeStatusClick = (farm) => {
        setFarm(farm);
        setChangeStatusDialog(true);
    };

    const changeStatusFarm = async () => {
        try {
            await BillingFarmService.changeStatusFarm(farm?.id);
            setSnackbarOpen({open: true, severity: 'success', text: t('billing.farms.status.saveSuccess')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('billing.farms.status.saveFailure')});
            console.error('Error changing status of farm :', error);
        }
    };

    const handleChangeStatus = () => {
        changeStatusFarm()
            .then(async () => {
                setChangeStatusDialog(false);
                await fetchFarms();
            })
            .catch((error) => {
                console.error('Error in handleChangeStatus:', error)
            });
    }

    const handleAddNewClick = () => {
        navigate('/billing/farms/new');
    };

    const handleRowClick = (farm) => {
        navigate(`/billing/farms/details/${farm.id}`);
    };

    const filterFarms = () => {
        let filtered = farms;

        if (nameFilter) {
            filtered = filtered.filter(farm => farm.name.toLowerCase().includes(nameFilter.toLowerCase()));
        }

        if (groupFilter) {
            filtered = filtered.filter(farm => farm.group === groupFilter);
        }

        if (showOnlyErrorFarms){
            filtered = filtered.filter(farm => farm.terminalCount !== farm.billingTerminalCount);
        }

        setFilteredFarms(filtered);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const handleExport = (format) => {
        const dataToExport = filteredFarms;
        const fileName = 'farms';

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }

    };

    const handleClearFilters = () => {
        setNameFilter('');
        setGroupFilter('');
    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Farms Tabs">
                <Tab label={t('billing.farms.withBilling')} />
                <Tab label={t('billing.farms.withoutBilling')} />
            </Tabs>
            {tabIndex === 0 && (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, flexWrap: 'wrap', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center'}}>
                            <TextField
                                label={t('billing.farms.name')}
                                value={nameFilter}
                                onChange={(e) => setNameFilter(e.target.value)}
                            />
                            <SelectField
                                label={t('billing.farms.group')}
                                value={groupFilter}
                                handleChange={(e) => setGroupFilter(e.target.value)}
                                items={groups.map(group => ({
                                    value: group.name,
                                    text: group.name,
                                }))}
                            />
                            <FormControlLabel
                                control={<Switch checked={showOnlyErrorFarms} onChange={(e) => setShowOnlyErrorFarms(e.target.checked)} />}
                                label={t('billing.farms.showOnlyErrorFarms')}
                            />
                        </Box>

                        <Box sx={{display: 'flex', gap: 2}}>
                            <Button variant="contained" color="primary" onClick={handleClearFilters}>
                                {t('generic.clearFilter')}
                            </Button>
                            <ExportButton onExport={handleExport} />
                        </Box>
                    </Box>

                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddNewClick}
                            startIcon={<Add />}
                            sx={{ padding: '10px', fontSize: '16px' }}
                        >
                            {t('generic.addNew')}
                        </Button>
                    </Box>
                </Box>
                <Box>
                    {loading ? (
                        <Loading />
                    ): (
                        <DataTableComponent
                            columns={getColumns(t, handleChangeStatusClick)}
                            data={filteredFarms}
                            pagination
                            onRowClicked={handleRowClick}
                            highlightOnHover
                        />
                    )}
                </Box>
            </>)}
            {tabIndex === 1 && (
                <FarmsWithoutBillingView
                    farmsWithoutBilling={farmsWithoutBilling}
                    isLoading={farmsWithoutBillingLoading}
                />)}
            <GenericDialog
                open={changeStatusDialog}
                handleClose={() => setChangeStatusDialog(false)}
                handleConfirm={handleChangeStatus}
                title={t('billing.farms.status.dialog.title')}
                text={`${t('billing.farms.status.dialog.text')} ${t(!farms.isActive ? 'generic.inactive' : 'generic.active')}.`}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{ width: '100%' }}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BillingFarmsContainer;