import React, {useEffect, useState} from 'react'
import {getColumns} from "./config"
import Loading from "../../Loading";
import SelectField from "../../form/SelectField";
import dayjs from "../../../config/dayjs-config"
import {Period} from "../../../enums/Period";
import {useTranslation} from "react-i18next";
import DataTableComponent from "../../dataTable/DataTableComponent";
import {Button, Box} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportService from "../../../services/ExportService";
import EventService from "../../../services/EventService";
import configs from "../../../configs";


const getDateFromPeriod = (period) => {
    switch (period) {
        case Period.LAST_1_HOURS:
            return dayjs().subtract(1, 'hours');
        case Period.LAST_6_HOURS:
            return dayjs().subtract(6, 'hours');
        case Period.LAST_12_HOURS:
            return dayjs().subtract(12, 'hours');
        default:
            return null;
    }
};

const LatestCriticalEventsList = () => {
    const {t} = useTranslation();
    const [period, setPeriod] = useState(Period.ALL);
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const resp = await EventService.fetchLatestCriticalEvents();
        const sortedData = resp ? resp.sort((a, b) => dayjs(b.creationDate).diff(dayjs(a.creationDate))) : [];
        setData(sortedData)
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        const periodDate = getDateFromPeriod(period);
        let filtered = data;
        if (periodDate) {
            filtered = filtered.filter(item => dayjs(
                dayjs.tz(item.creationDate, 'UTC').tz('Europe/Lisbon')
            ).isAfter(periodDate));
        }

        setFilteredData(filtered);

    }, [period, data]);
    const handleChangePeriod = (event) => {
        setPeriod(event.target.value);
    };

    const handleRefresh = () => {
        fetchData();
    };

    const handleExport = () => {
        const formattedData = filteredData.map(item => ({
            ...item,
            creationDate: dayjs.tz(item.creationDate, 'UTC').tz('Europe/Lisbon').format('YYYY-MM-DD HH:mm:ss'),
        }));

        ExportService.exportToExcel(formattedData, 'critical_events');
    };

    const handleRowClicked = (row) => {
        window.open(`${configs.host}/events/${row.id}/details`, '_blank');
    };

    return (
        <div>
            <Box display="flex" flexDirection={"column"}>
                <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
                    <SelectField
                        label={t('dashboard.disconnectedTerminals.period.label')}
                        value={period}
                        handleChange={handleChangePeriod}
                        items={
                            [
                                {
                                    value: Period.ALL,
                                    text: t('enums.periods.all')
                                },
                                {
                                    value: Period.LAST_1_HOURS,
                                    text: t('enums.periods.last1hours')
                                },
                                {
                                    value: Period.LAST_6_HOURS,
                                    text: t('enums.periods.last6hours')
                                },
                                {
                                    value: Period.LAST_12_HOURS,
                                    text: t('enums.periods.last12hours')
                                }
                            ]}
                    />
                </Box>
                <Box gap={2}>
                    <Button variant="contained"
                            onClick={handleRefresh}
                            endIcon={loading ? <MoreHorizIcon/> :<RefreshIcon />}
                            sx={{ mr: 2 }}>
                        {loading ? t('generic.loading') : t('generic.refresh')}
                    </Button>
                    <Button variant="contained" onClick={handleExport}  color="success" endIcon={<FileDownloadIcon />}>
                        {t('generic.export')}
                    </Button>
                </Box>
            </Box>
            {loading ? (
                    <div style={{marginTop: 20}}>
                        <Loading/>
                    </div>
                ) :
                (<div style={{marginTop: 20}}>
                    <DataTableComponent
                        columns={getColumns(t)}
                        data={filteredData}
                        pagination={true}
                        onRowClicked={handleRowClicked}
                    />
                </div>)}
        </div>
    );
}

export default LatestCriticalEventsList