import React from 'react';
import { Box, TextField, IconButton, Button } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import SelectField from "../../form/SelectField";

const ContactFields = ({ contacts, setContacts }) => {
    const { t } = useTranslation();

    const handleContactChange = (index, field, value) => {
        const newContacts = contacts.map((contact, i) => (
            i === index ? { ...contact, [field]: value } : contact
        ));
        setContacts(newContacts);
    };

    const addContact = () => {
        setContacts([...contacts, { type: '', value: '' }]);
    };

    const removeContact = (index) => {
        const newContacts = contacts.filter((_, i) => i !== index);
        setContacts(newContacts);
    };

    return (
        <Box>
            <Box sx={{ maxHeight: 200, overflowY: 'auto', mb: 2 }}>
                {contacts.map((contact, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                        <SelectField
                            name={`ContactType-${index}`}
                            label={t('billing.clients.contact.contactType')}
                            value={contact.type}
                            handleChange={(e) => handleContactChange(index, 'type', e.target.value)}
                            items={[
                                {
                                    value: 'name',
                                    text: t('billing.clients.contact.name')
                                },
                                {
                                    value: 'phone',
                                    text: t('billing.clients.contact.phone')
                                },
                                {
                                    value: 'email',
                                    text: t('billing.clients.contact.email')
                                }]}
                            fullWidth
                        />
                        <TextField
                            name={`ContactValue-${index}`}
                            label={t('billing.clients.contact.contactValue')}
                            value={contact.value}
                            onChange={(e) => handleContactChange(index, 'value', e.target.value)}
                            fullWidth
                        />
                        <IconButton onClick={() => removeContact(index)} color="secondary">
                            <Remove />
                        </IconButton>
                    </Box>
                ))}
            </Box>
            <Button onClick={addContact} startIcon={<Add />} sx={{ mb: 2 }}>
                {t('billing.clients.contact.addContact')}
            </Button>
        </Box>
    );
};

export default ContactFields;