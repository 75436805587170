import {useEffect, useState} from "react";
import {TextField, Button, Box, Typography, Grid, Alert} from '@mui/material';
import {useAuth} from "../context/AuthContext";
import farmLogo from '../assets/farm-logo.svg';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const LoginPage = () => {
    const {t} = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const { login } = useAuth();

    useEffect(() => {
        if (error) {
            setShowError(true);
        }
    }, [error]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try{
            await login(username, password);
            setShowError(false);
        } catch (error) {
            setError(t('errors.'+ error.message));
            setShowError(true);
        }
    };

    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid item xs={12} sm={8} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4 }}>
                <Typography component="h1" variant="h3" sx={{ mb: 4 }} className={"c-primary"}>
                    Backoffice
                </Typography>
                <Box className={"w-500 h-180"}>
                    <img alt="farm-logo" src={farmLogo}/>
                </Box>
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Login
                </Typography>
                {showError && <Alert severity="error">{error}</Alert>}
                <Box component="form" onSubmit={(e) => { handleLogin(e); }} sx={{ width: '100%', maxWidth: 400 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="E-mail"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        error={showError}
                        helperText={showError && 'Invalid username or password'}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={showError}
                        helperText={showError && 'Invalid username or password'}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Login
                    </Button>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            Don't have an account? <Link to="/sign-up">Register</Link>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={false} sm={4} md={4} className="c-bg-farm">

            </Grid>
        </Grid>
    );
}

export default LoginPage;