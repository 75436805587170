import React, { useEffect, useState } from "react";
import { Box, Button, Alert, Snackbar, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import BillingClientService from "../../../services/BillingClientService";
import DataTableComponent from "../../dataTable/DataTableComponent";
import BillingClientModal from "./BillingClientModal";
import {getColumns} from "./config";
import { useTranslation } from "react-i18next";
import SelectField from "../../form/SelectField";
import ExportService from "../../../services/ExportService";
import ExportButton from "../../form/ExportButton";
import AuxTableService from "../../../services/AuxTableService";
import ExpandableContacts from "./ExpandableContacts";
import GenericDialog from "../../form/Dialog/GenericDialog";
import Loading from "../../Loading";

const BillingClientsContainer = () => {
    const newClient = { clientCode: '', name: '', country: '', taxId: '', type: '', allowContact: false, contact: {}, isActive: false }
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [codeFilter, setCodeFilter] = useState('');
    const [countryFilter, setCountryFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [contactFilter, setContactFilter] = useState('');
    const [client, setClient] = useState(newClient);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState('');
    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);
    const [countries, setCountries] = useState([]);
    const [types, setTypes] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [changeStatusDialog, setChangeStatusDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCountries();
        fetchTypes();
        fetchClients();
    }, []);

    useEffect(() => {
        filterClients();
        // eslint-disable-next-line
    }, [codeFilter, countryFilter, typeFilter, nameFilter, contactFilter, clients]);

    const fetchCountries = async () => {
        try {
            const response = await AuxTableService.fetchAllCountries();
            setCountries(response);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchTypes = async () => {
        try {
            const response = await AuxTableService.fetchAllBillingTypes();
            setTypes(response);
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    const fetchClients = async () => {
        try {
            setLoading(true)
            const response = await BillingClientService.fetchAllClients();
            const sortedClients = response.sort((a, b) => a.clientCode - b.clientCode);
            setClients(sortedClients);
            setFilteredClients(sortedClients);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleEditClick = (client) => {
        setClient(client);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleChangeStatusClick = (client) => {
        setClient(client);
        setChangeStatusDialog(true);
    };

    const changeStatusClient = async () => {
        try {
            await BillingClientService.changeStatusClient(client?.clientCode);
            setSnackbarOpen({open: true, severity: 'success', text: t('billing.clients.status.saveSuccess')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('billing.clients.status.saveFailure')});
            console.error('Error changing status of client :', error);
        }
    };

    const handleChangeStatus = () => {
        changeStatusClient()
            .then(async () => {
                setChangeStatusDialog(false);
                await fetchClients();
            })
            .catch((error) => {
                console.error('Error in handleChangeStatus:', error)
            });
    }

    const handleAddNewClick = () => {
        setClient(newClient);
        setIsEditing(false);
        setModalOpen(true);
    };

    const handleSave = async (contacts) => {
        const hasInvalidContacts = contacts.some(contact => (contact.type && !contact.value) || (!contact.type && contact.value));
        if (hasInvalidContacts) {
            setError(t('billing.clients.contact.errors.incompleteContacts'));
            return;
        }
        setError('')
        const contactObject = contacts.reduce((acc, contact) => {
            if (contact.type && contact.value) {
                acc[contact.type] = contact.value;
            }
            return acc;
        }, {});

        setClient(newClient);
        handleClearFilters()
        setModalOpen(false);
        if (isEditing) {
            try {
                await BillingClientService.updateClient(client.clientCode, {...client, contact: contactObject});
                fetchClients();
            } catch (error) {
                console.error('Error updating client:', error);
            }
        } else {
            try {
                await BillingClientService.createClient(client);
                fetchClients();
            } catch (error) {
                console.error('Error adding new client:', error);
            }
        }
        setSnackbarOpen({open: true, severity: 'success', text: t('billing.clients.saveSuccess')});
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const filterClients = () => {
        let filtered = clients;

        if (codeFilter) {
            filtered = filtered.filter(client => client.clientCode === codeFilter);
        }

        if (countryFilter) {
            filtered = filtered.filter(client => client.country === countryFilter);
        }

        if (typeFilter) {
            filtered = filtered.filter(client => client.type === typeFilter);
        }

        if (nameFilter) {
            filtered = filtered.filter(client => client.name.toLowerCase().includes(nameFilter.toLowerCase()));
        }

        if (contactFilter !== '') {
            filtered = filtered.filter(client => client.allowContact === (contactFilter === 'true'));
        }

        setFilteredClients(filtered);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setClient({ ...client, [name]: value });
    };

    const handleCodeFilterChange = (event) => {
        setCodeFilter(event.target.value);
    };

    const handleCountryFilterChange = (event) => {
        setCountryFilter(event.target.value);
    };

    const handleTypeFilterChange = (event) => {
        setTypeFilter(event.target.value);
    };

    const handleNameFilterChange = (event) => {
        setNameFilter(event.target.value);
    };

    const handleContactFilterChange = (event) => {
        setContactFilter(event.target.value);
    };

    const handleClearFilters = () => {
        setCodeFilter('');
        setCountryFilter('');
        setTypeFilter('');
        setNameFilter('');
        setContactFilter('');
    };

    const handleExport = (format) => {
        const dataToExport = filteredClients;
        const fileName = 'clients';

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }

    };

    const hasContacts = (row) => row.contact && Object.keys(row.contact).length > 0;

    const handleRowClick = (row) => {
        if (hasContacts(row)) {
            setExpandedRows((prev) => ({
                ...prev,
                [row.clientCode]: !prev[row.clientCode],
            }));
        }
    };

    const isRowExpanded = (row) => !!expandedRows[row.clientCode];

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, flexWrap: 'wrap', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center'}}>
                        <SelectField
                            label={t('billing.clients.code')}
                            value={codeFilter}
                            handleChange={handleCodeFilterChange}
                            items={clients.map(client => ({
                                value: client.clientCode,
                                text: client.clientCode,
                            }))}
                        />
                        <SelectField
                            label={t('billing.clients.country')}
                            value={countryFilter}
                            handleChange={handleCountryFilterChange}
                            items={countries.map(country => ({
                                value: country.name,
                                text: country.name,
                            }))}
                        />
                        <SelectField
                            label={t('billing.clients.type')}
                            value={typeFilter}
                            handleChange={handleTypeFilterChange}
                            items={types.map(type => ({
                                value: type.name,
                                text: type.name,
                            }))}
                        />
                        <TextField
                            label={t('billing.clients.name')}
                            value={nameFilter}
                            onChange={handleNameFilterChange}
                        />
                        <SelectField
                            label={t('billing.clients.allowContact')}
                            value={contactFilter}
                            handleChange={handleContactFilterChange}
                            items={[
                                { value: 'true', text: t('generic.yes') },
                                { value: 'false', text: t('generic.no') },
                            ]}
                        />
                    </Box>

                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button variant="contained" color="primary" onClick={handleClearFilters}>
                            {t('generic.clearFilter')}
                        </Button>

                        <ExportButton onExport={handleExport} />
                    </Box>

                </Box>

                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddNewClick}
                        startIcon={<Add />}
                        sx={{ padding: '10px', fontSize: '16px' }}
                    >
                        {t('generic.addNew')}
                    </Button>
                </Box>

            </Box>
            {loading ? (
                <Loading />
            ): (
                <DataTableComponent
                    columns={getColumns(t, handleEditClick, handleChangeStatusClick)}
                    data={filteredClients}
                    pagination
                    expandableRows
                    expandableRowsComponent={({ data }) => <ExpandableContacts data={data} />}
                    onRowClicked={handleRowClick}
                    expandableRowExpanded={isRowExpanded}
                />
            )}
            <BillingClientModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                isEditing={isEditing}
                client={client}
                error={error}
                handleInputChange={handleInputChange}
                handleSave={handleSave}
                countries={countries}
                types={types}
            />
            <GenericDialog
                open={changeStatusDialog}
                handleClose={() => setChangeStatusDialog(false)}
                handleConfirm={handleChangeStatus}
                title={t('billing.clients.status.dialog.title')}
                text={`${t('billing.clients.status.dialog.text')} ${t(client.isActive ? 'generic.inactive' : 'generic.active')}.`}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{width: '100%'}}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BillingClientsContainer;