import React from 'react'
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import BillingAuxTablesContainer from "../../components/billing/auxTables/BillingAuxTablesContainer";


const BillingAuxTablesPage = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography
            variant={'h5'}
            mb={2}
            sx={{ marginLeft: '16px'}}
        >
            {t('billing.auxTables.title')}
        </Typography>
        <BillingAuxTablesContainer />
    </MainLayout>
}

export default BillingAuxTablesPage;