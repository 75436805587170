import {Box, IconButton, Tooltip} from "@mui/material";
import {Delete, Edit, CheckCircle, CheckCircleOutline, Warning} from "@mui/icons-material";

export const getColumns = (t, handleEditClick, handleDeleteClick, handleApproveClick, user) => [
    {
        name: t('billing.hw.installationDate'),
        selector: row => new Date(row.installationDate).toLocaleDateString(),
        sortFunction: (a, b) => new Date(a.installationDate) - new Date(b.installationDate),
        sortable: true,
    },
    {
        name: t('billing.hw.farm'),
        selector: row => row.farm,
        sortable: true,
    },
    {
        name: t('billing.hw.hardware'),
        selector: row => row.hardware,
        sortable: true,
    },
    {
        name: t('billing.hw.terminal'),
        selector: row => (row.terminal ? t('generic.yes') : t('generic.no')),
        sortable: true,
    },
    {
        name: t('billing.hw.quantity'),
        selector: row => row.quantity,
        sortable: true,
    },
    {
        name: t('billing.hw.observations'),
        selector: row => row?.observations,
        sortable: true,
    },
    {
        name: '',
        right: "true",
        cell: (row) => (
            <>
                {getApprovedStatus(t, row, user, handleApproveClick)}
                <IconButton color="primary" onClick={() => handleEditClick(row)}>
                    <Edit/>
                </IconButton>
                <IconButton color="error" onClick={() => handleDeleteClick(row)}>
                    <Delete/>
                </IconButton>
            </>
        ),
    },
];

function getApprovedStatus(t, hw, loggedUser, handleApproveClick){
    if(!hw.approvedDate && hw.createdBy && hw.createdByEmail !== loggedUser.email) {
        return (<Tooltip title={t('billing.hw.approve')}>
            <IconButton color="success" onClick={() => handleApproveClick(hw)} >
                <CheckCircleOutline sx={{mr: 3}}/>
            </IconButton>
        </Tooltip>)
    } else if(hw.approvedDate){
        return (<Tooltip title={`${t('billing.hw.approvedBy')} ${hw.approvedBy || t('generic.system')}, ${new Date(hw.approvedDate).toLocaleDateString()}`}>
            <CheckCircle color="success" sx={{ mr: 4 }} />
        </Tooltip>)
    } else {
        return (<Box sx={{ display: 'flex', alignItems: 'center', color: 'orange' }}>
            <Tooltip title={t('billing.hw.notApproved')}>
                <Warning sx={{ mr: 4 }} />
            </Tooltip>
        </Box>)
    }
}