import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExcelJS = require('exceljs');

const ExportService = {

    exportToCSV: (data, fileName) => {
        const csvHeader = Object.keys(data[0]).join(',') + '\n';
        const csvRows = data.map(row => Object.values(row).join(',')).join('\n');
        const csvString = csvHeader + csvRows;

        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${fileName}.csv`);
    },
    exportToExcel: (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    },
    filterNextRenewalData: (data, filterDate) => {
        const currentMonth = new Date(filterDate).getMonth();
        const currentYear = new Date(filterDate).getFullYear();

        return data.filter(item => {
            const renewalDate = new Date(item.nextRenewal);
            return renewalDate.getMonth() === currentMonth && renewalDate.getFullYear() === currentYear;
        });
    },
    exportMonthlyBillingMap: (data, filterDate) => {
        const filteredData = ExportService.filterNextRenewalData(data, filterDate);

        const dataToExport = [];
        let totalQty = 0;
        let totalValue = 0;
        let weightedGrandTotalPrice = 0;

        const groupedData = filteredData.reduce((acc, curr) => {
            if (!acc[curr.farmName]) {
                acc[curr.farmName] = [];
            }
            acc[curr.farmName].push(curr);
            return acc;
        }, {});

        Object.keys(groupedData).forEach(farmName => {
            const items = groupedData[farmName];
            let farmTotalQty = 0;
            let farmTotalValue = 0;
            let weightedTotalPrice = 0;

            items.forEach((item, index) => {

                if (item.productCode === "NAOFACT") {
                    return;
                }

                const itemPrice = item.discount > 0 ? item.price - (item.price * item.discount / 100) : item.price;

                dataToExport.push({
                    Farm: index === 0 ? farmName : "",
                    Product: item.product,
                    Qty: item.quantity,
                    Price: itemPrice,
                    Value: item.netValue
                });

                farmTotalQty += item.quantity;
                farmTotalValue += item.netValue;
                weightedTotalPrice += itemPrice * item.quantity;
            });

            const weightedAveragePrice = farmTotalQty > 0 ? weightedTotalPrice / farmTotalQty : 0;

            dataToExport.push({
                Farm: farmName,
                Product: "Total",
                Qty: farmTotalQty,
                Price: weightedAveragePrice,
                Value: farmTotalValue
            });

            totalQty += farmTotalQty;
            totalValue += farmTotalValue;
            weightedGrandTotalPrice += weightedAveragePrice * farmTotalQty;
        });

        const grandTotalWeightedAveragePrice = totalQty > 0 ? weightedGrandTotalPrice / totalQty : 0;

        dataToExport.push({
            Farm: "Total Geral",
            Product: "",
            Qty: totalQty,
            Price: grandTotalWeightedAveragePrice,
            Value: totalValue
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Billing Data');

        worksheet.columns = [
            { header: 'Farm', key: 'Farm', width: 20 },
            { header: 'Product', key: 'Product', width: 30 },
            { header: 'Qty', key: 'Qty', width: 10 },
            { header: 'Price (€)', key: 'Price', width: 15, style: { numFmt: '€ #,##0.00' } },
            { header: 'Value (€)', key: 'Value', width: 15, style: { numFmt: '€ #,##0.00' } }
        ];

        dataToExport.forEach((row, index) => {
            const excelRow = worksheet.addRow(row);

            excelRow.getCell('Price').numFmt = '€ #,##0.00';
            excelRow.getCell('Value').numFmt = '€ #,##0.00';

            // Format the Total row with bold and background color only within the table
            if (row.Product === "Total") {
                excelRow.font = { bold: true };
                for (let i = 1; i <= worksheet.columns.length; i++) {
                    excelRow.getCell(i).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFE599' }
                    };
                }
            } else {
                if (index > 0 && dataToExport[index].Farm === dataToExport[index - 1].Farm) {
                    excelRow.getCell('Farm').font = { color: { argb: 'FFFFFF' } };
                }
            }
        });

        worksheet.addTable({
            name: 'BillingTable',
            ref: 'A1',
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleLight9',
                showRowStripes: true,
            },
            columns: [
                { name: 'Farm', filterButton: true },
                { name: 'Product', filterButton: true },
                { name: 'Qty', filterButton: true },
                { name: 'Price (€)', filterButton: true },
                { name: 'Value (€)', filterButton: true }
            ],
            rows: dataToExport.map(row => Object.values(row))
        });

        workbook.xlsx.writeBuffer().then(function(buffer) {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            saveAs(blob, 'saas_billing_product.xlsx');
        });
    },
    exportMonthlyBillingMapWithClient: async (data, filterDate) => {
        const filteredData = ExportService.filterNextRenewalData(data, filterDate);

        const dataToExport = [];
        let totalQty = 0;
        let totalValue = 0;
        let weightedGrandTotalPrice  = 0;

        const groupedData = filteredData.reduce((acc, curr) => {
            const groupKey = `${curr.clientName}-${curr.clientCode}`;
            if (!acc[groupKey]) {
                acc[groupKey] = {};
            }

            if (!acc[groupKey][curr.productCode]) {
                acc[groupKey][curr.productCode] = {
                    productCode: curr.productCode,
                    product: curr.product,
                    quantity: 0,
                    netValue: 0,
                    totalPrice: 0,
                    count: 0
                };
            }

            const productEntry = acc[groupKey][curr.productCode];
            productEntry.quantity += curr.quantity;
            productEntry.netValue += curr.netValue;
            productEntry.totalPrice += curr.discount > 0 ? (curr.price - (curr.price * curr.discount / 100)) : curr.price;
            productEntry.count += 1;

            return acc;
        }, {});

        Object.keys(groupedData).forEach(groupKey => {
            const [group, clientCode] = groupKey.split('-');
            let groupTotalQty = 0;
            let groupTotalValue = 0;
            let weightedTotalPrice = 0;

            Object.keys(groupedData[groupKey]).forEach((productCode) => {
                const item = groupedData[groupKey][productCode];

                if (item.productCode === "NAOFACT") {
                    return;
                }

                dataToExport.push({
                    Name: group,
                    CliCod: clientCode,
                    CodProd: item.productCode,
                    Product: item.product,
                    Qty: item.quantity,
                    Price: item.totalPrice / item.count,
                    Value: item.netValue
                });

                groupTotalQty += item.quantity;
                groupTotalValue += item.netValue;
                weightedTotalPrice += (item.totalPrice / item.count) * item.quantity;
            });

            const weightedAveragePrice = groupTotalQty > 0 ? weightedTotalPrice / groupTotalQty : 0;

            dataToExport.push({
                Name: group,
                CliCod: "",
                CodProd: "",
                Product: "Total",
                Qty: groupTotalQty,
                Price: weightedAveragePrice,
                Value: groupTotalValue
            });

            totalQty += groupTotalQty;
            totalValue += groupTotalValue;
            weightedGrandTotalPrice += weightedAveragePrice * groupTotalQty;
        });

        const grandTotalWeightedAveragePrice = totalQty > 0 ? weightedGrandTotalPrice  / totalQty : 0;

        dataToExport.push({
            Name: "Grand Total",
            CliCod: "",
            CodProd: "",
            Product: "",
            Qty: totalQty,
            Price: grandTotalWeightedAveragePrice,
            Value: totalValue
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Billing Data');

        worksheet.columns = [
            { header: 'Name', key: 'Name', width: 20 },
            { header: 'CliCod', key: 'CliCod', width: 15 },
            { header: 'CodProd', key: 'CodProd', width: 15 },
            { header: 'Product', key: 'Product', width: 30 },
            { header: 'Qty', key: 'Qty', width: 10 },
            { header: 'Price (€)', key: 'Price', width: 15, style: { numFmt: '€ #,##0.00' } },
            { header: 'Value (€)', key: 'Value', width: 15, style: { numFmt: '€ #,##0.00' } }
        ];

        dataToExport.forEach((row, index) => {
            const excelRow = worksheet.addRow(row);

            excelRow.getCell('Price').numFmt = '€ #,##0.00';
            excelRow.getCell('Value').numFmt = '€ #,##0.00';

            if (row.CliCod) {
                excelRow.getCell('CliCod').numFmt = '0';
            }

            // Apply special formatting for the Total row only within the table
            if (row.Product === "Total") {
                excelRow.font = { bold: true };
                for (let i = 1; i <= worksheet.columns.length; i++) {
                    excelRow.getCell(i).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFE599' }
                    };
                }
            } else {
                // Hide duplicate Name and CliCod by setting their font color to white
                if (index > 0 && dataToExport[index].Name === dataToExport[index - 1].Name) {
                    excelRow.getCell('Name').font = { color: { argb: 'FFFFFF' } };
                }
                if (index > 0 && dataToExport[index].CliCod === dataToExport[index - 1].CliCod) {
                    excelRow.getCell('CliCod').font = { color: { argb: 'FFFFFF' } };
                }
            }
        });

        worksheet.addTable({
            name: 'BillingTable',
            ref: 'A1',
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleLight9',
                showRowStripes: true,
            },
            columns: [
                { name: 'Name', filterButton: true },
                { name: 'CliCod', filterButton: true },
                { name: 'CodProd', filterButton: true },
                { name: 'Product', filterButton: true },
                { name: 'Qty', filterButton: true },
                { name: 'Price (€)', filterButton: true },
                { name: 'Value (€)', filterButton: true }
            ],
            rows: dataToExport.map(row => Object.values(row))
        });

        workbook.xlsx.writeBuffer().then(function(buffer) {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            saveAs(blob, 'saas_billing_client.xlsx');
        });
    }
};

export default ExportService;