import axios from "../config/axios";

const ContactsService = {

    fetchAllContacts: async ()=> {
        try{
            const response = await axios.get('/contact/');
            return response.data;
        }catch (error) {
            throw error;
        }
    },
    createContact: async (contact) =>{
        try{
            const response = await axios.post('/contact', contact);
            return response.data;
        }catch (error) {
            throw error;
        }
    },
    updateContactByFarmId: async (farmId, contact) =>{
        try{
            const response = await axios.put(`/contact/farm/${farmId}`,contact);
            return response.data;
        }catch (error){
            throw error;
        }
    },
};

export default ContactsService;