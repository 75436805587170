import {TextField, Box, Button, Modal, Typography} from "@mui/material";
import SelectField from "../../form/SelectField";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

const BillingProductModal = ({ open, handleClose, isEditing, product, handleInputChange, handleSave }) => {
    const {t} = useTranslation();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid = product.code && product.name && product.status;

        setIsSaveDisabled(!isFormValid);
    }, [product]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%', borderRadius: 2, boxShadow: 24 }}>
                <Typography variant={"h3"} sx={{ marginBottom: 2, textAlign: 'center' }}>
                    {isEditing ? t('billing.products.editProduct') : t('billing.products.newProduct')}
                </Typography>
                <TextField
                    name="code"
                    label={t('billing.products.code')}
                    value={product.code}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={isEditing}
                    sx={{m: 1}}
                />
                <TextField
                    name="name"
                    label={t('billing.products.name')}
                    value={product.name}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{m: 1}}
                />
                <SelectField
                    name="status"
                    label={t('billing.products.status')}
                    value={product.status}
                    handleChange={handleInputChange}
                    items={[
                        { value: 'ACTIVE', text: t('billing.products.active') },
                        { value: 'INACTIVE', text: t('billing.products.inactive') },
                    ]}
                    fullWidth
                />
                <Box sx={{ gap: 4}}>
                    <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120}} disabled={isSaveDisabled}>
                        {t('generic.save')}
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120, backgroundColor: 'gray',
                        color: 'white'}}>
                        {t('generic.cancel')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default BillingProductModal;