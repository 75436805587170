import axios from "../config/axios";
import LanguageService from "./LanguageService";

function storeLoggedInUser(data) {

    const {name, email, language, roles} = data;

    const userInfo = {
        name,
        email,
        language,
        roles
    };

    LanguageService.setUserLanguage(language)

    localStorage.setItem('loggedUser', JSON.stringify(userInfo));
}

function storeAuthToken(data) {
    const {token, refreshToken} = data;

    const expirationTime = new Date().getTime() + 60 * 60 * 1000;

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('tokenExpiration', expirationTime.toString());
}

function cleanLoggedUserInfoFromLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('loggedUser');
}

let AuthService = {

    login: async (username, password) => {
        try {
            const response = await axios.post('/auth/login', {
                username,
                password,
            });
            storeAuthToken(response.data)
            storeLoggedInUser(response.data)
            return response.data;
        } catch (error){
            throw error;
        }
    },

    logout: () => {
        cleanLoggedUserInfoFromLocalStorage()
        LanguageService.setDefaultLanguage()
        window.location.href = '/login';
    },

    refreshAccessToken: async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const response = await axios.post('/auth/refresh-token', { refreshToken });
            const { token } = response.data;
            localStorage.setItem('token', token);
            const expirationTime = new Date().getTime() + 60 * 60 * 1000;
            localStorage.setItem('tokenExpiration', expirationTime.toString());
            return token;
        } catch (error) {
            AuthService.logout();
            throw error;
        }
    },

    register: async (username, password, email, roles, language) => {
        try {
            const response = await axios.post('/auth/signup', {
                username,
                password,
                email,
                roles,
                language
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

};

export default AuthService;