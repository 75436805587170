import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import HwComponentService from "../../../services/HwComponentService";
import ExportService from "../../../services/ExportService";
import {Alert, Box, Button, FormControlLabel, Snackbar, Switch} from "@mui/material";
import SelectField from "../../form/SelectField";
import ExportButton from "../../form/ExportButton";
import {Add} from "@mui/icons-material";
import DataTableComponent from "../../dataTable/DataTableComponent";
import {getColumns} from "./config";
import BillingFarmService from "../../../services/BillingFarmService";
import HwInventoryService from "../../../services/HwInventoryService";
import HwInventoryBulkCreateModal from "./HwInventoryBulkCreateModal";
import DeleteDialog from "../../form/Dialog/DeleteDialog";
import HwInventoryModal from "./HwInventoryModal";
import {useAuth} from "../../../context/AuthContext";
import Loading from "../../Loading";

const HwInventoryContainer = () => {
    const newHwInventory = { id: null, farm: '', installationDate: '', hardware: '', terminal: '', quantity: 0, observations: '' };
    const {t} = useTranslation();
    const [hwInventoryList, setHwInventoryList] = useState([]);
    const [hwInventoryListFiltered, setHwInventoryListFiltered] = useState([]);
    const [hwComponents, setHwComponents] = useState([]);
    const [farms, setFarms] = useState([]);
    const [hardwareFilter, setHardwareFilter] = useState('');
    const [farmFilter, setFarmFilter] = useState('');
    const [hwInventory, setHwInventory] = useState(newHwInventory);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showOnlyPending, setShowOnlyPending] = useState(false);
    const {user} = useAuth();

    useEffect(() => {
        fetchHwInventoryList();
        fetchFarms();
        fetchHwComponents();
    }, []);

    useEffect(() => {
        filterHwInventory();

        // eslint-disable-next-line
    }, [hardwareFilter, farmFilter, showOnlyPending, hwInventoryList]);

    const fetchHwComponents = async () => {
        try {
            const response = await HwComponentService.fetchAllHwComponents();
            setHwComponents(response.sort((a, b) => a.hardware.localeCompare(b.hardware)));
        } catch (error) {
            console.error('Error fetching Hw Components:', error);
        }
    };

    const fetchFarms = async () => {
        try {
            const response = await BillingFarmService.fetchAllFarms();
            setFarms(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching Billing farms:', error);
        }
    };

    const fetchHwInventoryList = async () => {
        try {
            setLoading(true)
            const response = await HwInventoryService.fetchAllHwInventory();
            const sortedHwInventory = response.sort((a, b) => new Date(b.installationDate) - new Date(a.installationDate));
            setHwInventoryList(sortedHwInventory);
            setHwInventoryListFiltered(sortedHwInventory);
        } catch (error) {
            console.error('Error fetching Hw Inventory audit:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleEditClick = (hwInventory) => {
        setHwInventory(hwInventory);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleDeleteClick = (hwInventory) => {
        setHwInventory(hwInventory);
        setDeleteDialog(true);
    };

    const deleteHwInventory = async () => {
        try {
            await HwInventoryService.deleteHwInventory(hwInventory?.id);
            setSnackbarOpen({open: true, severity: 'success', text: t('billing.hw.delete.success')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('billing.hw.delete.failure')});
            console.error('Error deleting inventory :', error);
        }
    };

    const handleDelete = () => {
        deleteHwInventory()
            .then(async () => {
                setDeleteDialog(false);
                await fetchHwInventoryList();
            })
            .catch((error) => {
                console.error('Error in handleChangeStatus:', error)
            });
    }

    const handleAddNewClick = () => {
        setHwInventory(newHwInventory);
        setIsEditing(false);
        setModalOpen(true);
    };

    const handleSaveSingleHwInventory = async () => {
        setModalOpen(false);
        try {
            if (isEditing) {
                await HwInventoryService.updateHwInventory(hwInventory.id, hwInventory);
            } else {
                await HwInventoryService.createHwInventory(hwInventory);
            }

            fetchHwInventoryList();
            setSnackbarOpen({ open: true, severity: 'success', text: t('billing.hw.saveSuccess') });
        } catch (error) {
            console.error('Error saving hw component:', error);
            setSnackbarOpen({ open: true, severity: 'error', text: t('billing.hw.saveError') });
        }
    };

    const handleSaveMultipleHwInventory = async (entries) => {
        setModalOpen(false);
        try {
            for (const entry of entries) {
                if (isEditing && entry.id) {
                    await HwInventoryService.updateHwInventory(entry.id, entry);
                } else {
                    await HwInventoryService.createHwInventory(entry);
                }
            }

            fetchHwInventoryList();
            setSnackbarOpen({ open: true, severity: 'success', text: t('billing.hw.saveSuccess') });
        } catch (error) {
            console.error('Error saving hw component:', error);
            setSnackbarOpen({ open: true, severity: 'error', text: t('billing.hw.saveError') });
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const filterHwInventory = () => {
        let filtered = hwInventoryList;

        if (hardwareFilter) {
            filtered = filtered.filter(hwInv => hwInv.hardware === hardwareFilter);
        }

        if (farmFilter) {
            filtered = filtered.filter(hwInv => hwInv.farmId === farmFilter);
        }

        if (showOnlyPending) {
            filtered = filtered.filter(item => !item.approvedDate);
        }

        setHwInventoryListFiltered(filtered);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setHwInventory({ ...hwInventory, [name]: value });
    };

    const handleDateInputChange = (event) => {
        const { name, value } = event.target;
        if(value){
            const date = new Date(value)
            setHwInventory({ ...hwInventory, [name]: date.toISOString().split('T')[0] + 'T00:00:00' });
        }
    }

    const handleClearFilters = () => {
        setHardwareFilter('');
        setFarmFilter('');
    };

    const handleExport = (format) => {
        const dataToExport = hwInventoryListFiltered;
        const fileName = 'hw_inventory';

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }
    };

    const handleApproveClick = async (hwInventory) => {
        try {
            await HwInventoryService.approveHwInventory(hwInventory.id);

            fetchHwInventoryList();
            setSnackbarOpen({ open: true, severity: 'success', text: t('billing.hw.approveSuccess') });
        } catch (error) {
            console.error('Error approving hw inventory:', error);
            setSnackbarOpen({ open: true, severity: 'error', text: t('billing.hw.approveError') });
        }
    };


    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2 }}>
                    <SelectField
                        label={t('billing.hw.hardware')}
                        value={hardwareFilter}
                        handleChange={(e) => setHardwareFilter(e.target.value)}
                        items={hwComponents.map(hwComponent => ({
                            value: hwComponent.hardware,
                            text: hwComponent.hardware,
                        }))}
                    />
                    <SelectField
                        label={t('billing.hw.farm')}
                        value={farmFilter}
                        handleChange={(e) => setFarmFilter(e.target.value)}
                        items={farms.map(farm => ({
                            value: farm.id,
                            text: farm.name,
                        }))}
                    />

                    <FormControlLabel
                        control={<Switch checked={showOnlyPending} onChange={(e) => setShowOnlyPending(e.target.checked)} />}
                        label={t('billing.hw.showOnlyPending')}
                    />

                    <Button variant="contained" color="primary" onClick={handleClearFilters}>
                        {t('generic.clearFilter')}
                    </Button>
                    <ExportButton onExport={handleExport} />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewClick}
                    startIcon={<Add />}
                    sx={{ padding: '10px 20px', fontSize: '16px' }}
                >
                    {t('generic.addNew')}
                </Button>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <DataTableComponent
                    columns={getColumns(t, handleEditClick, handleDeleteClick, handleApproveClick, user)}
                    data={hwInventoryListFiltered}
                    pagination
                />
            )}
            {isEditing ? (
                <HwInventoryModal
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    isEditing={isEditing}
                    hwInventory={hwInventory}
                    farms={farms}
                    components={hwComponents}
                    handleInputChange={handleInputChange}
                    handleDateInputChange={handleDateInputChange}
                    handleSave={handleSaveSingleHwInventory}
                />
            ) : (
                <HwInventoryBulkCreateModal
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    farms={farms}
                    components={hwComponents}
                    handleSave={handleSaveMultipleHwInventory}
                />
            )}
            <DeleteDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                handleDelete={handleDelete}
                title={t('billing.hw.delete.title')}
                text={t('billing.hw.delete.text')}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{width: '100%'}}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default HwInventoryContainer;