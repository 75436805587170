import React, { useState, useEffect } from 'react';
import {
    Box,
    CircularProgress,
    Typography,
} from '@mui/material';
import ServiceStatusService from "../../../services/ServicesStatusService";
import ServiceStatusList from "./ServiceStatusList";
import {useTranslation} from "react-i18next";

const ServiceStatusContainer = () => {
    const {t} = useTranslation()
    const [servicesStatus, setServicesStatus] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchServicesStatus = async () => {
            try {
                const response = await ServiceStatusService.fetchServicesStatus();
                setServicesStatus(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching services status:', error);
                setLoading(false);
            }
        };

        fetchServicesStatus();

        const interval = setInterval(fetchServicesStatus, 600000); // Refresh every minute
        return () => clearInterval(interval);
    }, []);

    return (
        <Box>
            <Typography variant={'h5'} mb={2}>{t('dashboard.servicesStatus.title')}</Typography>
            {loading ? (
                <CircularProgress />
            ): (
                <ServiceStatusList servicesStatus={servicesStatus}/>
            )}
        </Box>
    );
};

export default ServiceStatusContainer;
