import React from 'react'
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import HwInventoryContainer from "../../components/billing/hwInventory/HwInventoryContainer";
import {useTranslation} from "react-i18next";

const BillingHardwareInventoryPage = () => {
    const {t} = useTranslation();
    return (
        <MainLayout>
            <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.hw.title')}</Typography>
            <HwInventoryContainer />
        </MainLayout>
    )
}

export default BillingHardwareInventoryPage;