import React from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {useTranslation} from "react-i18next";

const renderStatusIcon = (status) => {
    return status === 'UP' ? (
        <CheckCircleIcon style={{ color: 'green', marginRight: 8 }} />
    ) : (
        <ErrorIcon style={{ color: 'red', marginRight: 8 }} />
    );
};

const renderComponents = (components, t) => {
    return Object.entries(components).map(([component, status], idx) => (
        <Box key={idx} display="flex" alignItems="center" mb={1}>
            <Typography variant="body1" style={{ flex: 1, padding: 2 }}>
                {t(`dashboard.servicesStatus.components.${component}`)}
            </Typography>
            {renderStatusIcon(status.status)}
        </Box>
    ));
};

const ServiceStatusList = ({servicesStatus}) => {
    const {t} = useTranslation();
    return (
        <>
            {servicesStatus && Object.entries(servicesStatus).map(([service, status], idx) => (
                <Accordion key={idx} sx={{marginBottom: 1, padding: 0}}>
                    <AccordionSummary
                        aria-controls={`panel${idx}-content`}
                        id={`panel${idx}-header`}
                    >
                        <Box key={idx} display="flex" flex={1} alignItems="center" justifyContent="space-between">
                            <Typography variant="body1" style={{ flex: 1, fontWeight: 'bold' }}>
                                {service}
                            </Typography>
                            {renderStatusIcon(status.status)}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0.5 }}>
                        {status.components
                            ? renderComponents(status.components, t)
                            : (<Typography variant="body1" style={{padding: 2}}>
                                {t('dashboard.servicesStatus.serviceNotAvailable')}
                        </Typography>)}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}

export default ServiceStatusList;