import axios from "../config/axios";

const HwInventoryService = {

    fetchAllHwInventory: async () => {
        try {
            const response = await axios.get(`/billing/hw-inventory`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    updateHwInventory: async (id, hwInstalled) => {
        try {
            const response = await axios.put(`/billing/hw-inventory/${id}`, hwInstalled);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    createHwInventory: async (hwInstalled) => {
        try {
            const response = await axios.post(`/billing/hw-inventory`, hwInstalled);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    deleteHwInventory: async (id) => {
        try {
            const response = await axios.delete(`/billing/hw-inventory/${id}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },
    approveHwInventory: async (id) => {
        try {
            const response = await axios.get(`/billing/hw-inventory/approve/${id}`);
            return response.data;
        } catch (error){
            throw error;
        }
    }

};

export default HwInventoryService;