import dayjs from "../config/dayjs-config";

export const formatDate = (dateString) => {
    const formattedDate = dayjs.tz(dateString, 'UTC').tz('Europe/Lisbon').format('YYYY-MM-DD HH:mm:ss');
    const [date, time] = formattedDate.split(' ');
    return { date, time };
};

export const getElapsedTime = (dateString) => {
    const lastCommTime = dayjs.tz(dateString, 'UTC').tz('Europe/Lisbon');
    const currentTime = dayjs().tz('Europe/Lisbon');

    const duration = dayjs.duration(currentTime.diff(lastCommTime));
    const years = duration.years();
    const months = duration.months();
    const weeks = Math.floor(duration.asWeeks() % 4);
    const days = Math.floor(duration.asDays() % 7);
    const hours = Math.floor(duration.asHours() % 24);
    const minutes = duration.minutes();

    let elapsedTime = '';

    if (years > 0) {
        elapsedTime += `${years}y `;
    }
    if (months > 0) {
        elapsedTime += `${months}mo `;
    }
    if (weeks > 0) {
        elapsedTime += `${weeks}w `;
    }
    if (days > 0) {
        elapsedTime += `${days}d `;
    }
    if (hours > 0) {
        elapsedTime += `${hours}h `;
    }
    elapsedTime += `${minutes}m`;

    return elapsedTime.trim();
};