import axios from "../config/axios";

const SaasBillingService = {

    fetchAllSaasBilling: async (date) => {
        try {
            const response = await axios.get(`/billing/saas-billing`, {
                params: { date }
            });
            return response.data;
        } catch (error){
            throw error;
        }
    },

    fetchSaasBillingById: async (id) => {
        try {
            const response = await axios.get(`/billing/saas-billing/${id}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    updateSaasBilling: async (id, saasBilling) => {
        try {
            const response = await axios.put(`/billing/saas-billing/${id}`, saasBilling);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    createSaasBilling: async (saasBilling) => {
        try {
            const response = await axios.post(`/billing/saas-billing`, saasBilling);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    deleteSaasBilling: async (id) => {
        try {
            const response = await axios.delete(`/billing/saas-billing/${id}`);
            return response.data;
        } catch (error){
            throw error;
        }
    }
};

export default SaasBillingService;