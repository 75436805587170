export const formatCurrency = (value) => {
    if (!value) return '€0.00';
    const valueRounded = roundToTwoDecimals(value);
    return `€${valueRounded.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

export const roundToTwoDecimals = (value) => {
    return Math.round(value * 100) / 100;
}
export const formatPercentage = (value) => {
    return value !== null && value !== undefined ? `${(value).toFixed(2)} %` : '0.00 %';
};