import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth} from "../context/AuthContext";

const withRole = (WrappedComponent, requiredRoles) => {
    return (props) => {
        const { hasRole } = useAuth();
        const hasRequiredRole = requiredRoles.some(role => hasRole(role));

        if (!hasRequiredRole) {
            return <Navigate to="/" replace />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withRole;