import { Button, Menu, MenuItem } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import {useState} from "react";

const ExportButton = ({ onExport }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExportClick = (format) => {
        onExport(format);
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={handleMenuClick}
                color="success"
                aria-label="export"
                aria-controls="export-menu"
                aria-haspopup="true"
                endIcon={<FileDownloadIcon />}
            >
                {t('generic.export')}
            </Button>
            <Menu
                id="export-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleExportClick('xlsx')}>XLSX</MenuItem>
                <MenuItem onClick={() => handleExportClick('csv')}>CSV</MenuItem>
            </Menu>
        </>
    );
};

export default ExportButton;