import React, { useEffect, useState } from "react";
import {Box, Button, Typography, TextField, Switch, FormControlLabel, Grid, Snackbar, Alert,
    CircularProgress, InputAdornment} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import BillingFarmService from "../../../services/BillingFarmService";
import MainLayout from "../../MainLayout";
import {useTranslation} from "react-i18next";
import SelectField from "../../form/SelectField";
import AuxTableService from "../../../services/AuxTableService";
import BillingClientService from "../../../services/BillingClientService";

const BillingFarmDetails = () => {
    const {t} = useTranslation();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [farm, setFarm] = useState({
        name: '',
        farmcontrolFarmId: '',
        billingClass: '',
        group: '',
        country: '',
        solution: '',
        platform: '',
        species: '',
        installationDate: '',
        status: false,
        clientCode: '',
        farmcare: false,
        fcLevel: '0',
        obs: ''
    });
    const [countries, setCountries] = useState([]);
    const [groups, setGroups] = useState([]);
    const [classes, setClasses] = useState([]);
    const [solutions, setSolutions] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [species, setSpecies] = useState([]);
    const [clients, setClients] = useState([]);
    const [farmcontrolFarms, setFarmcontrolFarms] = useState([]);
    const [isEditing, setIsEditing] = useState(!id);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [loadingFarmcontrolFarms, setLoadingFarmcontrolFarms] = useState(false);

    useEffect(() => {
        const isFormValid = farm.name && farm.group && farm.billingClass &&
            farm.country && farm.solution && farm.platform && farm.species &&
            farm.installationDate && farm.clientCode && (farm.fcLevel !== null && farm.fcLevel !== undefined);

            setIsSaveDisabled(!isFormValid);
    }, [farm]);

    useEffect(() => {
        fetchCountries();
        fetchGroups();
        fetchClasses();
        fetchSpecies();
        fetchPlatforms();
        fetchSolutions();
        fetchGroups();
        fetchGroups();
        fetchClients();
        fetchFarmcontrolFarms();
        if (id) {
            fetchFarm();
        }else if (location.state?.selectedFarmId) {
            setFarm((prevFarm) => ({
                ...prevFarm,
                farmcontrolFarmId: location.state.selectedFarmId
            }));
        }
        // eslint-disable-next-line
    }, [id, location.state]);

    const fetchCountries = async () => {
        try {
            const response = await AuxTableService.fetchAllCountries();
            setCountries(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await AuxTableService.fetchAllBillingGroups();
            setGroups(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchClasses = async () => {
        try {
            const response = await AuxTableService.fetchAllBillingClasses();
            setClasses(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchPlatforms = async () => {
        try {
            const response = await AuxTableService.fetchAllPlatforms();
            setPlatforms(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };


    const fetchSolutions = async () => {
        try {
            const response = await AuxTableService.fetchAllSolutions();
            setSolutions(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchSpecies = async () => {
        try {
            const response = await AuxTableService.fetchAllSpecies();
            setSpecies(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchClients = async () => {
        try {
            const response = await BillingClientService.fetchAllClients();
            setClients(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const fetchFarmcontrolFarms = async () => {
        setLoadingFarmcontrolFarms(true);
        try {
            const response = await BillingFarmService.fetchFarmcontrolFarms(id);
            setFarmcontrolFarms(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching farm:', error);
        } finally {
            setLoadingFarmcontrolFarms(false);
        }
    };

    const fetchFarm = async () => {
        try {
            const response = await BillingFarmService.fetchFarmById(id);
            setFarm(response);
        } catch (error) {
            console.error('Error fetching farm:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFarm({ ...farm, [name]: value });
    };

    const handleDateInputChange = (event) => {
        const { name, value } = event.target;
        if(value) {
            const date = new Date(value)
            setFarm({...farm, [name]: date.toISOString().split('T')[0] + 'T00:00:00'});
        }
    };

    const handleNumberInputChange = (event) => {
        const { name, value } = event.target;
        setFarm({ ...farm, [name]: +value });
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            if (id) {
                await BillingFarmService.updateFarm(id, farm);
            } else {
                await BillingFarmService.createFarm(farm);
            }
            navigate('/billing/farms', { state: { success: true, message: t('billing.farms.saveSuccess') } });
        } catch (error) {
            setSnackbarMessage(t('billing.farms.saveError'));
            setSnackbarOpen(true);
            console.error('Error saving farm:', error);
        }
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleCancel = () => {
        if (id) {
            if (isEditing) {
                setIsEditing(false);
            } else {
                navigate('/billing/farms');
            }
        } else {
            navigate('/billing/farms');
        }
    };

    const handleStatusSwitchChange = (event) => {
        handleInputChange({ target: { name: 'status', value: event.target.checked } });
    };

    const handleFarmcareSwitchChange = (event) => {
        handleInputChange({ target: { name: 'farmcare', value: event.target.checked } });
    };

    if (!farm) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <MainLayout>
            <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        {isEditing ? (id ? t('billing.farms.editFarm') : t('billing.farms.newFarm')) : t('billing.farms.farmDetails')}
                    </Typography>
                    {!isEditing && (
                        <Button variant="contained" color="primary" onClick={handleEditClick}>
                            {t('generic.edit')}
                        </Button>
                    )}
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('billing.farms.name')}
                            name="name"
                            value={farm.name}
                            onChange={handleInputChange}
                            fullWidth
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="group"
                            label={t('billing.farms.group')}
                            value={farm.group}
                            handleChange={handleInputChange}
                            items={groups.map(group => ({
                                value: group.name,
                                text: group.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="farmcontrolFarmId"
                            label={t('billing.farms.farmcontrolFarm')}
                            value={farm.farmcontrolFarmId}
                            handleChange={handleInputChange}
                            items={farmcontrolFarms.map(farmcontrolFarm => ({
                                value: farmcontrolFarm.id,
                                text: farmcontrolFarm.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                            SelectProps={{
                                startAdornment: loadingFarmcontrolFarms && (
                                    <InputAdornment position="start">
                                        <CircularProgress size={24} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="billingClass"
                            label={t('billing.farms.billingClass')}
                            value={farm.billingClass}
                            handleChange={handleInputChange}
                            items={classes.map(billingClass => ({
                                value: billingClass.name,
                                text: billingClass.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="country"
                            label={t('billing.farms.country')}
                            value={farm.country}
                            handleChange={handleInputChange}
                            items={countries.map(country => ({
                                value: country.name,
                                text: country.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="solution"
                            label={t('billing.farms.solution')}
                            value={farm.solution}
                            handleChange={handleInputChange}
                            items={solutions.map(solution => ({
                                value: solution.name,
                                text: solution.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="platform"
                            label={t('billing.farms.platform')}
                            value={farm.platform}
                            handleChange={handleInputChange}
                            items={platforms.map(platform => ({
                                value: platform.name,
                                text: platform.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="species"
                            label={t('billing.farms.species')}
                            value={farm.species}
                            handleChange={handleInputChange}
                            items={species.map(specie => ({
                                value: specie.name,
                                text: specie.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('billing.farms.installationDate')}
                            name="installationDate"
                            type="date"
                            value={farm.installationDate.split('T')[0]}
                            onChange={handleDateInputChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="clientCode"
                            label={t('billing.farms.client')}
                            value={farm.clientCode}
                            handleChange={handleInputChange}
                            items={clients.map(client => ({
                                value: client.clientCode,
                                text: client.name,
                            }))}
                            fullWidth
                            disabled={!isEditing}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('billing.farms.fcLevel')}
                            name="fcLevel"
                            type={"number"}
                            value={farm.fcLevel}
                            onChange={handleNumberInputChange}
                            fullWidth
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('billing.farms.obs')}
                            name="obs"
                            value={farm.obs}
                            onChange={handleInputChange}
                            fullWidth
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={farm.status}
                                    onChange={handleStatusSwitchChange}
                                    name="status"
                                    color="primary"
                                    disabled={!isEditing}
                                />
                            }
                            label={t('billing.farms.active')}
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={farm.farmcare}
                                    onChange={handleFarmcareSwitchChange}
                                    name="farmcare"
                                    color="primary"
                                    disabled={!isEditing}
                                />
                            }
                            label={t('billing.farms.farmcare')}
                            sx={{ m: 1 }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
                    {isEditing ? (
                        <>
                            <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaveDisabled}>
                               {id ? t('generic.save') : t('generic.create')}
                            </Button>
                            <Button variant="contained" onClick={handleCancel} sx={{backgroundColor: 'gray' }}>
                                {t('generic.cancel')}
                            </Button>
                        </>
                    ) : (
                        <Button variant="outlined" onClick={handleCancel}>
                            {t('generic.back')}
                        </Button>
                    )}
                </Box>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </MainLayout>
    );
};

export default BillingFarmDetails;