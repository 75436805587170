import {IconButton, Switch, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";

export const getColumns = (t, handleEditClick, handleChangeStatusClick) => [
    {
        name: t('billing.hw.components.hardware'),
        selector: row => row.hardware,
        sortable: true,
    },
    {
        name: t('billing.hw.components.hwSupplier'),
        selector: row => row.hwSupplier,
        sortable: true,
    },
    {
        name: t('billing.hw.components.saasSalesType'),
        selector: row => row.saasSalesType,
        sortable: true,
    },
    {
        name: t('billing.hw.components.terminal'),
        selector: row => (row.terminal ? t('generic.yes') : t('generic.no')),
        sortable: true,
    },
    {
        name: t('billing.hw.components.observations'),
        selector: row => row?.observations,
        sortable: true,
    },
    {
        name: '',
        right: "true",
        cell: (row) => (
            <>
                <Tooltip title={`${t(row?.isActive ? 'generic.active' : 'generic.inactive')}`}>
                    <Switch checked={row?.isActive} onClick={() => handleChangeStatusClick(row)}/>
                </Tooltip>
                <IconButton color="primary" onClick={() => handleEditClick(row)}>
                    <Edit/>
                </IconButton>
            </>
        ),
    },
];