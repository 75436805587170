import { Typography, Stack, Tooltip } from "@mui/material";
import { Circle } from "@mui/icons-material";

export const getColumns = (t) => [
    {
        name: t('terminals.name'),
        selector: row => row.displayName,
        sortable: true,
    },
    {
        name: t('terminals.farm'),
        selector: row => row.farm,
        sortable: true,
    },
    {
        name: t('terminals.serialNumber'),
        selector: row => row.serialNumber,
        sortable: true,
    },
    {
        name: t('terminals.profile'),
        selector: row => row.profile,
        sortable: true,
    },
    {
        name: t('terminals.lastCommunication'),
        selector: row => new Date(row.lastComm),
        sortable: true,
        cell: row => {
            const lastCommDate = new Date(row.lastComm);
            const hours = lastCommDate.getHours().toString().padStart(2, '0');
            const minutes = lastCommDate.getMinutes().toString().padStart(2, '0');
            const seconds = lastCommDate.getSeconds().toString().padStart(2, '0');
            const year = lastCommDate.getFullYear();
            const month = lastCommDate.getMonth() + 1;
            const day = lastCommDate.getDate();

            const formattedTime = `${hours}:${minutes}:${seconds}`;
            const formattedDate = `${day}/${month}/${year}`;

            return (
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Tooltip title={row.connectionStatus ? t('terminals.connected') : t('terminals.disconnected')}>
                            <Circle
                                sx={{
                                    color: row.connectionStatus ? 'green' : 'red',
                                    fontSize: 16,
                                    cursor: 'pointer'
                                }}
                            />
                        </Tooltip>
                        <Typography
                            variant="body2"
                            sx={{
                                color: getTerminalStatusColor(row.terminalStatus),
                                fontWeight: 'bold'
                            }}
                        >
                            {t(`terminals.statuses.${row.terminalStatus}`)}
                        </Typography>
                    </Stack>
                    <Typography variant="body2">
                        {formattedTime}, {formattedDate}
                    </Typography>
                </Stack>
            );
        },
    }
];

const getTerminalStatusColor = (status) => {
    switch (status) {
        case 'ENABLED':
            return 'green';
        case 'INACTIVE':
            return 'gray';
        case 'ERROR':
            return 'red';
        case 'MAINTENANCE':
            return 'orange';
        case 'STOCK':
            return 'blue';
        case 'TESTING':
            return 'lightblue';
        default:
            return 'black';
    }
};
