import axios from "../config/axios";

const HwComponentService = {

    fetchAllHwComponents: async () => {
        try {
            const response = await axios.get(`/billing/hw-components`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    updateHwComponent: async (hardware, hwComponent) => {
        try {
            const response = await axios.put(`/billing/hw-components/${hardware}`, hwComponent);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    createHwComponent: async (hwComponent) => {
        try {
            const response = await axios.post(`/billing/hw-components`, hwComponent);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    changeStatusHwComponent: async (hardware) => {
        try {
            const response = await axios.put(`/billing/hw-components/status/${hardware}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

};

export default HwComponentService;