import {useTranslation} from "react-i18next";
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import BillingFarmsContainer from "../../components/billing/farms/BillingFarmsContainer";

const BillingFarmsPage = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.farms.title')}</Typography>
        <BillingFarmsContainer />
    </MainLayout>
}

export default BillingFarmsPage;