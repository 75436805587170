import React from 'react';
import {Box, Button, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

const TotalsConfig = (
    {
        voicePrice,
        setVoicePrice,
        smsPrice,
        setSmsPrice,
        voiceLimit,
        setVoiceLimit,
        smsLimit,
        setSmsLimit,
        applyTotalFilters
    }) => {

    const {t} = useTranslation()
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
                label={t('billing.notifications.inputs.voicePrice')}
                type="number"
                value={voicePrice}
                onChange={(e) => setVoicePrice(parseFloat(e.target.value))}
            />
            <TextField
                label={t('billing.notifications.inputs.smsPrice')}
                type="number"
                value={smsPrice}
                onChange={(e) => setSmsPrice(parseFloat(e.target.value))}
            />
            <TextField
                label={t('billing.notifications.inputs.voiceLimit')}
                type="number"
                value={voiceLimit}
                onChange={(e) => setVoiceLimit(parseInt(e.target.value, 10))}
            />
            <TextField
                label={t('billing.notifications.inputs.smsLimit')}
                type="number"
                value={smsLimit}
                onChange={(e) => setSmsLimit(parseInt(e.target.value, 10))}
            />
            <Button variant="contained" color="primary" onClick={applyTotalFilters}>
                {t('generic.apply')}
            </Button>
        </Box>
    )
}

export default TotalsConfig;