import axios from "../config/axios";

const ServiceStatusService = {

    fetchServicesStatus: async () => {
        try {
            const response = await axios.get(`/services-status`);
            return response.data;
        } catch (error){
            throw error;
        }
    }
};

export default ServiceStatusService;