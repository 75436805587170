import {useTranslation} from "react-i18next";
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import HwComponentsContainer from "../../components/billing/hwComponents/HwComponentsContainer";

const HwComponentsPage = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.hw.components.title')}</Typography>
        <HwComponentsContainer />
    </MainLayout>
}

export default HwComponentsPage;