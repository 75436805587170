import React from 'react'
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import SaasBillingContainer from "../../components/billing/saas-billing/SaasBillingContainer";

const SaasBillingPage = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.saas.title')}</Typography>
        <SaasBillingContainer />
    </MainLayout>
}

export default SaasBillingPage;