import DataTableComponent from "../../dataTable/DataTableComponent";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {getFarmsWithoutBillingColumns} from "./config";
import {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import Loading from "../../Loading";

const FarmsWithoutBillingView = ({farmsWithoutBilling, isLoading}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [nameFilter, setNameFilter] = useState('');

    const handleCreateBillingFarm = (farm) => {
        navigate(`/billing/farms/new`, { state: { selectedFarmId: farm.id } });
    }

    const filteredFarmsWithoutBilling = useMemo(() => {
        return farmsWithoutBilling.filter((farm) =>
            farm.name.toLowerCase().includes(nameFilter.toLowerCase())
        );
    }, [farmsWithoutBilling, nameFilter]);

    return (
        <Box sx={{ margin: 'auto', padding: 1 }}>
            {isLoading ? (
                <Loading />
            ) : (
            <>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center'}}>
                    <TextField
                        label={t('billing.farms.name')}
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                </Box>
                <Box>
                    <DataTableComponent
                        columns={getFarmsWithoutBillingColumns(t, handleCreateBillingFarm)}
                        data={filteredFarmsWithoutBilling}
                        highlightOnHover
                    />
                </Box>
            </>)}

        </Box>
    )
}

export default FarmsWithoutBillingView;