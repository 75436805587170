import {IconButton} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import React from "react";

export function getColumns (t, handleEditClick, handleDeleteClick) {
    return [
        {
            name: t('billing.products.code'),
            selector: row => row.code,
            sortable: true,
        },
        {
            name: t('billing.products.name'),
            selector: row => row.name,
            sortable: true,
        },
        {
            name: t('billing.products.status'),
            selector: row => row.status,
            sortable: true,
        },
        {
            name: '',
            right: true,
            cell: (row) => (
                <>
                    <IconButton color="primary" onClick={() => handleEditClick(row)}>
                        <Edit/>
                    </IconButton>
                    <IconButton color="error" disabled={row.status === 'ACTIVE'} onClick={() => handleDeleteClick(row)}>
                        <Delete/>
                    </IconButton>
                </>
            ),
        },
    ];
}