import {formatDate, getElapsedTime} from "../../../utils/datesUtil";

export function getColumns (t) {
    return [
        {
            name: t('dashboard.openHelpdesks.tableHeader.title'),
            selector: row => row.title,
            sortable: true,
            cell: row => (
                <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflow: 'hidden', maxWidth: '200px' }}>
                    {row.title}
                </div>
            ),
        },
        {
            name: t('dashboard.openHelpdesks.tableHeader.entity'),
            selector: row => row.enterprise,
            sortable: true,
            format: (row, idx) => {
                return (
                    <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflow: 'hidden', maxWidth: '200px' }}>
                        {row.enterprise} | {row.farm}
                    </div>
                );
            }
        },
        {
            name: t('dashboard.openHelpdesks.tableHeader.createDate'),
            sortable: true,
            selector: row => row.creationDate,
            format: (row, idx) => {
                const { date, time } = formatDate(row.creationDate);
                const elapsedTime = getElapsedTime(row.creationDate);
                return (
                    <>
                        {date}<br />{time}<br /><small style={{color: "#888888"}}>{elapsedTime}</small>
                    </>
                );
            }
        },
        {
            name: t('dashboard.openHelpdesks.tableHeader.updateDate'),
            sortable: true,
            selector: row => row.updateDate,
            format: (row, idx) => {
                if(!row.updateDate){
                    return '---'
                }
                const { date, time } = formatDate(row.updateDate);
                const elapsedTime = getElapsedTime(row.updateDate);
                return (
                    <>
                        {date}<br />{time}<br /><small style={{color: "#888888"}}>{elapsedTime}</small>
                    </>
                );
            },
            sortFunction: (rowA, rowB) => {
                const dateA = rowA.updateDate ? new Date(rowA.updateDate) : null;
                const dateB = rowB.updateDate ? new Date(rowB.updateDate) : null;

                if (dateA === null && dateB === null) return 0;
                if (dateA === null) return 1;
                if (dateB === null) return -1;

                return dateA - dateB;
            }
        },
        {
            name: t('dashboard.openHelpdesks.tableHeader.status'),
            selector: row => row.status,
            sortable: true,
            format: (row, idx) => {
                return (
                    <>
                        {t('dashboard.openHelpdesks.status.'+row.status)}
                    </>
                );
            }
        },
        {
            name: t('dashboard.openHelpdesks.tableHeader.notes'),
            selector: row => row.lastNote,
            cell: row => (
                <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflow: 'hidden', maxWidth: '200px' }}>
                    {row.title}
                </div>
            ),
        }
    ];
}