import {TextField, Box, Button, Modal, Typography, IconButton, Grid} from "@mui/material";
import SelectField from "../../form/SelectField";
import {useTranslation} from "react-i18next";
import {useState, useEffect} from "react";
import { Add, Delete } from "@mui/icons-material";

const HwInventoryBulkCreateModal = ({
  open,
  handleClose,
  farms,
  components,
  handleSave
}) => {
    const { t } = useTranslation();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    const [commonFields, setCommonFields] = useState({
        farmId: '',
        installationDate: '',
    });

    const [inventoryEntries, setInventoryEntries] = useState([{ hardware: '', quantity: '', observations: '' }]);

    useEffect(() => {
        const isFormValid = commonFields.farmId && commonFields.installationDate &&
            inventoryEntries.every(entry => entry.hardware && entry.quantity && entry.quantity !== "0");

        setIsSaveDisabled(!isFormValid);
    }, [commonFields, inventoryEntries]);

    const handleDateInputChange = (event) => {
        const { name, value } = event.target;
        if(value) {
            const date = new Date(value)
            setCommonFields({...commonFields, [name]: date.toISOString().split('T')[0] + 'T00:00:00'});
        }
    }

    const handleCommonFieldChange = (event) => {
        const { name, value } = event.target;
        setCommonFields({ ...commonFields, [name]: value });
    };

    const handleEntryChange = (index, event) => {
        const { name, value } = event.target;
        const updatedEntries = [...inventoryEntries];
        updatedEntries[index] = { ...updatedEntries[index], [name]: value };
        setInventoryEntries(updatedEntries);
    };

    const handleAddEntry = () => {
        setInventoryEntries([...inventoryEntries, { hardware: '', quantity: '', observations: '' }]);
    };

    const handleRemoveEntry = (index) => {
        const updatedEntries = [...inventoryEntries];
        updatedEntries.splice(index, 1);
        setInventoryEntries(updatedEntries);
    };

    const handleMultipleSave = () => {
        const entriesToSave = inventoryEntries.map(entry => ({
            ...entry,
            farmId: commonFields.farmId,
            installationDate: commonFields.installationDate
        }));
        setCommonFields({farmId: '', installationDate: ''})
        setInventoryEntries([{ hardware: '', quantity: '', observations: '' }]);
        handleSave(entriesToSave);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                padding: 4,
                backgroundColor: 'white',
                margin: 'auto',
                marginTop: '5%',
                width: '60%',
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: 2,
                boxShadow: 24
            }}>
                <Typography variant={"h3"} sx={{ marginBottom: 2, textAlign: 'center' }}>
                    {t('billing.hw.newHwInventory')}
                </Typography>
                <SelectField
                    name="farmId"
                    label={t('billing.hw.farm')}
                    value={commonFields.farmId}
                    handleChange={handleCommonFieldChange}
                    items={farms.map(farm => ({
                        value: farm.id,
                        text: farm.name,
                    }))}
                    fullWidth
                    sx={{ m: 1 }}
                />
                <TextField
                    label={t('billing.hw.installationDate')}
                    name="installationDate"
                    type="date"
                    value={commonFields.installationDate.split('T')[0]}
                    onChange={handleDateInputChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{ m: 1 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleAddEntry}
                    sx={{ m: 1 }}
                >
                    {t('billing.hw.addNewEntry')}
                </Button>
                {inventoryEntries.map((entry, index) => (
                    <Grid container key={index} sx={{ border: '1px solid #ddd', padding: 2, marginBottom: 2, borderRadius: 2, gap: 2, m: 1 }}>
                        <Grid item xs={3}>
                            <SelectField
                                name="hardware"
                                label={t('billing.hw.hardware')}
                                value={entry.hardware}
                                handleChange={(e) => handleEntryChange(index, e)}
                                items={components.map(hwComponent => ({
                                    value: hwComponent.hardware,
                                    text: hwComponent.hardware,
                                }))}
                                fullWidth
                                sx={{ m: 1 }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                        <TextField
                            name="quantity"
                            label={t('billing.hw.quantity')}
                            value={entry.quantity}
                            type={"number"}
                            inputProps={{ step: 1 }}
                            onChange={(e) => handleEntryChange(index, e)}
                            fullWidth
                            sx={{ m: 1 }}
                        />
                        </Grid>
                        <Grid item xs={5}>
                        <TextField
                            name="observations"
                            label={t('billing.hw.components.observations')}
                            value={entry.observations}
                            onChange={(e) => handleEntryChange(index, e)}
                            fullWidth
                            sx={{ m: 1 }}
                        />
                        </Grid>
                        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={() => handleRemoveEntry(index)} color="error" sx={{ float: 'right' }}>
                                <Delete />
                            </IconButton>
                        </Grid>

                    </Grid>
                ))}

                <Box sx={{ gap: 4 }}>
                    <Button variant="contained" color="primary" onClick={handleMultipleSave} sx={{ marginTop: 2, m: 1, p: 2, minWidth: 120 }} disabled={isSaveDisabled}>
                        {t('generic.save')}
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: 2, m: 1, p: 2, minWidth: 120, backgroundColor: 'gray', color: 'white' }}>
                        {t('generic.cancel')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default HwInventoryBulkCreateModal;