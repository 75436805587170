import {useState} from "react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBarComponent from "./AppBarComponent";
import DrawerComponent from "./DrawerComponent";

const Sidebar = () => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (isOpen) => {
        setOpen(isOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBarComponent open={open} toggleDrawer={toggleDrawer} />
            <DrawerComponent open={open} toggleDrawer={toggleDrawer} />
        </Box>
    );
};

export default Sidebar;