import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/custom.css';
import './styles/colors.css';
import './config/i18n';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import RedirectToLogin from "./components/RedirectToLogin";
import {AuthProvider} from "./context/AuthContext";
import TerminalsPage from "./pages/TerminalsPage";
import UsersPage from "./pages/UsersPage";
import ErrorBoundary from "./components/errors/ErrorBoundary";
import BillingHardwareInventoryPage from "./pages/billing/BillingHardwareInventoryPage";
import BillingVoiceAndSMSPage from "./pages/billing/BillingVoiceAndSMSPage";
import BillingProductsPage from "./pages/billing/BillingProductsPage";
import BillingClientsPage from "./pages/billing/BillingClientsPage";
import SignUpPage from "./pages/SignUpPage";
import BillingFarmsPage from "./pages/billing/BillingFarmsPage";
import BillingFarmDetails from "./components/billing/farms/BillingFarmDetails";
import HwComponentsPage from "./pages/billing/HwComponentsPage";
import BillingPlansPage from "./pages/billing/BillingPlansPage";
import SaasBillingPage from "./pages/billing/SaasBillingPage";
import ContactsPage from "./pages/ContactsPage";
import BillingAuxTablesPage from "./pages/billing/BillingAuxTablesPage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <Router>
        <AuthProvider>
            <Routes>
                <Route path="/"
                    element={
                        <RedirectToLogin>
                            <HomePage />
                        </RedirectToLogin>
                    }
                />
                <Route path="/terminals"
                    element={
                        <RedirectToLogin>
                            <TerminalsPage />
                        </RedirectToLogin>
                    }
                />
                <Route path="/users"
                       element={
                           <RedirectToLogin>
                               <UsersPage />
                           </RedirectToLogin>
                       }
                />
                <Route path="/contacts"
                       element={
                           <RedirectToLogin>
                               <ContactsPage />
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/saas-billing"
                       element={
                           <RedirectToLogin>
                               <SaasBillingPage />
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/products"
                       element={
                           <RedirectToLogin>
                               <BillingProductsPage/>
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/clients"
                       element={
                           <RedirectToLogin>
                               <BillingClientsPage/>
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/farms"
                       element={
                           <RedirectToLogin>
                               <BillingFarmsPage/>
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/farms/new" element={<RedirectToLogin><BillingFarmDetails /></RedirectToLogin>} />
                <Route path="/billing/farms/details/:id" element={<RedirectToLogin><BillingFarmDetails /></RedirectToLogin>} />
                <Route path="/billing/farms/edit/:id" element={<RedirectToLogin><BillingFarmDetails /></RedirectToLogin>} />
                <Route path="/billing/hw-components"
                       element={
                           <RedirectToLogin>
                               <HwComponentsPage />
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/hw-inventory"
                       element={
                           <RedirectToLogin>
                               <BillingHardwareInventoryPage/>
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/voice-sms-list"
                       element={
                           <RedirectToLogin>
                               <BillingVoiceAndSMSPage />
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/plans"
                       element={
                           <RedirectToLogin>
                               <BillingPlansPage />
                           </RedirectToLogin>
                       }
                />
                <Route path="/billing/aux-tables"
                       element={
                           <RedirectToLogin>
                               <BillingAuxTablesPage />
                           </RedirectToLogin>
                       }
               />
                <Route path="/login" element={<LoginPage />}/>
                <Route path="/sign-up" element={<SignUpPage />} />
            </Routes>
        </AuthProvider>
    </Router>
  </ErrorBoundary>
);
