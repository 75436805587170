import React, { useState } from 'react';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    CircularProgress
} from "@mui/material";
import { formatCurrency } from "../../../utils/CurrencyUtil";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import html2pdf from 'html2pdf.js';
import {useTranslation} from "react-i18next";

const BudgetAnnualContainer = ({ saasBillings, filterDate }) => {
    const {t} = useTranslation();
    const [expandedGroups, setExpandedGroups] = useState({});
    const [isExportMode, setIsExportMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const prepareData = () => {
        const groupedData = {};
        const currentYear = new Date(filterDate).getFullYear();
        const currentMonth = new Date(filterDate).getMonth();

        saasBillings.forEach(billing => {
            const groupName = billing.group;
            const farmName = billing.farmName;

            const endDate = new Date(billing.endDate);
            const expirationYear = endDate.getFullYear();
            const expirationMonth = endDate.getMonth();

            // Skip farms that expired last year or earlier
            if (expirationYear < currentYear) return;

            if (!groupedData[groupName]) {
                groupedData[groupName] = {
                    farms: {},
                    total: Array(12).fill(0)
                };
            }

            if (!groupedData[groupName].farms[farmName]) {
                groupedData[groupName].farms[farmName] = Array(12).fill(0);
            }

            const monthlyValue = billing.valueMonthly || 0;
            const yearlyValue = billing.valueYearly || 0;
            const beginDate = new Date(billing.beginDate);

            const isAnnual = billing.period === 'Annual';
            const isSemester = billing.period === 'Semester';

            if (isAnnual) {
                if (expirationYear < currentYear || (expirationYear === currentYear && expirationMonth < beginDate.getMonth())) {
                    return;
                }

                if(!billing.nextRenewal && beginDate.getMonth() <= currentMonth){
                    groupedData[groupName].farms[farmName][beginDate.getMonth()] += yearlyValue;
                    groupedData[groupName].total[beginDate.getMonth()] += yearlyValue;
                } else if(billing.nextRenewal) {
                    const renewalMonth = new Date(billing.nextRenewal).getMonth();
                    if (renewalMonth >= 0 && renewalMonth < 12) {
                        groupedData[groupName].farms[farmName][renewalMonth] += yearlyValue;
                        groupedData[groupName].total[renewalMonth] += yearlyValue;
                    }
                }
            } else if (isSemester) {
                const semesterValue = yearlyValue / 2;
                let semesterRenewalDate = new Date(beginDate.getFullYear(), beginDate.getMonth(), 1);

                while (semesterRenewalDate <= endDate) {
                    const renewalYear = semesterRenewalDate.getFullYear();
                    const renewalMonth = semesterRenewalDate.getMonth();

                    if (renewalYear === currentYear) {
                        groupedData[groupName].farms[farmName][renewalMonth] += semesterValue;
                        groupedData[groupName].total[renewalMonth] += semesterValue;
                    }

                    semesterRenewalDate.setMonth(semesterRenewalDate.getMonth() + 6);
                }
            } else {
                for (let month = 0; month < 12; month++) {
                    const firstDayOfMonth = new Date(currentYear, month, 1);
                    const lastDayOfMonth = new Date(currentYear, month + 1, 0);

                    if (beginDate <= lastDayOfMonth && endDate >= firstDayOfMonth) {
                        groupedData[groupName].farms[farmName][month] += monthlyValue;
                        groupedData[groupName].total[month] += monthlyValue;
                    }
                }
            }
        });

        // Filter out farms with all zero values
        for (const groupName in groupedData) {
            groupedData[groupName].farms = Object.fromEntries(
                Object.entries(groupedData[groupName].farms).filter(([farmName, values]) =>
                    values.some(value => value !== 0)
                )
            );
        }

        // Filter out groups with no value farms
        return Object.fromEntries(
            Object.entries(groupedData).filter(([groupName, data]) =>
                Object.keys(data.farms).length > 0
            )
        );
    };

    const groupedData = prepareData();

    const grandTotal = Array(12).fill(0);
    for (const groupName in groupedData) {
        for (let i = 0; i < 12; i++) {
            grandTotal[i] += groupedData[groupName].total[i];
        }
    }

    const getTotalAnnual = (monthlyArray) => {
        return monthlyArray.reduce((acc, curr) => acc + curr, 0);
    };

    const handleToggleGroup = (groupName) => {
        setExpandedGroups(prevState => ({
            ...prevState,
            [groupName]: !prevState[groupName]
        }));
    };

    const handleExpandAll = () => {
        const allExpanded = {};
        Object.keys(groupedData).forEach(groupName => {
            allExpanded[groupName] = true;
        });
        setExpandedGroups(allExpanded);
    };

    const handleGeneratePDF = () => {
        setIsLoading(true);
        setIsExportMode(true);
        handleExpandAll();

        setTimeout(() => {
            const element = document.getElementById('budget-annual-table');
            const opt = {
                margin: 0.4,
                filename: 'BudgetAnnualReport.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1.5 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy'],
                    avoid: ['.no-break', 'tr', 'td']
                }
            };

            html2pdf().from(element).set(opt).save().then(() => {
                setIsLoading(false);
                setIsExportMode(false);
            });
        }, 1000);
    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Button variant="contained"
                    color="primary"
                    onClick={handleGeneratePDF}
                    sx={{ mb: 2 }}
                    disabled={isLoading}
                    startIcon={isLoading ? <CircularProgress size={20} /> : null}>
                {isLoading ? t('generic.loadingGeneratePdf') : t('generic.generatePdf')}
            </Button>
            <TableContainer component={Paper} id="budget-annual-table">
                <Table size="small" style={{ fontSize: '8px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: '2px' }}></TableCell>
                            {["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez", "Total"].map((month) => (
                                <TableCell key={month} align="right" style={{ padding: '2px' }}>{month}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(groupedData).map((groupName, groupIndex) => (
                            <React.Fragment key={groupName}>
                                <TableRow style={{ breakInside: 'avoid', pageBreakInside: 'avoid' }}>
                                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold', cursor: 'pointer', padding: '2px' }} onClick={() => handleToggleGroup(groupName)}>
                                        {!isExportMode && (
                                            <IconButton size="small">
                                                {expandedGroups[groupName] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        )}
                                        {groupName}
                                    </TableCell>
                                    {groupedData[groupName].total.map((value, index) => (
                                        <TableCell key={index} align="right" style={{ fontWeight: 'bold', padding: '2px' }}>
                                            {formatCurrency(value)}
                                        </TableCell>
                                    ))}
                                    <TableCell align="right" style={{ fontWeight: 'bold', padding: '2px' }}>
                                        {formatCurrency(getTotalAnnual(groupedData[groupName].total))}
                                    </TableCell>
                                </TableRow>

                                {expandedGroups[groupName] && Object.keys(groupedData[groupName].farms).map(farmName => (
                                    <TableRow key={farmName} style={{ breakInside: 'avoid', pageBreakInside: 'avoid' }}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                paddingLeft: '2px',
                                                paddingRight: '2px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '80px'
                                            }}>
                                            {farmName}
                                        </TableCell>
                                        {groupedData[groupName].farms[farmName].map((value, index) => (
                                            <TableCell key={index} align="right" style={{ padding: '2px' }}>{formatCurrency(value)}</TableCell>
                                        ))}
                                        <TableCell align="right" style={{ padding: '2px' }}>
                                            {formatCurrency(getTotalAnnual(groupedData[groupName].farms[farmName]))}
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {isExportMode && groupIndex < Object.keys(groupedData).length - 1 && (
                                    <TableRow>
                                        <TableCell colSpan={14} style={{ padding: '10px' }}></TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                        <TableRow>
                            <TableCell component="th" scope="row" style={{ fontWeight: 'bold', padding: '2px' }}>
                                TOTAL
                            </TableCell>
                            {grandTotal.map((value, index) => (
                                <TableCell key={index} align="right" style={{ fontWeight: 'bold', padding: '2px', fontSize: 10 }}>
                                    {formatCurrency(value)}
                                </TableCell>
                            ))}
                            <TableCell align="right" style={{ fontWeight: 'bold', padding: '2px', fontSize: 10}}>
                                {formatCurrency(getTotalAnnual(grandTotal))}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default BudgetAnnualContainer;