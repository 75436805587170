import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import OpenHelpdesksList from "./OpenHelpdesksList";

const OpenHelpdesksContainer = () => {
    const {t} = useTranslation()
    return (
        <>
            <Typography variant={'h5'} mb={2}>{t('dashboard.openHelpdesks.title')}</Typography>
            <OpenHelpdesksList />
        </>
    )
}

export default OpenHelpdesksContainer