import axios from "../config/axios";

const BillingClientService = {

    fetchAllClients: async () => {
        try {
            const response = await axios.get(`/billing/clients`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    updateClient: async (code, client) => {
        try {
            const response = await axios.put(`/billing/clients/${code}`, client);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    createClient: async (client) => {
        try {
            const response = await axios.post(`/billing/clients`, client);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    changeStatusClient: async (code) => {
        try {
            const response = await axios.put(`/billing/clients/status/${code}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },
};

export default BillingClientService;