import React, { useEffect, useState }  from 'react';
import { Box, Button, Modal, TextField, Typography, Switch, FormControlLabel, Alert  } from '@mui/material';
import SelectField from "../../form/SelectField";
import { useTranslation } from "react-i18next";
import ContactFields from "./ContactFields";

const BillingClientModal = ({ open, handleClose, isEditing, client, handleInputChange, handleSave, error, countries, types }) => {
    const { t } = useTranslation();
    const [contacts, setContacts] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        let isFormValid = client.clientCode && client.country && client.name && client.taxId && client.type;
        if(client.allowContact){
            isFormValid = contacts.length && contacts.every(contact => contact.type.trim() !== "" && contact.value.trim() !== "");
        }

        setIsSaveDisabled(!isFormValid);
    }, [client, contacts]);

    useEffect(() => {
        if (client.contact) {
            try {
                let parsedContacts;
                if (typeof client.contact === 'string') {
                    parsedContacts = Object.entries(JSON.parse(client.contact)).map(([type, value]) => ({ type, value }));
                } else if (typeof client.contact === 'object') {
                    parsedContacts = Object.entries(client.contact).map(([type, value]) => ({ type, value }));
                }
                setContacts(parsedContacts.length ? parsedContacts : [{ type: '', value: '' }]);
            } catch (error) {
                setContacts([{ type: '', value: '' }]);
            }
        } else {
            setContacts([{ type: '', value: '' }]);
        }
    }, [client.contact]);

    const handleSwitchChange = (event) => {
        handleInputChange({ target: { name: 'allowContact', value: event.target.checked } });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ padding: 2, backgroundColor: 'white', margin: 'auto', marginTop: '5%', width: '50%', borderRadius: 1 }}>
                <Typography variant="h3" align="center" sx={{ marginBottom: 2 }}>
                    {isEditing ? t('billing.clients.editClient') : t('billing.clients.newClient')}
                </Typography>
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Box sx={{ flex: '1 1 45%' }}>
                        <TextField
                            name="clientCode"
                            label={t('billing.clients.code')}
                            value={client.clientCode}
                            type={"number"}
                            inputProps={{min: 1, step:1}}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={isEditing}
                            sx={{ m: 1 }}
                        />
                        <TextField
                            name="name"
                            label={t('billing.clients.name')}
                            value={client.name}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Box>
                    <Box sx={{ flex: '1 1 45%' }}>
                        <SelectField
                            name="country"
                            label={t('billing.clients.country')}
                            value={client.country}
                            handleChange={handleInputChange}
                            items={countries.map(country => ({
                                value: country.name,
                                text: country.name,
                            }))}
                            fullWidth
                        />
                        <TextField
                            name="taxId"
                            label={t('billing.clients.taxId')}
                            value={client.taxId}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Box>
                    <Box sx={{ flex: '1 1 45%' }}>
                        <SelectField
                            name="type"
                            label={t('billing.clients.type')}
                            value={client.type}
                            handleChange={handleInputChange}
                            items={types.map(type => ({
                                value: type.name,
                                text: type.name,
                            }))}
                            fullWidth
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={client.allowContact}
                                    onChange={handleSwitchChange}
                                    name="allowContact"
                                    color="primary"
                                />
                            }
                            label={t('billing.clients.allowContact')}
                            sx={{ m: 1 }}
                        />
                    </Box>
                </Box>
                {client.allowContact && (
                    <ContactFields contacts={contacts} setContacts={setContacts} />
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="contained" color="primary" onClick={() => handleSave(contacts)} sx={{ m: 1, p: 2, minWidth: 120 }} disabled={isSaveDisabled}>
                        {t('generic.save')}
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ m: 1, p: 2, minWidth: 120, backgroundColor: 'gray' }}>
                        {t('generic.cancel')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default BillingClientModal;