import React, { useState, useCallback } from 'react';
import { Box, IconButton, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataTableComponent from '../dataTable/DataTableComponent';
import { getContactColumns } from './config';
import { useTranslation } from 'react-i18next';

const ExpandableContacts = ({ data }) => {
    const { t } = useTranslation();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const unsecuredCopyToClipboard= (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }

    const handleCopyClick = useCallback(async(value) => {
        try{
            await navigator.clipboard.writeText(value);
        } catch (e) {
            unsecuredCopyToClipboard(value);
        }
        setSnackbarMessage(t('contacts.copied'));
        setSnackbarOpen(true);
    }, [t]);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <DataTableComponent
                columns={getContactColumns(t, handleCopyClick)}
                data={data.contacts || []}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseSnackbar}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ExpandableContacts;