import { Box } from '@mui/material';
import Sidebar from './Sidebar';

const MainLayout = ({ children }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 8 }}>
                {children}
            </Box>
        </Box>
    );
};

export default MainLayout;