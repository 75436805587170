import React, {useMemo} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Drawer as MuiDrawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import PeopleIcon from '@mui/icons-material/People';
import SellIcon from '@mui/icons-material/Sell';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MemoryIcon from '@mui/icons-material/Memory';
import InventoryIcon from '@mui/icons-material/Inventory';
import RedeemIcon from '@mui/icons-material/Redeem';
import EuroIcon from '@mui/icons-material/Euro';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useHasRole} from "../hooks/useHasRole";
import {UserRoles} from "../enums/UserRoles";

const drawerWidth = 190;
const drawerBackgroundColor = '#1976d2';

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: drawerBackgroundColor,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    backgroundColor: drawerBackgroundColor,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: drawerBackgroundColor,
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
);

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#303f9f',
    },
}));

const DrawerComponent = ({ open, toggleDrawer }) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        toggleDrawer(false);
    };

    const canViewBilling = useHasRole([UserRoles.ROLE_ACCOUNTANT, UserRoles.ROLE_ADMIN]);

    const items = useMemo(() => {
        const billingItems = canViewBilling ? [
            { text: t('drawer.auxTables'), icon: <TableChartIcon/>, path: '/billing/aux-tables' },
            { text: t('drawer.hw-components'), icon: <MemoryIcon />, path: '/billing/hw-components' },
            { text: t('drawer.inventory'), icon: <InventoryIcon />, path: '/billing/hw-inventory' },
            { text: t('drawer.products'), icon: <SellIcon />, path: '/billing/products' },
            { text: t('drawer.clients'), icon: <PermContactCalendarIcon />, path: '/billing/clients' },
            { text: t('drawer.farms'), icon: <HomeWorkIcon />, path: '/billing/farms' },
            { text: t('drawer.plans'), icon: <RedeemIcon />, path: '/billing/plans' },
            { text: t('drawer.saas'), icon: <EuroIcon />, path: '/billing/saas-billing' },
            { text: t('drawer.smsAndVoice'), icon: <PermPhoneMsgIcon />, path: '/billing/voice-sms-list' },
        ] : [];

        return [
            { text: t('drawer.dashboard'), icon: <HomeIcon />, path: '/' },
            { text: t('drawer.terminals'), icon: <WifiTetheringIcon />, path: '/terminals' },
            { text: t('drawer.users'), icon: <PeopleIcon />, path: '/users' },
            { text: t('drawer.contacts'), icon: <SupportAgentIcon />, path: '/contacts'},
            ...billingItems
        ].filter(Boolean)
    }, [t, canViewBilling]);

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={() => toggleDrawer(false)}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <List>
                {items.map((item) => (
                    <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
                        <ListItemButtonStyled
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2,
                                color: 'white',
                            }}
                            onClick={() => handleNavigation(item.path)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 1 : 'auto',
                                    justifyContent: 'center',
                                    color: 'white',
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.text}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    whiteSpace: open ? 'normal' : 'nowrap',
                                    wordWrap: open ? 'break-word' : 'normal',
                                    overflow: open ? 'hidden' : 'visible',
                                    textOverflow: open ? 'ellipsis' : 'clip',
                                }}
                            />
                        </ListItemButtonStyled>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default DrawerComponent;