import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import EventService from "../../../services/EventService";
import dayjs from "../../../config/dayjs-config";
import ExportService from "../../../services/ExportService";
import {Box, Button} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Loading from "../../Loading";
import DataTableComponent from "../../dataTable/DataTableComponent";
import {getColumns} from "./config";
import configs from "../../../configs";

const OpenHelpdesksList = () => {
    const {t} = useTranslation();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const resp = await EventService.fetchOpenHelpdeskEvents();
        const sortedData = resp ? resp.sort((a, b) => dayjs(b.creationDate).diff(dayjs(a.creationDate))) : [];
        setData(sortedData)
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [])

    const handleRefresh = () => {
        fetchData();
    };

    const handleExport = () => {
        const formattedData = data.map(item => ({
            ...item,
            creationDate: dayjs.tz(item.creationDate, 'UTC').tz('Europe/Lisbon').format('YYYY-MM-DD HH:mm:ss'),
            updateDate: dayjs.tz(item.updateDate, 'UTC').tz('Europe/Lisbon').format('YYYY-MM-DD HH:mm:ss'),
        }));

        ExportService.exportToExcel(formattedData, 'open_helpdesks');
    };

    const handleRowClicked = (row) => {
        window.open(`${configs.host}/events/${row.id}/details`, '_blank');
    };

    return (
        <div>
            <Box display="flex" flexDirection={"column"}>
                <Box gap={2}>
                    <Button variant="contained"
                            onClick={handleRefresh}
                            endIcon={loading ? <MoreHorizIcon/> :<RefreshIcon />}
                            sx={{ mr: 2 }}>
                        {loading ? t('generic.loading') : t('generic.refresh')}
                    </Button>
                    <Button variant="contained" onClick={handleExport}  color="success" endIcon={<FileDownloadIcon />}>
                        {t('generic.export')}
                    </Button>
                </Box>
            </Box>
            {loading ? (
                    <div style={{marginTop: 20}}>
                        <Loading/>
                    </div>
                ) :
                (<div style={{marginTop: 20}}>
                    <DataTableComponent
                        columns={getColumns(t)}
                        data={data}
                        pagination={true}
                        onRowClicked={handleRowClicked}
                    />
                </div>)}
        </div>
    );
}

export default OpenHelpdesksList;