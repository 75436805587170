import {IconButton, Switch, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";

export const getColumns = (t, handleEditClick, handleChangeStatusClick) => [
    {
        name: t('billing.clients.code'),
        selector: row => row.clientCode,
        sortable: true,
    },
    {
        name: t('billing.clients.name'),
        selector: row => row.name,
        sortable: true,
    },
    {
        name: t('billing.clients.country'),
        selector: row => row?.country,
        sortable: true,
    },
    {
        name: t('billing.clients.taxId'),
        selector: row => row.taxId,
        sortable: true,
    },
    {
        name: t('billing.clients.type'),
        selector: row => row?.type,
        sortable: true,
    },
    {
        name: t('billing.clients.allowContact'),
        selector: row => (row.allowContact ? t('generic.yes') : t('generic.no')),
        sortable: true,
    },
    {
        name: '',
        right: "true",
        cell: (row) => (
            <>
                <Tooltip title={`${t(row?.isActive ? 'generic.active' : 'generic.inactive')}`}>
                    <Switch checked={row?.isActive} onClick={() => handleChangeStatusClick(row)}/>
                </Tooltip>
                <IconButton color="primary" onClick={() => handleEditClick(row)}>
                    <Edit/>
                </IconButton>
            </>
        )
    },
];