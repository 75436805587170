import {useTranslation} from "react-i18next";
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import BillingClientsContainer from "../../components/billing/clients/BillingClientsContainer";

const BillingClientsPage = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.clients.title')}</Typography>
        <BillingClientsContainer />
    </MainLayout>
}

export default BillingClientsPage;