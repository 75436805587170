import {IconButton, Switch, Tooltip} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {formatCurrency, formatPercentage} from "../../../utils/CurrencyUtil";

export const getColumns = (t, handleEditClick, handleChangeStatusClick) => [
    {
        name: t('billing.plans.code'),
        selector: row => row.code,
        sortable: true,
    },
    {
        name: t('billing.plans.description'),
        selector: row => row.description,
        sortable: true,
        cell: row => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.description}</div>,
    },
    {
        name: t('billing.plans.product'),
        selector: row => row.productName,
        sortable: true,
        cell: row => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.productName}</div>,
    },
    {
        name: t('billing.plans.saasSaleType'),
        selector: row => row.saasSaleType,
        sortable: true,
    },
    {
        name: t('billing.plans.period'),
        selector: row => row.period,
        sortable: true,
    },
    {
        name: t('billing.plans.price'),
        selector: row => row.price,
        sortable: true,
        cell: row => formatCurrency(row.price),
    },
    {
        name: t('billing.plans.discountPercentage'),
        selector: row => row.discountPercentage,
        sortable: true,
        cell: row => formatPercentage(row.discountPercentage),
    },
    {
        name: t('billing.plans.valueMonthly'),
        selector: row => row.valueMonthly,
        sortable: true,
        cell: row => formatCurrency(row.valueMonthly),
    },
    {
        name: t('billing.plans.valueYearly'),
        selector: row => row.valueYearly,
        sortable: true,
        cell: row => formatCurrency(row.valueYearly),
    },
    {
        name: t('billing.hw.observations'),
        selector: row => row?.observations,
        sortable: true,
    },
    {
        name: '',
        right: "true",
        cell: (row) => (
            <>
                <Tooltip title={`${t(row?.isActive ? 'generic.active' : 'generic.inactive')}`}>
                    <Switch checked={row?.isActive} onClick={() => handleChangeStatusClick(row)}/>
                </Tooltip>
                <IconButton color="primary" onClick={() => handleEditClick(row)}>
                    <Edit/>
                </IconButton>
            </>
        ),
    },
];