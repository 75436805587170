import React, {useState, useEffect} from 'react'
import {Box, Button, Tab, Tabs, TextField} from "@mui/material";
import BillingService from "../../../services/BillingService";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Loading from "../../Loading";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TotalsConfig from "./TotalsConfig";
import SMSTabContainer from "./tabs/SMSTabContainer";
import VoiceTabContainer from "./tabs/VoiceTabContainer";
import TotalsTabContainer from "./tabs/TotalsTabContainer";
import {useTranslation} from "react-i18next";
import {formatCurrency} from "../../../utils/CurrencyUtil";
import '../../../styles/datepicker.css'
import { saveAs } from 'file-saver';

const ExcelJS = require('exceljs');

const BillingNotificationAuditContainer = () => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const [voiceData, setVoiceData] = useState([]);
    const [smsData, setSmsData] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [voicePrice, setVoicePrice] = useState(0.1);
    const [smsPrice, setSmsPrice] = useState(0.07);
    const [voiceLimit, setVoiceLimit] = useState(0);
    const [smsLimit, setSmsLimit] = useState(300);
    const [loading, setLoading] = useState(false);

    const handleChangeTab = (event, newTab) => {
        setActiveTab(newTab);
    };

    const fetchNotificationData = async (startDate, endDate) => {
        try {
            setLoading(true)
            const voiceAudit = await BillingService.fetchVoiceAudit(startDate, endDate);
            const smsAudit = await BillingService.fetchSMSAudit(startDate, endDate);
            const voiceDataWithTypes = voiceAudit.map(item => ({ ...item, type: 'VOICE' }));
            const smsDataWithTypes = smsAudit.map(item => ({ ...item, type: 'SMS' }));

            setSmsData(smsDataWithTypes);
            setVoiceData(voiceDataWithTypes);
            combineData(voiceDataWithTypes, smsDataWithTypes);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching voice data:', error);
        }
    };

    useEffect(() => {
        const start = startDate.toLocaleDateString('en-CA').split('T')[0];
        const end = endDate.toLocaleDateString('en-CA').split('T')[0];
        fetchNotificationData(start, end);
        // eslint-disable-next-line
    }, [startDate, endDate]);

    const handleFilterClick = () => {
        const start = startDate.toLocaleDateString('en-CA').split('T')[0];
        const end = endDate.toLocaleDateString('en-CA').split('T')[0];
        fetchNotificationData(start, end);
    };

    const combineData = (voiceData, smsData) => {
        const combined = [...voiceData, ...smsData];
        setCombinedData(combined);
        applyTotalFilters(combined);
    };

    const applyTotalFilters = (data) => {
        const updatedData = data.map(item => {
            const isVoice = item.type === 'VOICE';
            const price = isVoice ? voicePrice : smsPrice;
            const limit = isVoice ? voiceLimit : smsLimit;
            const difference = item.counter > limit ? item.counter - limit : 0;
            const value = difference * price;

            return {
                ...item,
                rawValue: value,
                rawPrice: price,
                price: formatCurrency(price),
                limit, difference,
                value: formatCurrency(value)
            };
        });
        setCombinedData(updatedData);
    };

    const createSheetWithTotals = (worksheet, data) => {
        worksheet.columns = [
            { header: t('billing.notifications.exportData.headers.clientCode'), key: 'clientCode', width: 15 },
            { header: t('billing.notifications.exportData.headers.client'), key: 'clientName', width: 20 },
            { header: t('billing.notifications.exportData.headers.farm'), key: 'entityName', width: 25 },
            { header: t('billing.notifications.exportData.headers.type'), key: 'type', width: 10 },
            { header: t('billing.notifications.exportData.headers.qty'), key: 'counter', width: 10 },
            { header: t('billing.notifications.exportData.headers.limit'), key: 'limit', width: 10 },
            { header: t('billing.notifications.exportData.headers.difference'), key: 'diff', width: 10 },
            { header: t('billing.notifications.exportData.headers.price'), key: 'price', width: 15, style: { numFmt: '€ #,##0.00' } },
            { header: t('billing.notifications.exportData.headers.value'), key: 'value', width: 15, style: { numFmt: '€ #,##0.00' } }
        ];

        worksheet.getRow(1).eachCell(cell => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff9933' }
            };
            cell.font = {
                bold: true,
                color: { argb: 'FF000000' }
            };
            cell.alignment = { horizontal: 'center' };
        });

        const groupedByClient = data.reduce((acc, item) => {
            const clientKey = item.clientCode;
            if (!acc[clientKey]) {
                acc[clientKey] = [];
            }
            acc[clientKey].push(item);
            return acc;
        }, {});

        let grandTotalValue = 0;

        Object.keys(groupedByClient).forEach(clientKey => {
            const clientData = groupedByClient[clientKey];

            let clientTotalValue = 0;

            clientData.forEach(item => {
                worksheet.addRow({
                    clientName: item.clientName,
                    clientCode: item.clientCode,
                    entityName: item.entityName,
                    counter: item.counter,
                    type: item.type,
                    price: item.rawPrice,
                    limit: item.limit,
                    diff: item.difference,
                    value: item.rawValue
                });

                clientTotalValue += item.rawValue;
            });

            const clientTotalRow = worksheet.addRow({
                clientName: (clientData[0].clientName || ''),
                value: clientTotalValue
            });
            clientTotalRow.getCell('value').numFmt = '€ #,##0.00';
            clientTotalRow.font = { bold: true };

            grandTotalValue += clientTotalValue;
        });

        worksheet.addRow([]);

        const grandTotalRow = worksheet.addRow({
            price: 'TOTAL',
            value: grandTotalValue
        });

        grandTotalRow.getCell('value').numFmt = '€ #,##0.00';
        grandTotalRow.font = { bold: true };

        worksheet.autoFilter = {
            from: 'A1',
            to: 'I1'
        };
    };

    const handleExportClick = () => {
        const workbook = new ExcelJS.Workbook();

        const worksheetTotals = workbook.addWorksheet(t('billing.notifications.exportData.sheet.voiceAndSms'));
        createSheetWithTotals(worksheetTotals, combinedData);

        const smsData = combinedData.filter(item => item.type === 'SMS');
        const worksheetSms = workbook.addWorksheet(t('billing.notifications.exportData.sheet.sms'));
        createSheetWithTotals(worksheetSms, smsData);

        const voiceData = combinedData.filter(item => item.type === 'VOICE');
        const worksheetVoice = workbook.addWorksheet(t('billing.notifications.exportData.sheet.voice'));
        createSheetWithTotals(worksheetVoice, voiceData);

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `voice_and_sms.xlsx`);
        });
    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, margin: 1, alignItems: 'center'}}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        customInput={<TextField label={t('generic.inputs.startDate')}/>}
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        customInput={<TextField label={t('generic.inputs.endDate')} />}
                    />
                    <Button variant="contained" color="primary" onClick={handleFilterClick}>
                        {t('generic.filter')}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleExportClick}
                        endIcon={<FileDownloadIcon />}
                    >
                        {t('generic.export')}
                    </Button>
                </Box>
            </Box>

                {activeTab === 2 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <TotalsConfig
                        voicePrice={voicePrice}
                        setVoicePrice={setVoicePrice}
                        smsPrice={smsPrice}
                        setSmsPrice={setSmsPrice}
                        voiceLimit={voiceLimit}
                        setVoiceLimit={setVoiceLimit}
                        smsLimit={smsLimit}
                        setSmsLimit={setSmsLimit}
                        applyTotalFilters={() => applyTotalFilters(combinedData)}/>
                    </Box>

                )}

            <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '80%' }}>
                <Tabs value={activeTab} onChange={handleChangeTab} aria-label="billing tabs">
                    <Tab label={t('billing.notifications.tabs.sms')} />
                    <Tab label={t('billing.notifications.tabs.voice')} />
                    <Tab label={t('billing.notifications.tabs.totals')} />
                </Tabs>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <Box sx={{ maxWidth: '80%' }}>
                    <TabPanel value={activeTab} index={0}>
                        <SMSTabContainer data={smsData}/>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <VoiceTabContainer data={voiceData}/>
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <TotalsTabContainer data={combinedData} />
                    </TabPanel>
                </Box>
            )}

        </Box>
    );

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
}

export default BillingNotificationAuditContainer;