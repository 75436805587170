import {Box, Button, Chip, Switch, Stack, Tooltip} from "@mui/material";
import React from "react";

export const getColumns = (t, handleChangeStatusClick) => [
    {
        name: t('billing.farms.name'),
        selector: row => row.name,
        sortable: true,
        grow: 2
    },
    {
        name: t('billing.farms.group'),
        selector: row => row.group,
        sortable: true,
    },
    {
        name: t('billing.farms.country'),
        selector: row => row.country,
        sortable: true,
    },
    {
        name: t('billing.farms.installationDate'),
        selector: row => new Date(row.installationDate).toLocaleDateString(),
        sortFunction: (a, b) => new Date(a.installationDate) - new Date(b.installationDate),
        sortable: true,
    },
    {
        name: t('billing.farms.terminalCount'),
        selector: row => row.terminalCount,
        sortable: true,
        grow: 2
    },
    {
        name: t('billing.farms.billingTerminalCount'),
        selector: row => row.billingTerminalCount,
        sortable: true,
        grow: 2
    },
    {
        name: t('billing.farms.terminalCountStatus'),
        selector: row => (
            <Box
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: row.terminalCount === row.billingTerminalCount ? 'green' : 'red'
                }}
            />
        ),
        sortable: true,
        grow: 2
    },
    {
        name: t('billing.farms.farmcare'),
        selector: row => (row.farmcare ? t('generic.yes') : t('generic.no')),
        sortable: true,
    },
    {
        name: t('billing.farms.active'),
        selector: row => (
            <Box
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: row.status ? 'green' : 'red'
                }}
            />
        ),
        sortable: true,
    },
    {
        name: '',
        right: "true",
        cell: (row) => (
            <Tooltip title={`${t(row?.isActive ? 'generic.active' : 'generic.inactive')}`}>
                <Switch checked={row?.isActive} onClick={() => handleChangeStatusClick(row)}/>
            </Tooltip>

        )
    },
];

export const getFarmsWithoutBillingColumns = (t, handleCreateBillingFarm) => [
    {
        name: t('billing.farms.name'),
        selector: row => row.name,
        sortable: true,
        grow: 2
    },
    {
        name: t('billing.farms.parent'),
        selector: row => row.parentName,
        sortable: true,
        grow: 2
    },
    {
        name: t('billing.farms.creationDate'),
        selector: row => new Date(row.creationTimestampUtc).toLocaleDateString(),
        sortable: true,
        sortFunction: (a, b) => new Date(a.creationTimestampUtc) - new Date(b.creationTimestampUtc),
    },
    {
        name: t('billing.farms.terminalCount'),
        selector: row => (
            <Stack direction="row" spacing={1}>
                {row.activeTerminalCount > 0 && (
                    <Tooltip title={t('billing.farms.terminalStatus.active')}>
                        <Chip
                            label={`${row.activeTerminalCount}`}
                            color="success"
                            size="small"
                        />
                    </Tooltip>
                )}
                {row.maintenanceTerminalCount > 0 && (
                    <Tooltip title={t('billing.farms.terminalStatus.maintenance')}>
                        <Chip
                            label={`${row.maintenanceTerminalCount}`}
                            color="warning"
                            size="small"
                        />
                    </Tooltip>
                )}
                {row.testingTerminalCount > 0 && (
                    <Tooltip title={t('billing.farms.terminalStatus.testing')}>
                        <Chip
                            label={`${row.testingTerminalCount}`}
                            color="info"
                            size="small"
                        />
                    </Tooltip>
                )}
                {row.errorTerminalCount > 0 && (
                    <Tooltip title={t('billing.farms.terminalStatus.error')}>
                        <Chip
                            label={`${row.errorTerminalCount}`}
                            color="error"
                            size="small"
                        />
                    </Tooltip>
                )}
                {row.stockTerminalCount > 0 && (
                    <Tooltip title={t('billing.farms.terminalStatus.stock')}>
                        <Chip
                            label={`${row.stockTerminalCount}`}
                            color="default"
                            size="small"
                        />
                    </Tooltip>
                )}
            </Stack>
        ),
        sortable: true,
    },
    {
        name: '',
        right: "true",
        cell: (row) => (
            <Button variant="contained" color="primary" onClick={() => handleCreateBillingFarm(row)}>
                {t('billing.farms.createBillingFarmBtn')}
            </Button>

        )
    },
]