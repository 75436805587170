import {Avatar, Box, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {useAuth} from "../context/AuthContext";

const UserAvatar = () => {
    const { user, logout } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        logout();
    };

    const getInitials = (name) => {
        const names = name.split(' ');
        const initials = names.map(name => name[0]).join('');
        return initials.toUpperCase();
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar onClick={handleAvatarClick} style={{ cursor: 'pointer' }}>
                {getInitials(user.name)}
            </Avatar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </Box>
    )
}

export default UserAvatar;