import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageService from "../services/LanguageService";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: LanguageService.defaultLanguage,
        whitelist: LanguageService.supportedLanguages,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        parseMissingKeyHandler: (key) => {
            return key.split(".").pop();
        }
    });

export default i18n;