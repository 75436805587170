import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import ExportService from "../../../services/ExportService";
import {Box, Button, TextField, Switch, FormControlLabel, Alert, Snackbar, Tabs, Tab, Typography} from "@mui/material";
import SelectField from "../../form/SelectField";
import ExportButton from "../../form/ExportButton";
import {Add} from "@mui/icons-material";
import DataTableComponent from "../../dataTable/DataTableComponent";
import SaasBillingService from "../../../services/SaasBillingService";
import BillingSaasModal from "./BillingSaasModal";
import BillingFarmService from "../../../services/BillingFarmService";
import BillingPlanService from "../../../services/BillingPlanService";
import {getColumns} from "./config";
import ExpandableSaasBillingInfo from "./ExpandableSaasBillingInfo";
import AuxTableService from "../../../services/AuxTableService";
import {formatCurrency} from "../../../utils/CurrencyUtil";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import YearlySaasBillingContainer from "./YearlySaasBillingContainer";
import BudgetAnnualContainer from "./BudgetAnnualContainer";
import DeleteDialog from "../../form/Dialog/DeleteDialog";
import Loading from "../../Loading";

const SaasBillingContainer = () => {
    const newSaasBilling = {
        planCode: '',
        farmId: '',
        beginDate: '',
        endDate: '',
        quantity: 0,
        discount: null,
        obs: ''
    };
    const {t} = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [saasBillings, setSaasBillings] = useState([]);
    const [filteredSaasBillings, setFilteredSaasBillings] = useState([]);
    const [yearlySaasBillingData, setYearlySaasBillingData] = useState([]);
    const [farms, setFarms] = useState([]);
    const [plans, setPlans] = useState([]);
    const [groups, setGroups] = useState([]);
    const [farmFilter, setFarmFilter] = useState('');
    const [groupFilter, setGroupFilter] = useState('');
    const [planFilter, setPlanFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [saasBilling, setSaasBilling] = useState(newSaasBilling);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [showExpired, setShowExpired] = useState(false);
    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchFarms();
        fetchPlans();
        fetchGroups();
    }, []);

    useEffect(() => {
        fetchSaasBillings();

        // eslint-disable-next-line
    }, [selectedDate]);

    useEffect(() => {
        filterSaasBillings();

        // eslint-disable-next-line
    }, [farmFilter, groupFilter, planFilter, startDateFilter, endDateFilter, showExpired, saasBillings]);

    const fetchSaasBillings = async () => {
        try {
            setLoading(true);
            const response = await SaasBillingService.fetchAllSaasBilling(selectedDate);
            const sortedSaasBillings = response.sort((a, b) => a.farmName.localeCompare(b.farmName));
            setSaasBillings(sortedSaasBillings);
            setFilteredSaasBillings(sortedSaasBillings);
        } catch (error) {
            console.error('Error fetching saas billings :', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFarms = async () => {
        try {
            const response = await BillingFarmService.fetchAllFarms();
            setFarms(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching Billing farms:', error);
        }
    };

    const fetchPlans = async () => {
        try {
            const response = await BillingPlanService.fetchAllPlans();
            setPlans(response.sort((a, b) => a.description.localeCompare(b.description)));
        } catch (error) {
            console.error('Error fetching Billing plans:', error);
        }
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate);
    };

    const fetchGroups = async () => {
        try {
            const response = await AuxTableService.fetchAllBillingGroups();
            setGroups(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching Billing groups:', error);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleEditClick = (saasBilling) => {
        setSaasBilling(saasBilling);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleDeleteClick = (saasBilling) => {
        setSaasBilling(saasBilling);
        setDeleteDialog(true);
    };

    const deleteSaasBillings = async () => {
        try {
            await SaasBillingService.deleteSaasBilling(saasBilling?.id);
            setSnackbarOpen({open: true, severity: 'success', text: t('billing.saas.delete.success')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('billing.saas.delete.failure')});
            console.error('Error deleting saas billings :', error);
        }
    };

    const handleDelete = () => {
        deleteSaasBillings()
            .then(async () => {
                setDeleteDialog(false);
                await fetchSaasBillings();
            })
            .catch((error) => {
                console.error('Error in handleChangeStatus:', error)
            });
    }

    const handleAddNewClick = () => {
        setSaasBilling(newSaasBilling);
        setIsEditing(false);
        setModalOpen(true);
    };

    const handleSave = async () => {
        const saasBillingToStore = { ...saasBilling };
        if (saasBillingToStore.discount !== null && saasBillingToStore.discount !== undefined) {
            saasBillingToStore.discount = saasBillingToStore.discount / 100;
        }
        setSaasBilling(newSaasBilling);
        setModalOpen(false);
        if(isEditing){
            try {
                await SaasBillingService.updateSaasBilling(saasBillingToStore.id, saasBillingToStore);
            } catch (error) {
                console.error('Error updating Saas Billing:', error);
            }
        } else {
            try {
                await SaasBillingService.createSaasBilling(saasBillingToStore);
            } catch (error) {
                console.error('Error adding new Saas Billing:', error);
            }
        }
        fetchSaasBillings();
        setSnackbarOpen({open: true, severity: 'success', text: t('billing.saas.saveSuccess')});

    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const filterSaasBillings = () => {
        let filtered = saasBillings;

        if (farmFilter) {
            filtered = filtered.filter(item => item.farmId === farmFilter);
        }

        if (groupFilter) {
            filtered = filtered.filter(item => item.group === groupFilter);
        }

        if (planFilter) {
            filtered = filtered.filter(item => item.planCode === planFilter);
        }

        if (startDateFilter) {
            const startDate = new Date(startDateFilter);
            filtered = filtered.filter(item => new Date(item.nextRenewal) >= startDate);
        }

        if (endDateFilter) {
            const endDate = new Date(endDateFilter);
            filtered = filtered.filter(item => new Date(item.nextRenewal) <= endDate);
        }

        if (!showExpired) {
            filtered = filtered.filter(item => !item.expired);
        }

        setFilteredSaasBillings(filtered);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSaasBilling({ ...saasBilling, [name]: value });
    };

    const handleCurrencyChange = (name, value) => {
        setSaasBilling({ ...saasBilling, [name]: parseFloat(value) });
    };

    const handleDateInputChange = (event) => {
        const { name, value } = event.target;
        if(value) {
            const date = new Date(value)
            setSaasBilling({...saasBilling, [name]: date.toISOString().split('T')[0] + 'T00:00:00'});
        }
    }

    const handleClearFilters = () => {
        setFarmFilter('');
        setGroupFilter('');
        setPlanFilter('');
        setStartDateFilter('');
        setEndDateFilter('');
    };

    const handleExport = (format) => {
        const dataToExport = tabValue === 0 ? filteredSaasBillings : yearlySaasBillingData;

        const fileName = 'saas_billing';

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }
    };

    const handleRowClick = (row) => {
        setExpandedRows((prev) => ({
            ...prev,
            [row.id]: !prev[row.id],
        }));
    };

    const calculateTotals = () => {
        let totalYearlyValue = 0;
        let totalMonthlyValue = 0;

        filteredSaasBillings.forEach(item => {
            totalYearlyValue += item.valueYearly || 0;
            totalMonthlyValue += item.valueMonthly || 0;
        });

        return {
            totalYearlyValue: formatCurrency(totalYearlyValue),
            totalMonthlyValue: formatCurrency(totalMonthlyValue),
        };
    };

    const handleExportMonthlyBillingToExcel = () => {
        ExportService.exportMonthlyBillingMap(saasBillings, selectedDate);
    }

    const handleExportMonthlyBillingClientToExcel = () => {
        ExportService.exportMonthlyBillingMapWithClient(saasBillings, selectedDate);
    }

    const totals = calculateTotals();

    const isRowExpanded = (row) => !!expandedRows[row.id];

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, flexWrap: 'wrap', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center'}}>
                        <SelectField
                            label={t('billing.saas.farm')}
                            value={farmFilter}
                            handleChange={(e) => setFarmFilter(e.target.value)}
                            items={farms.map(farm => ({
                                value: farm.id,
                                text: farm.name,
                            }))}
                        />
                        <SelectField
                            label={t('billing.saas.group')}
                            value={groupFilter}
                            handleChange={(e) => setGroupFilter(e.target.value)}
                            items={groups.map(group => ({
                                value: group.name,
                                text: group.name,
                            }))}
                        />
                        <SelectField
                            label={t('billing.saas.plan')}
                            value={planFilter}
                            handleChange={(e) => setPlanFilter(e.target.value)}
                            items={plans.map(plan => ({
                                value: plan.code,
                                text: plan.description,
                            }))}
                        />

                        <TextField
                            label={t('billing.saas.beginNextRenewal')}
                            type="date"
                            value={startDateFilter}
                            onChange={(e) => setStartDateFilter(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />

                        <TextField
                            label={t('billing.saas.endNextRenewal')}
                            type="date"
                            value={endDateFilter}
                            onChange={(e) => setEndDateFilter(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />

                        <FormControlLabel
                            control={<Switch checked={showExpired} onChange={(e) => setShowExpired(e.target.checked)} />}
                            label={t('billing.saas.showExpired')}
                        />
                        <TextField
                            label={t('billing.saas.selectDate')}
                            type="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button variant="contained" color="primary" onClick={handleClearFilters}>
                            {t('generic.clearFilter')}
                        </Button>

                        <ExportButton onExport={handleExport} />
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleExportMonthlyBillingToExcel}
                            endIcon={<FileDownloadIcon />}
                        >
                            {t('billing.saas.exportBillingMonthly')}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleExportMonthlyBillingClientToExcel}
                            endIcon={<FileDownloadIcon />}
                        >
                            {t('billing.saas.exportBillingMonthlyClient')}
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddNewClick}
                        startIcon={<Add />}
                        sx={{ padding: '10px 20px', fontSize: '16px' }}
                    >
                        {t('generic.addNew')}
                    </Button>
                </Box>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="SaaS Billing Tabs">
                        <Tab label={t("billing.saas.tabSaasBilling")} />
                        <Tab label={t("billing.saas.tabSaasBillingAnnual")} />
                        <Tab label={t("billing.saas.tabSaasBillingBudgetAnnual")} />
                    </Tabs>
                    {tabValue === 0 && (
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 4, marginBottom: 5 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1}}>
                                    <Typography variant="h7">{t('billing.saas.totalMonthlyValue')}</Typography>
                                    <Typography variant="h4" sx={{fontWeight: 'bold'}}> {totals.totalMonthlyValue}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1}}>
                                    <Typography variant="h7">{t('billing.saas.totalYearlyValue')}</Typography>
                                    <Typography variant="h4" sx={{fontWeight: 'bold'}}> {totals.totalYearlyValue}</Typography>
                                </Box>
                            </Box>
                            <DataTableComponent
                                columns={getColumns(t, handleEditClick, handleDeleteClick)}
                                data={filteredSaasBillings}
                                pagination
                                expandableRows
                                expandableRowsComponent={({ data }) => <ExpandableSaasBillingInfo data={data} />}
                                onRowClicked={handleRowClick}
                                expandableRowExpanded={isRowExpanded}
                            />
                        </Box>
                    )}
                    {tabValue === 1 && (
                        <YearlySaasBillingContainer
                            saasBillings={filteredSaasBillings}
                            onDataChange={setYearlySaasBillingData}
                            filterDate={selectedDate}
                        />
                    )}
                    {tabValue === 2 && (
                        <BudgetAnnualContainer
                            saasBillings={saasBillings}
                            filterDate={selectedDate}
                        />
                    )}
                </>
            )}
            <BillingSaasModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                isEditing={isEditing}
                saasBilling={saasBilling}
                farms={farms}
                plans={plans}
                handleInputChange={handleInputChange}
                handleCurrencyChange={handleCurrencyChange}
                handleDateInputChange={handleDateInputChange}
                handleSave={handleSave}
            />
            <DeleteDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                handleDelete={handleDelete}
                title={t('billing.saas.delete.title')}
                text={t('billing.saas.delete.text')}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{width: '100%'}}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SaasBillingContainer;