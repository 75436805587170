import React from 'react'
import {FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, Chip, Box} from "@mui/material";

const SelectField = ({label, name, value, handleChange, items, multiple,  fullWidth = false,
                         disabled = false, SelectProps = {}}) => {
    return (
        <FormControl sx={{m: 1,  minWidth: 150 }} size="medium" fullWidth={fullWidth} >
            <InputLabel>{label}</InputLabel>
            <Select
                name={name}
                value={value}
                label={label}
                multiple={multiple}
                onChange={handleChange}
                disabled={disabled}
                {...SelectProps}
                renderValue={(selected) => {
                    if (multiple) {
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip
                                        key={value}
                                        label={items.find(item => item.value === value)?.text}
                                        sx={{
                                            height: 18,
                                            fontSize: '0.75rem',
                                            padding: '0 4px',
                                        }}
                                    />
                                ))}
                            </Box>
                        );
                    } else {
                        return items.find(item => item.value === selected)?.text;
                    }
                }}
            >
                {items.map((item, idx) => (
                    <MenuItem key={idx} value={item.value}>
                        {multiple && <Checkbox checked={value.indexOf(item.value) > -1} />}
                        <ListItemText primary={item.text} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectField;