import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import ExportService from "../../../services/ExportService";
import {Alert, Box, Button, Snackbar, TextField} from "@mui/material";
import SelectField from "../../form/SelectField";
import ExportButton from "../../form/ExportButton";
import {Add} from "@mui/icons-material";
import DataTableComponent from "../../dataTable/DataTableComponent";
import {getColumns} from "./config";
import HwComponentService from "../../../services/HwComponentService";
import HwComponentModal from "./HwComponentModal";
import AuxTableService from "../../../services/AuxTableService";
import GenericDialog from "../../form/Dialog/GenericDialog";
import Loading from "../../Loading";

const HwComponentsContainer = () => {
    const newHwComponent = { hardware: '', hwSupplier: '', saasSalesType: '', terminal: false, observations: '' };
    const {t} = useTranslation();
    const [hwComponents, setHwComponents] = useState([]);
    const [filteredHwComponents, setFilteredHwComponents] = useState([]);
    const [hwSuppliers, setHwSuppliers] = useState([]);
    const [saasSalesTypes, setSaasSalesTypes] = useState([]);
    const [hardwareFilter, setHardwareFilter] = useState('');
    const [saasSaleTypeFilter, setSaasSalesTypeFilter] = useState('');
    const [hwSupplierFilter, setHwSupplierFilter] = useState('');
    const [hwComponent, setHwComponent] = useState(newHwComponent);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);
    const [changeStatusDialog, setChangeStatusDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchSaasSalesTypes();
        fetchHwSuppliers();
        fetchHwComponents();
    }, []);

    useEffect(() => {
        filterHwComponents();

        // eslint-disable-next-line
    }, [hardwareFilter, saasSaleTypeFilter, hwSupplierFilter, hwComponents]);

    const fetchHwComponents = async () => {
        try {
            setLoading(true)
            const response = await HwComponentService.fetchAllHwComponents();
            const sortedHwComponents = response.sort((a, b) => a.hardware.localeCompare(b.hardware));
            setHwComponents(sortedHwComponents);
            setFilteredHwComponents(sortedHwComponents);
        } catch (error) {
            console.error('Error fetching Hw Components:', error);
        } finally {
            setLoading(false)
        }
    };

    const fetchSaasSalesTypes = async () => {
        try {
            const response = await AuxTableService.fetchAllSaasSalesTypes();
            const sortedSaasSalesTypes = response.sort((a, b) => a.name.localeCompare(b.name));
            setSaasSalesTypes(sortedSaasSalesTypes);
        } catch (error) {
            console.error('Error fetching SaaS Sales Types:', error);
        }
    };

    const fetchHwSuppliers = async () => {
        try {
            const response = await AuxTableService.fetchAllHwSuppliers();
            const sortedHwSuppliers = response.sort((a, b) => a.name.localeCompare(b.name));
            setHwSuppliers(sortedHwSuppliers);
        } catch (error) {
            console.error('Error fetching Hw Suppliers:', error);
        }
    };

    const handleEditClick = (hwComponent) => {
        setHwComponent(hwComponent);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleChangeStatusClick = (hwComponent) => {
        setHwComponent(hwComponent);
        setChangeStatusDialog(true);
    };

    const changeStatusHwComponent = async () => {
        try {
            await HwComponentService.changeStatusHwComponent(hwComponent?.hardware);
            setSnackbarOpen({open: true, severity: 'success', text: t('billing.hw.components.status.saveSuccess')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('billing.hw.components.saveFailure')});
            console.error('Error changing status of hardware component :', error);
        }
    };

    const handleChangeStatus = () => {
        changeStatusHwComponent()
            .then(async () => {
                setChangeStatusDialog(false);
                await fetchHwComponents();
            })
            .catch((error) => {
                console.error('Error in handleChangeStatus:', error)
            });
    }

    const handleAddNewClick = () => {
        setHwComponent(newHwComponent);
        setIsEditing(false);
        setModalOpen(true);
    };

    const handleSave = async () => {
        setHwComponent(newHwComponent);
        setModalOpen(false);
        if(isEditing){
            try {
                await HwComponentService.updateHwComponent(hwComponent.hardware, hwComponent);
            } catch (error) {
                console.error('Error updating hw component:', error);
            }
        } else {
            try {
                await HwComponentService.createHwComponent(hwComponent);
            } catch (error) {
                console.error('Error adding new hw component:', error);
            }
        }

        fetchHwComponents()
        setSnackbarOpen({open: true, severity: 'success', text: t('billing.hw.components.saveSuccess')});
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const filterHwComponents = () => {
        let filtered = hwComponents;

        if (hardwareFilter) {
            filtered = filtered.filter(hwComp => hwComp.hardware.toLowerCase().includes(hardwareFilter.toLowerCase()));
        }

        if (saasSaleTypeFilter) {
            filtered = filtered.filter(hwComp => hwComp.saasSalesType === saasSaleTypeFilter);
        }

        if (hwSupplierFilter) {
            filtered = filtered.filter(hwComp => hwComp.hwSupplier === hwSupplierFilter);
        }

        setFilteredHwComponents(filtered);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setHwComponent({ ...hwComponent, [name]: value });
    };

    const handleTerminalSwitchChange = (event) => {
        handleInputChange({ target: { name: 'terminal', value: event.target.checked } });
    };

    const handleClearFilters = () => {
        setHardwareFilter('');
        setHwSupplierFilter('');
        setSaasSalesTypeFilter('');
    };

    const handleExport = (format) => {
        const dataToExport = filteredHwComponents;
        const fileName = 'hw_components';

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }

    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2 }}>
                    <TextField
                        label={t('billing.hw.components.hardware')}
                        value={hardwareFilter}
                        onChange={(e) => setHardwareFilter(e.target.value)}
                        sx={{m: 1}}
                    />
                    <SelectField
                        label={t('billing.hw.components.hwSupplier')}
                        value={hwSupplierFilter}
                        handleChange={(e) => setHwSupplierFilter(e.target.value)}
                        items={hwSuppliers.map(hwSupplier => ({
                            value: hwSupplier.name,
                            text: hwSupplier.name,
                        }))}
                    />
                    <SelectField
                        label={t('billing.hw.components.saasSalesType')}
                        value={saasSaleTypeFilter}
                        handleChange={(e) => setSaasSalesTypeFilter(e.target.value)}
                        items={saasSalesTypes.map(saasSaleType => ({
                            value: saasSaleType.name,
                            text: saasSaleType.name,
                        }))}
                    />

                    <Button variant="contained" color="primary" onClick={handleClearFilters}>
                        {t('generic.clearFilter')}
                    </Button>

                    <ExportButton onExport={handleExport} />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewClick}
                    startIcon={<Add />}
                    sx={{ padding: '10px 20px', fontSize: '16px' }}
                >
                    {t('generic.addNew')}
                </Button>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <DataTableComponent columns={getColumns(t, handleEditClick, handleChangeStatusClick)} data={filteredHwComponents} pagination />
            )}

            <HwComponentModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                isEditing={isEditing}
                hwComponent={hwComponent}
                hwSuppliers={hwSuppliers}
                saasSalesTypes={saasSalesTypes}
                handleInputChange={handleInputChange}
                handleTerminalSwitchChange={handleTerminalSwitchChange}
                handleSave={handleSave}
            />
            <GenericDialog
                open={changeStatusDialog}
                handleClose={() => setChangeStatusDialog(false)}
                handleConfirm={handleChangeStatus}
                title={t('billing.hw.components.status.dialog.title')}
                text={`${t('billing.hw.components.status.dialog.text')} ${t(hwComponent.isActive ? 'generic.inactive' : 'generic.active')}.`}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{ width: '100%' }}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default HwComponentsContainer;