import axios from "../config/axios";

const EventService = {

    fetchLatestCriticalEvents: async () => {
        try {
            const response = await axios.get(`/events/critical-events`);
            return response.data;
        } catch (error){
            throw error;
        }
    },
    fetchOpenHelpdeskEvents: async () => {
        try {
            const response = await axios.get(`/events/open-helpdesks`);
            return response.data;
        } catch (error){
            throw error;
        }
    }
};

export default EventService;