import {IconButton} from "@mui/material";
import {Edit, ContentCopy} from "@mui/icons-material";

export const getColumns = (t, handleEditClick) => [
    {
        name: t('contacts.farm'),
        selector: row => row.farm,
        sortable: true,
    },
    {
        name: t('contacts.entity'),
        selector: row => row.entity,
        sortable: true,
    },
    {
        name: '',
        right: "true",
        cell: (row) => (
            <IconButton color="primary" onClick={() => handleEditClick(row)}>
                <Edit />
            </IconButton>
        ),
    },
];
export const getContactColumns = (t, handleCopyClick) => [
    {
        name: t('contacts.contactList.name'),
        selector: row => row.name,
        sortable: true,
    },
    {
        name: t('contacts.contactList.email'),
        selector: row => row.email,
        sortable: true,
        cell: row => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {row.email && (
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleCopyClick(row.email)}
                        style={{ marginLeft: '8px' }}
                    >
                        <ContentCopy fontSize="small" />
                    </IconButton>
                )}
                <span style={{ marginLeft: '8px' }}>{row.email}</span>
            </div>
        ),
    },
    {
        name: t('contacts.contactList.phone'),
        selector: row => row.phone,
        sortable: true,
        cell: row => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {row.phone && (
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleCopyClick(row.phone)}
                        style={{ marginLeft: '8px' }}
                    >
                        <ContentCopy fontSize="small" />
                    </IconButton>
                )}
                <span style={{ marginLeft: '8px' }}>{row.phone}</span>
            </div>
        ),
    },
];