import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";

const DeleteDialog = ({open, handleClose, handleDelete, title, text}) => {

    const {t} = useTranslation();

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleClose} sx={{backgroundColor: 'gray', color: 'white'}}>
                {t('generic.cancel')}
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
                {t('generic.delete')}
            </Button>
        </DialogActions>
    </Dialog>
}

export default DeleteDialog