import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

const ActionCell = ({ row, handleEditClick, handleDeleteClick }) => {
    const [isDeleteDisabled, setDeleteDisabled] = useState(true);

    useEffect(() => {
        const checkDependencies = async () => {
            try {
                const response = (row.dependencies);
                setDeleteDisabled(response);
            } catch (error) {
                setDeleteDisabled(false);
            }
        };
        checkDependencies();
    }, [row.name, row.dependencies]);

    return (
        <>
            <IconButton color="primary" onClick={() => handleEditClick(row)}>
                <Edit />
            </IconButton>
            <IconButton
                color="error"
                disabled={isDeleteDisabled}
                onClick={() => handleDeleteClick(row)}
            >
                <Delete />
            </IconButton>
        </>
    );
};

export function getColumns(t, translationPath, handleEditClick, handleDeleteClick) {
    return [
        {
            name: t(translationPath),
            selector: row => row.name,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.name}</div>,
        },
        {
            name: '',
            right: "true",
            cell: row => (
                <ActionCell
                    row={row}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                />
            ),
        },
    ];
}
