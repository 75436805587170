import {IconButton,  Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import React from "react";
import {formatCurrency, formatPercentage} from "../../../utils/CurrencyUtil";

export function getColumns (t, handleEditClick, handleDeleteClick) {
    return [
        {
            name: t('billing.saas.farm'),
            selector: row => row.farmName,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.farmName}</div>,
        },
        {
            name: t('billing.saas.group'),
            selector: row => row.group,
            sortable: true,
        },
        {
            name: t('billing.saas.plan'),
            cell: row => (<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.planName}</div>
                <div style={{ fontWeight: 'bold', fontSize: '14px'}}>{row.period}</div>
            </div>),
            selector: row => row.planName,
            sortable: true,
        },
        {
            name: t('billing.saas.beginDate'),
            selector: row => new Date(row.beginDate).toLocaleDateString(),
            sortFunction: (a, b) => new Date(a.beginDate) - new Date(b.beginDate),
            sortable: true,
        },
        {
            name: t('billing.saas.endDate'),
            selector: row => new Date(row.endDate).toLocaleDateString(),
            sortFunction: (a, b) => new Date(a.endDate) - new Date(b.endDate),
            sortable: true,
        },
        {
            name: t('billing.saas.nextRenewal'),
            selector: row => row.expired ? t('billing.saas.expired') : new Date(row.nextRenewal).toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit'
            }),
            sortFunction: (a, b) => new Date(a.nextRenewal) - new Date(b.nextRenewal),
            sortable: true,
        },
        {
            name: t('billing.saas.quantity'),
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: t('billing.saas.price'),
            selector: row => row.price,
            sortable: true,
            cell: row => formatCurrency(row.price),
        },
        {
            name: t('billing.saas.discount'),
            selector: row => row.discount,
            sortable: true,
            cell: row => (
                <Tooltip title={row.hasCustomDiscount ? t('billing.saas.customDiscountTooltip') : ''} arrow placement={"top"}>
                    <div style={{ color: row.hasCustomDiscount ? '#FFC107' : 'inherit' }}>
                        {formatPercentage(row.discount)}
                    </div>
                </Tooltip>
            ),
        },
        {
            name: t('billing.saas.netValue'),
            selector: row => row.netValue,
            sortable: true,
            cell: row => formatCurrency(row.netValue),
        },
        {
            name: '',
            right: true,
            cell: (row) => (
                <>
                    <IconButton color="primary" onClick={() => handleEditClick(row)}>
                        <Edit/>
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteClick(row)}>
                        <Delete/>
                    </IconButton>
                </>
            ),
        },
    ];
}