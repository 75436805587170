import {getElapsedTime, formatDate} from "../../../utils/datesUtil";

export function getColumns (t) {
    return [
        {
            name: t('dashboard.disconnectedTerminals.tableHeader.name'),
            selector: row => row.displayName,
            sortable: true,
        },
        {
            name: t('dashboard.disconnectedTerminals.tableHeader.farm'),
            selector: row => row.farm,
            sortable: true,
        },
        {
            name: t('dashboard.disconnectedTerminals.tableHeader.profile'),
            selector: row => row.profile,
            sortable: true,
        },
        {
            name: t('dashboard.disconnectedTerminals.tableHeader.lastComm'),
            sortable: true,
            selector: row => row.lastComm,
            format: (row, idx) => {
                const { date, time } = formatDate(row.lastComm);
                const elapsedTime = getElapsedTime(row.lastComm);
                return (
                    <>
                        {date}<br />{time}<br /><small style={{color: "#888888"}}>{elapsedTime}</small>
                    </>
                );
            }
        },
        {
            name: t('dashboard.disconnectedTerminals.tableHeader.generatedEvent'),
            selector: row => row.hasEvent,
            sortable: false,
            format: (row, idx) => {
                return (
                    <div style={{backgroundColor: row.hasEvent ? "#46ed4a" : "#f24646", height: "24px", width: "24px", borderRadius: "50%"}}></div>
                )
            }
        },
    ];
}