import React, {useEffect, useState} from 'react'
import {getColumns} from "./configs";
import Loading from "../../Loading";
import SelectField from "../../form/SelectField";
import TerminalService from "../../../services/TerminalService";
import dayjs from "../../../config/dayjs-config"
import {Period} from "../../../enums/Period";
import {useTranslation} from "react-i18next";
import DataTableComponent from "../../dataTable/DataTableComponent";
import {Profiles as Profile} from "../../../enums/Profiles";
import {Button, Box} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExportService from "../../../services/ExportService";
import configs from "../../../configs";



const getDateFromPeriod = (period) => {
    switch (period) {
        case Period.LAST_6_HOURS:
            return dayjs().subtract(6, 'hours');
        case Period.LAST_12_HOURS:
            return dayjs().subtract(12, 'hours');
        case Period.LAST_24_HOURS:
            return dayjs().subtract(24, 'hours');
        case Period.LAST_WEEK:
            return dayjs().subtract(1, 'week');
        case Period.LAST_MONTH:
            return dayjs().subtract(1, 'month');
        default:
            return null;
    }
};

const DisconnectedTerminalList = () => {
    const {t} = useTranslation();
    const [period, setPeriod] = useState(Period.ALL);
    const [profiles, setProfiles] = useState([Profile.FC_SMARTBOX_1, Profile.FC_SMARTBOX_ROOM,
        Profile.FC_SBONE, Profile.CONNECTOR_BOX]);
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        setLoading(true)
        const resp = await TerminalService.fetchDisconnectedTerminals();
        const sortedData = resp ? resp.sort((a, b) => dayjs(b.lastComm).diff(dayjs(a.lastComm))) : [];
        setData(sortedData)
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        const periodDate = getDateFromPeriod(period);
        let filtered = data;
        if (periodDate) {
            filtered = filtered.filter(item => dayjs(item.lastComm).isAfter(periodDate));
        }
        if (profiles.length > 0) {
            filtered = filtered.filter(item => profiles.includes(item.profile));
        }

        setFilteredData(filtered);

    }, [period, profiles, data]);
    const handleChangePeriod = (event) => {
        setPeriod(event.target.value);
    };

    const handleChangeProfile = (event) => {
        setProfiles(event.target.value)
    }

    const handleRefresh = () => {
        fetchData();
    };

    const handleExport = () => {
        const formattedData = filteredData.map(item => ({
            ...item,
            lastComm: dayjs.tz(item.lastComm, 'UTC').tz('Europe/Lisbon').format('YYYY-MM-DD HH:mm:ss'),
        }));

        ExportService.exportToExcel(formattedData, 'disconnected_terminals');
    };

    const handleRowClicked = (row) => {
        window.open(`${configs.host}/system/terminals/details/${row.id}/details`, '_blank');
    };

    return (
            <div>
                <Box display="flex" flexDirection={"column"}>
                    <Box display="flex" alignItems={"center"}>
                        <SelectField
                            label={t('dashboard.disconnectedTerminals.period.label')}
                            value={period}
                            handleChange={handleChangePeriod}
                            items={
                                [
                                    {
                                        value: Period.ALL,
                                        text: t('enums.periods.all')
                                    },
                                    {
                                        value: Period.LAST_6_HOURS,
                                        text: t('enums.periods.last6hours')
                                    },
                                    {
                                        value: Period.LAST_12_HOURS,
                                        text: t('enums.periods.last12hours')
                                    },
                                    {
                                        value: Period.LAST_24_HOURS,
                                        text: t('enums.periods.last24hours')
                                    },
                                    {
                                        value: Period.LAST_WEEK,
                                        text: t('enums.periods.lastWeek')
                                    },
                                    {
                                        value: Period.LAST_MONTH,
                                        text: t('enums.periods.lastMonth')
                                    }
                                ]}
                        />
                        <SelectField
                            label={t('dashboard.disconnectedTerminals.profile.label')}
                            value={profiles}
                            multiple={true}
                            handleChange={handleChangeProfile}
                            items={
                                [
                                    {
                                        value: Profile.FC_SMARTBOX_ROOM,
                                        text: Profile.FC_SMARTBOX_ROOM
                                    },
                                    {
                                        value: Profile.FC_SMARTBOX_1,
                                        text: Profile.FC_SMARTBOX_1
                                    },
                                    {
                                        value: Profile.FC_SBONE,
                                        text: Profile.FC_SBONE
                                    },
                                    {
                                        value: Profile.CONNECTOR_BOX,
                                        text: Profile.CONNECTOR_BOX
                                    },
                                ]}
                        />
                    </Box>
                    <Box gap={2}>
                        <Button variant="contained"
                                onClick={handleRefresh}
                                endIcon={loading ? <MoreHorizIcon/> :<RefreshIcon />}
                                sx={{ mr: 2 }}>
                            {loading ? t('generic.loading') : t('dashboard.disconnectedTerminals.refreshBtn')}
                        </Button>
                        <Button variant="contained" onClick={handleExport}  color="success" endIcon={<FileDownloadIcon />}>
                            {t('dashboard.disconnectedTerminals.exportBtn')}
                        </Button>
                    </Box>
                </Box>
                {loading ? (
                    <div style={{marginTop: 20}}>
                        <Loading/>
                        </div>
                    ) :
                    (<div style={{marginTop: 20}}>
                            <DataTableComponent
                                columns={getColumns(t)}
                                data={filteredData}
                                pagination={true}
                                onRowClicked={handleRowClicked}
                            />
                    </div>)}
                </div>
    );
}

export default DisconnectedTerminalList