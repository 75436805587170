import {formatDate, getElapsedTime} from "../../../utils/datesUtil";

export function getColumns (t) {
    return [
        {
            name: t('dashboard.criticalEvents.tableHeader.title'),
            selector: row => row.title,
            sortable: true,
            cell: row => (
                <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflow: 'hidden', maxWidth: '200px' }}>
                    {row.title}
                </div>
            ),
        },
        {
            name: t('dashboard.criticalEvents.tableHeader.entityName'),
            selector: row => row.entityName,
            sortable: true,
        },
        {
            name: t('dashboard.criticalEvents.tableHeader.date'),
            sortable: true,
            selector: row => row.creationDate,
            format: (row, idx) => {
                const { date, time } = formatDate(row.creationDate);
                const elapsedTime = getElapsedTime(row.creationDate);
                return (
                    <>
                        {date}<br />{time}<br /><small style={{color: "#888888"}}>{elapsedTime}</small>
                    </>
                );
            }
        }
    ];
}