import {useTranslation} from "react-i18next";
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import BillingProductsContainer from "../../components/billing/products/BillingProductsContainer";

const BillingProductsPage = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.products.title')}</Typography>
        <BillingProductsContainer />
    </MainLayout>
}

export default BillingProductsPage;