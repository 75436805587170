import {Box, Grid, Typography} from '@mui/material';
import MainLayout from "../components/MainLayout";
import {useTranslation} from "react-i18next";
import DisconnectedTerminalsContainer
    from "../components/homepage/disconnected-terminals/DisconnectedTerminalsContainer";
import ServiceStatusContainer from "../components/homepage/services-status/ServiceStatusContainer";
import LatestCriticalEventsContainer from "../components/homepage/latest-critical-events/LatestCriticalEventsContainer";
import OpenHelpdesksContainer from "../components/homepage/open-helpdesk/OpenHelpdesksContainer";

const HomePage = () => {

    const { t } = useTranslation();

    return (
        <MainLayout>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                    <Box sx={{ height: 300, borderRadius: 2, boxShadow: 4, padding: 2 }}>
                        <Typography>{t('dashboard.statistics.title')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box sx={{ height: 300, borderRadius: 2, boxShadow: 4, padding: 2, overflow: "auto"}}>
                        <ServiceStatusContainer />
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box sx={{ borderRadius: 2, boxShadow: 4, padding: 2, overflow: "auto"}}>
                        <DisconnectedTerminalsContainer />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{ borderRadius: 2, boxShadow: 4, padding: 2, overflow: "auto", height: '800px' }}>
                        <OpenHelpdesksContainer />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ borderRadius: 2, boxShadow: 4, padding: 2, overflow: "auto", height: '800px' }}>
                        <LatestCriticalEventsContainer />
                    </Box>
                </Grid>
            </Grid>
        </MainLayout>
    );
}

export default HomePage;