import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography, Snackbar, Alert } from '@mui/material';
import { useTranslation } from "react-i18next";
import SelectField from "../form/SelectField";
import GenericDialog from "../form/Dialog/GenericDialog";
import DataTableComponent from "../dataTable/DataTableComponent";
import ContactFields from './ContactFields';
import {Add} from "@mui/icons-material";

const ContactsModal = ({ open, handleClose, isEditing, farmId, contacts = [], billingFarms = [], handleSave }) => {
    const { t } = useTranslation();
    const [selectedEntity, setSelectedEntity] = useState('');
    const [selectedFarm, setSelectedFarm] = useState('');
    const [selectedFarmId, setSelectedFarmId] = useState('');
    const [contactList, setContactList] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isEditMode, setIsEditMode] = useState(isEditing);
    const [dialogOpen, setDialogOpen] = useState(false);
    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);

    useEffect(() => {
        if (open) {
            if (isEditing) {
                const contact = contacts.find(contact => contact.farmId === farmId);
                setSelectedEntity(contact.entity || '');
                setSelectedFarm(contact.farm || '');
                setSelectedFarmId(contact.farmId || '');
                setContactList(contact.contacts || []);
                setIsSaveDisabled(!validateContacts(contact.contacts || []));
                setIsEditMode(true);
            } else {
                resetFields();
            }
        }
    }, [isEditing, farmId, contacts, billingFarms, open]);

    const resetFields = () => {
        setSelectedEntity('');
        setSelectedFarm('');
        setSelectedFarmId('');
        setContactList([]);
        setIsSaveDisabled(true);
        setIsEditMode(false);
    };

    const validateContacts = (contacts) => {
        return contacts.every(contact => contact.name && (contact.phone || contact.email));
    };

    const updateContactListAndValidate = (newList) => {
        setContactList(newList);
        setIsSaveDisabled(!validateContacts(newList));
    };

    const handleFarmChange = (event) => {
        const value = event.target.value;
        setSelectedFarm(value);

        const farm = billingFarms.find(farm => farm.name === value);
        setSelectedFarmId(farm.id || '');
        setSelectedEntity(farm.group || '');

        const existingContact = contacts.find(contact => contact.farm === value);
        if (existingContact) {
            setContactList(existingContact.contacts || []);
            setIsEditMode(true);
            setIsSaveDisabled(!validateContacts(existingContact.contacts || []));
        } else {
            updateContactListAndValidate([{ id: '', name: '', phone: '', email: '' }]);
            setIsEditMode(false);
        }
    };

    const handleEntityChange = (event) => {
        const value = event.target.value;
        setSelectedEntity(value);
        setIsSaveDisabled(!value || !selectedFarm || !validateContacts(contactList));
    };

    const handleAddContact = () => {
        updateContactListAndValidate([...contactList, { id: '', name: '', phone: '', email: '' }]);
    };

    const handleEditContact = (index, field, value) => {
        const updatedContacts = [...contactList];
        updatedContacts[index][field] = value;
        updateContactListAndValidate(updatedContacts);
    };

    const handleRemoveContact = (index) => {
        const updatedContacts = contactList.filter((_, i) => i !== index);
        updateContactListAndValidate(updatedContacts);

        if (!isEditMode && updatedContacts.length === 0) {
            setIsSaveDisabled(true);
        }
    };

    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        rows: {
            style: {
                padding: '8px',
                border: 'none',
                borderBottom: 'none',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            },
        },
    };

    const createNewContact = () => ({
        farmId: selectedFarmId,
        farm: selectedFarm,
        entity: selectedEntity,
        contacts: contactList,
    });

    const handleSaveClick = () => {
        if (validateContacts(contactList)) {
            setDialogOpen(true);
        }
    };

    const handleConfirmSave = () => {
        setDialogOpen(false);
        try {
            handleSave(createNewContact());
            setSnackbarOpen({open: true, severity: 'success', text: t('contacts.saveSuccess')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('contacts.saveError')});
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const handleCancelSave = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ padding: 2, backgroundColor: 'white', margin: 'auto', marginTop: '5%', width: '50%', borderRadius: 1 }}>
                    <Typography variant="h3" align="center" sx={{ marginBottom: 2 }}>
                        {isEditMode ? t('contacts.editContact') : t('contacts.newContact')}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Box sx={{ flex: '1 1 45%', marginRight: '32px' }}>
                            <SelectField
                                name="farm"
                                label={t('contacts.farm')}
                                value={selectedFarm}
                                handleChange={handleFarmChange}
                                items={billingFarms.map(farm => ({
                                    value: farm.name,
                                    text: farm.name,
                                }))}
                                fullWidth
                                disabled={isEditing}
                            />
                        </Box>
                        <Box sx={{ flex: '1 1 45%', marginRight: '32px' }}>
                            <SelectField
                                name="entity"
                                label={t('contacts.entity')}
                                value={selectedEntity}
                                handleChange={handleEntityChange}
                                items={billingFarms.map(farm => ({
                                    value: farm.group,
                                    text: farm.group,
                                }))}
                                fullWidth
                                disabled
                            />
                        </Box>
                    </Box>
                    <DataTableComponent
                        columns={ContactFields(handleEditContact,handleRemoveContact)}
                        data={contactList}
                        pagination={false}
                        customStyles={customStyles}
                        noDataComponent={'contacts.contactList.noItems'}
                    />
                    <Button onClick={handleAddContact} startIcon={<Add />} sx={{ mb: 2 }}>
                        {t('contacts.addContact')}
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveClick}
                            sx={{ m: 1, p: 2, minWidth: 120 }}
                            disabled={isSaveDisabled}
                        >
                            {t('generic.save')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            sx={{ m: 1, p: 2, minWidth: 120, backgroundColor: 'gray' }}
                        >
                            {t('generic.cancel')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <GenericDialog
                open={dialogOpen}
                handleClose={handleCancelSave}
                handleConfirm={handleConfirmSave}
                title={t('contacts.confirmation')}
                text={t('contacts.saveChanges')}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{ width: '100%' }}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ContactsModal;
