import {getColumns} from "../config";
import FilterableTabContainer from "./FilterableTabContainer";
import {useTranslation} from "react-i18next";

const SMSTabContainer = ({data}) => {
    const {t} = useTranslation()
    return (
        <FilterableTabContainer
            columns={getColumns(t)}
            data={data}
            filterFields={['clientName', 'entityName']}
        />
    );
}

export default SMSTabContainer