import axios from "../config/axios";

const BillingService = {

    fetchSMSAudit: async (begin, end) => {
        try {
            const response = await axios.get(`/billing/sms?begin=${begin}&end=${end}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },
    fetchVoiceAudit: async (begin, end) => {
        try {
            const response = await axios.get(`/billing/voice?begin=${begin}&end=${end}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

};

export default BillingService;