import axios from "../config/axios";

const BillingPlanService = {

    fetchAllPlans: async () => {
        try {
            const response = await axios.get(`/billing/plans`);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    updatePlan: async (code, product) => {
        try {
            const response = await axios.put(`/billing/plans/${code}`, product);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    createPlan: async (product) => {
        try {
            const response = await axios.post(`/billing/plans`, product);
            return response.data;
        } catch (error){
            throw error;
        }
    },

    changeStatusPlan: async (code) => {
        try {
            const response = await axios.put(`/billing/plans/status/${code}`);
            return response.data;
        } catch (error){
            throw error;
        }
    },
};

export default BillingPlanService;