import React, {useEffect, useState} from 'react';

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const handleError = (event) => {
            setHasError(true);
            setError(event.error);
            console.error("Uncaught error:", event.error);
        };

        const handleRejection = (event) => {
            setHasError(true);
            setError(event.reason);
            console.error("Uncaught promise rejection:", event.reason);
        };

        window.addEventListener('error', handleError);
        window.addEventListener('unhandledrejection', handleRejection);

        return () => {
            window.removeEventListener('error', handleError);
            window.removeEventListener('unhandledrejection', handleRejection);
        };
    }, []);

    if (hasError) {
        return (
            <div>
                <h1>Something went wrong.</h1>
                {error && <p>{error.message}</p>}
            </div>
        );
    }

    return children;
};

export default ErrorBoundary;