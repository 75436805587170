import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Snackbar} from '@mui/material';
import { Add } from '@mui/icons-material';
import BillingProductService from "../../../services/BillingProductService";
import DataTableComponent from "../../dataTable/DataTableComponent";
import BillingProductModal from "./BillingProductModal";
import {getColumns} from "./config";
import {useTranslation} from "react-i18next";
import SelectField from "../../form/SelectField";
import ExportService from "../../../services/ExportService";
import ExportButton from "../../form/ExportButton";
import DeleteDialog from "../../form/Dialog/DeleteDialog";
import Loading from "../../Loading";

const BillingProductsContainer = () => {
    const {t} = useTranslation();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [codeFilter, setCodeFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [product, setProduct] = useState({ code: '', name: '', status: '' });
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const initSnackbar = {open: false, severity: '', text: ''};
    const [snackbarOpen, setSnackbarOpen] = useState(initSnackbar);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        filterProducts();

        // eslint-disable-next-line
    }, [codeFilter, statusFilter, products]);

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const response = await BillingProductService.fetchAllProducts();
            const sortedProducts = response.sort((a, b) => a.code.localeCompare(b.code));
            setProducts(sortedProducts);
            setFilteredProducts(sortedProducts);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (product) => {
        setProduct(product);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleDeleteClick = (product) => {
        setProduct(product);
        setDeleteDialog(true);
    };

    const deleteProduct = async () => {
        try {
            await BillingProductService.deleteProduct(product?.code);
            setSnackbarOpen({open: true, severity: 'success', text: t('billing.products.delete.success')});
        } catch (error) {
            setSnackbarOpen({open: true, severity: 'error', text: t('billing.products.delete.failure')});
            console.error('Error deleting product :', error);
        }
    };

    const handleDelete = () => {
        deleteProduct()
            .then(async () => {
                setDeleteDialog(false);
                await fetchProducts();
            })
            .catch((error) => {
                console.error('Error in handleChangeStatus:', error)
            });
    }

    const handleAddNewClick = () => {
        setProduct({ code: '', name: '', status: '' });
        setIsEditing(false);
        setModalOpen(true);
    };

    const handleSave = async () => {
        setProduct({ code: '', name: '', status: '' });
        setModalOpen(false);
        if(isEditing){
            try {
                await BillingProductService.updateProduct(product.code, product);
                fetchProducts();
            } catch (error) {
                console.error('Error updating product:', error);
            }
        } else {
            try {
                await BillingProductService.createProduct(product);
                fetchProducts();
            } catch (error) {
                console.error('Error adding new product:', error);
            }
        }

    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(initSnackbar);
    };

    const filterProducts = () => {
        let filtered = products;

        if (codeFilter) {
            filtered = filtered.filter(product => product.code === codeFilter);
        }

        if (statusFilter) {
            filtered = filtered.filter(product => product.status === statusFilter);
        }

        setFilteredProducts(filtered);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProduct({ ...product, [name]: value });
    };

    const handleCodeFilterChange = (event) => {
        setCodeFilter(event.target.value);
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    };

    const handleClearFilters = () => {
        setCodeFilter('');
        setStatusFilter('');
    };

    const handleExport = (format) => {
        const dataToExport = filteredProducts;
        const fileName = 'product_codes';

        if (format === 'xlsx') {
            ExportService.exportToExcel(dataToExport, fileName)
        } else if (format === 'csv') {
            ExportService.exportToCSV(dataToExport, fileName)
        }

    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: 2 }}>
                    <SelectField
                        label={t('billing.products.code')}
                        value={codeFilter}
                        handleChange={handleCodeFilterChange}
                        items={products.map(product => ({
                            value: product.code,
                            text: product.code,
                        }))}
                    />
                    <SelectField
                        label={t('billing.products.status')}
                        value={statusFilter}
                        handleChange={handleStatusFilterChange}
                        items={[
                            { value: 'ACTIVE', text: t('billing.products.active') },
                            { value: 'INACTIVE', text: t('billing.products.inactive') },
                        ]}
                    />
                    <Button variant="contained" color="primary" onClick={handleClearFilters}>
                        {t('generic.clearFilter')}
                    </Button>

                    <ExportButton onExport={handleExport} />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewClick}
                    startIcon={<Add />}
                    sx={{ padding: '10px 20px', fontSize: '16px' }}
                >
                    {t('generic.addNew')}
                </Button>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <DataTableComponent columns={getColumns(t, handleEditClick, handleDeleteClick)} data={filteredProducts} pagination />
            )}
            <BillingProductModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                isEditing={isEditing}
                product={product}
                handleInputChange={handleInputChange}
                handleSave={handleSave}
            />
            <DeleteDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                handleDelete={handleDelete}
                title={t('billing.products.delete.title')}
                text={t('billing.products.delete.text')}
            />
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity={snackbarOpen.severity} sx={{width: '100%'}}>
                    {snackbarOpen.text}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BillingProductsContainer;