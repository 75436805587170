import {useTranslation} from "react-i18next";
import MainLayout from "../components/MainLayout";
import {Typography} from "@mui/material";
import ContactsContainer from "../components/contacts/ContactsContainer"

const ContactsPage = () => {
    const{t} = useTranslation();
    return <MainLayout>
        <Typography
            variant={'h5'}
            mb={2}
            sx={{ marginLeft: '24px'}}
        >
            {t('contacts.title')}</Typography>
        <ContactsContainer />
    </MainLayout>
}

export default ContactsPage;