import React from 'react';
import { TextField, IconButton } from '@mui/material';
import { Remove } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const ContactFields = (handleEditContact, handleRemoveContact) => {
    const { t } = useTranslation();

    const columns = [
        {
            name: t('contacts.contactList.name'),
            selector: row => row.name,
            cell: (row, index) => (
                <TextField
                    value={row.name}
                    onChange={(e) => handleEditContact(index, 'name', e.target.value)}
                    fullWidth
                />
            ),
            sortable: true,
            width: '30%',
        },
        {
            name: t('contacts.contactList.email'),
            selector: row => row.email,
            cell: (row, index) => (
                <TextField
                    value={row.email}
                    onChange={(e) => handleEditContact(index, 'email', e.target.value)}
                    fullWidth
                />
            ),
            sortable: true,
            width: '30%',
        },
        {
            name: t('contacts.contactList.phone'),
            selector: row => row.phone,
            cell: (row, index) => (
                <TextField
                    value={row.phone}
                    onChange={(e) => handleEditContact(index, 'phone', e.target.value)}
                    fullWidth
                />
            ),
            sortable: true,
            width: '30%',
        },
        {
            name: t('generic.delete'),
            cell: (_, index) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <IconButton
                        onClick={() => handleRemoveContact(index)}
                        color="secondary"
                    >
                        <Remove />
                    </IconButton>
                </div>
            ),
            ignoreRowClick: true,
            width: '10%',
        },
    ];

    return columns;
};

export default ContactFields;
