import React from 'react';
import {Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography,  Card, CardContent} from '@mui/material';
import { useTranslation } from "react-i18next";
import {formatCurrency} from "../../../utils/CurrencyUtil";

const ExpandableSaasBillingInfo = ({ data }) => {
    const { t } = useTranslation();

    const { valueMonthly, valueYearly, obs } = data;

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell><Typography variant="h6">{t('billing.saas.valueMonthly')}</Typography></TableCell>
                                <TableCell align="right">{formatCurrency(valueMonthly)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Typography variant="h6">{t('billing.saas.valueYearly')}</Typography></TableCell>
                                <TableCell align="right">{formatCurrency(valueYearly)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {obs && obs !== '' && (
                    <Card sx={{ marginTop: 2 }}>
                        <CardContent>
                            <Typography variant="h6">{t('billing.saas.obs')}</Typography>
                            <Typography variant="body1">{obs}</Typography>
                        </CardContent>
                    </Card>
                )}

            </Box>
        </Box>
    );
};

export default ExpandableSaasBillingInfo;