import React, { useState, useEffect } from 'react';
import {Box, FormControl, InputLabel, MenuItem, Typography, Select} from "@mui/material";
import DataTableComponent from "../../../dataTable/DataTableComponent";
import {useTranslation} from "react-i18next";
import {formatCurrency} from "../../../../utils/CurrencyUtil";

const FilterableTabContainer = ({ columns, data, filterFields, showTotalCost }) => {
    const {t} = useTranslation();
    const [filters, setFilters] = useState(
        filterFields.reduce((acc, field) => ({ ...acc, [field]: '' }), {})
    );
    const [filteredData, setFilteredData] = useState(data);
    const [totals, setTotals] = useState({
        cost: 0,
        sms: 0,
        voice: 0
    });

    const handleFilterChange = (field) => (event) => {
        const newFilters = {
            ...filters,
            [field]: event.target.value,
        };
        setFilters(newFilters);
    };

    const filterData = (data, filters) => {
        const newFilteredData = data.filter(item => {
            return filterFields.every(field => {
                const filterValue = filters[field];
                const itemValue = item[field];
                return filterValue === '' || itemValue === filterValue;
            });
        });
        setFilteredData(newFilteredData);
        if (showTotalCost) {
            updateTotalCost(newFilteredData);
        }
    };

    const updateTotalCost = (data) => {
        const total = data.reduce((sum, item) => {
            return sum + item.rawValue;
        }, 0);

        const smsTotal = data.filter(item => item.type === "SMS").reduce((sum, item) => {
            return sum + item.counter
        }, 0);

        const voiceTotal = data.filter(item => item.type === "VOICE").reduce((sum, item) => {
            return sum + item.counter
        }, 0);


        setTotals({
            cost: formatCurrency(total),
            sms: smsTotal,
            voice: voiceTotal
        });
    };

    useEffect(() => {
        filterData(data, filters);
        // eslint-disable-next-line
    }, [filters, data]);

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                {filterFields.map(field => (
                    <FormControl key={field} variant="outlined" sx={{ minWidth: 200 }}>
                        <InputLabel>{t('generic.filters.' + field)}</InputLabel>
                        <Select
                            value={filters[field]}
                            onChange={handleFilterChange(field)}
                            label={t('generic.filters.' + field)}
                        >
                            <MenuItem value="">
                                <em>{t('generic.all')}</em>
                            </MenuItem>
                            {Array.from(new Set(data.map(item => item[field]))).map(value => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ))}
            </Box>
            <DataTableComponent columns={columns} data={filteredData} pagination />
            {showTotalCost && (
                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end', gap: 4, flexDirection: 'row', alignItems: 'flex-end' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {t('billing.notifications.tabs.voice')}: {totals.voice}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {t('billing.notifications.tabs.sms')}: {totals.sms}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {t('generic.total')}: {totals.cost}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default FilterableTabContainer;