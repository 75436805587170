import {TextField, Box, Button, Modal, Typography} from "@mui/material";
import SelectField from "../../form/SelectField";
import {useTranslation} from "react-i18next";
import {useState, useEffect} from "react";

const HwInventoryModal = (
    {
        open,
        handleClose,
        isEditing,
        hwInventory,
        farms,
        components,
        handleInputChange,
        handleDateInputChange,
        handleSave
    }) => {

    const {t} = useTranslation()
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid = hwInventory.installationDate && hwInventory.hardware &&
            hwInventory.farmId && hwInventory.quantity && hwInventory.quantity !== "0";

        setIsSaveDisabled(!isFormValid);
    }, [hwInventory]);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%', borderRadius: 2, boxShadow: 24 }}>
                <Typography variant={"h3"} sx={{ marginBottom: 2, textAlign: 'center' }}>
                    {isEditing ? t('billing.hw.editHwInventory') : t('billing.hw.newHwInventory')}
                </Typography>
                <TextField
                    label={t('billing.hw.installationDate')}
                    name="installationDate"
                    type="date"
                    value={hwInventory.installationDate.split('T')[0]}
                    onChange={handleDateInputChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ m: 1 }}
                />
                <SelectField
                    name="hardware"
                    label={t('billing.hw.hardware')}
                    value={hwInventory.hardware}
                    handleChange={handleInputChange}
                    items={components.map(hwComponent => ({
                        value: hwComponent.hardware,
                        text: hwComponent.hardware,
                    }))}
                    fullWidth
                    sx={{m: 1}}
                />
                <SelectField
                    name="farmId"
                    label={t('billing.hw.farm')}
                    value={hwInventory.farmId}
                    handleChange={handleInputChange}
                    items={farms.map(farm => ({
                        value: farm.id,
                        text: farm.name,
                    }))}
                    fullWidth
                    sx={{m: 1}}
                />
                <TextField
                    name="quantity"
                    label={t('billing.hw.quantity')}
                    value={hwInventory.quantity}
                    type={"number"}
                    inputProps={{step:1}}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ m: 1 }}
                />
                <TextField
                    name="observations"
                    label={t('billing.hw.components.observations')}
                    value={hwInventory.observations}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{m: 1}}
                />
                <Box sx={{ gap: 4}}>
                    <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120}} disabled={isSaveDisabled}>
                        {t('generic.save')}
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120, backgroundColor: 'gray',
                        color: 'white'}}>
                        {t('generic.cancel')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default HwInventoryModal;