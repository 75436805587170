import axios from "axios";
import AuthService from "../services/AuthService";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        const currentTime = new Date().getTime();

        if (token && tokenExpiration && currentTime < tokenExpiration) {
            config.headers.Authorization = `Bearer ${token}`;
        } else if (token) {
            AuthService.logout();
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            const token = localStorage.getItem('token');
            if (token) {
                AuthService.logout();
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;