import {useTranslation} from "react-i18next";
import MainLayout from "../../components/MainLayout";
import {Typography} from "@mui/material";
import BillingPlansContainer from "../../components/billing/plans/BillingPlansContainer";
import withRole from "../../hoc/withRole";
import {UserRoles} from "../../enums/UserRoles";

const BillingPlansPageContainer = () => {
    const {t} = useTranslation();
    return <MainLayout>
        <Typography variant={'h5'} mb={2} sx={{ marginLeft: '24px'}}>{t('billing.plans.title')}</Typography>
        <BillingPlansContainer />
    </MainLayout>
}

const BillingPlansPage = withRole(BillingPlansPageContainer, [UserRoles.ROLE_ACCOUNTANT,  UserRoles.ROLE_ADMIN])

export default BillingPlansPage;