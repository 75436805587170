import React, { useEffect, useState, useCallback } from "react";
import ContactsService from "../../services/ContactsService";
import BillingFarmService from "../../services/BillingFarmService";
import { Box, Button, TextField} from "@mui/material";
import { useTranslation } from "react-i18next";
import DataTableComponent from "../dataTable/DataTableComponent";
import { getColumns } from "./config";
import ExpandableContacts from "./ExpandableContacts";
import { Add} from "@mui/icons-material";
import ContactsModal from "./ContactsModal";
import SelectField from "../form/SelectField";
import Loading from "../Loading";

const ContactsContainer = () => {
    const { t } = useTranslation();
    const [contacts, setContacts] = useState([]);
    const [billingFarms, setBillingFarms] = useState([]);
    const [farmFilter, setFarmFilter] = useState('');
    const [entityFilter, setEntityFilter] = useState('');
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [farmIndex, setFarmIndex] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchAllContacts = useCallback(async () => {
        try {
            const response = await ContactsService.fetchAllContacts();
            setContacts(response.sort((a, b) => a.farm.localeCompare(b.farm)));
        } catch (error) {
            console.error('Error fetching contact list:', error);
        }
    }, []);

    const fetchAllBillingFarms = useCallback(async () => {
        try {
            setLoading(true);
            const response = await BillingFarmService.fetchAllFarms();
            setBillingFarms(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching farm list:', error);
        }finally{
            setLoading(false);
        }
    }, []);

    const handleEditClick = useCallback((contact) => {
        setIsEditing(true);
        setModalOpen(true);
        setFarmIndex(contact.farmId);
    }, []);

    const handleAddNewClick = useCallback(() => {
        setIsEditing(false);
        setModalOpen(true);
        setFarmIndex('');
    }, []);

    const handleSave = async (newFarmContactList) => {
        try {
            handleClearFilters();

            if (newFarmContactList) {
                if (isEditing) {
                    await ContactsService.updateContactByFarmId(newFarmContactList.farmId, newFarmContactList);
                } else {
                    await ContactsService.createContact(newFarmContactList);
                }

                await fetchAllContacts();
                setModalOpen(false);
            } else {
                console.error('No contact data provided');
            }
        } catch (error) {
            console.error('Error saving contact:', error);
        }
    };

    const filterContacts = useCallback(() => {
        let filtered = contacts;
        if (farmFilter) {
            filtered = filtered.filter(contact => contact.farm.toLowerCase().includes(farmFilter.toLowerCase()));
        }

        if (entityFilter) {
            filtered = filtered.filter(contact => contact.entity === entityFilter);
        }

        setFilteredContacts(filtered);
    }, [contacts, farmFilter, entityFilter]);

    const uniqueEntities = [...new Set(contacts.map(contact => contact.entity))].sort((a, b) => a.localeCompare(b));

    const handleClearFilters = () => {
        setFarmFilter('');
        setEntityFilter('');
    };

    useEffect(() => {
        fetchAllBillingFarms();
        fetchAllContacts();
    }, [fetchAllBillingFarms, fetchAllContacts]);

    useEffect(() => {
        filterContacts();
    }, [filterContacts]);

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <TextField
                            label={t('contacts.farm')}
                            value={farmFilter}
                            onChange={(e) => setFarmFilter(e.target.value)}
                            fullWidth
                            sx={{m:1}}
                        />
                        <SelectField
                            name="entity"
                            label={t('contacts.entity')}
                            value={entityFilter}
                            handleChange={(e) => setEntityFilter(e.target.value)}
                            items={uniqueEntities.map(entity => ({
                                value: entity,
                                text: entity,
                            }))}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleClearFilters}>
                            {t('generic.clearFilter')}
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddNewClick}
                        startIcon={<Add />}
                        sx={{ padding: '10px', fontSize: '16px' }}
                    >
                        {t('generic.addNew')}
                    </Button>
                </Box>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <DataTableComponent
                    columns={getColumns(t, handleEditClick)}
                    data={filteredContacts}
                    pagination
                    expandableRows
                    expandableRowsComponent={({ data }) => <ExpandableContacts data={data} />}
                />
                )
            }

            <ContactsModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                isEditing={isEditing}
                farmId={farmIndex}
                billingFarms={billingFarms}
                contacts={contacts}
                handleSave={handleSave}
            />
        </Box>
    );
};

export default ContactsContainer;
