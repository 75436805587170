import axios from "../config/axios";
import {CacheKeys} from "../enums/CacheKeys";
import LocalStorageService from "./LocalStorageService";

const AuxTableService = (() => {
    const cacheKey = CacheKeys.AUX_TABLES;
    let cache;
    try {
        cache = JSON.parse(LocalStorageService.getItem(cacheKey)) || {};
        if (typeof cache !== 'object' || cache === null) {
            cache = {};
        }
    } catch (error) {
        cache = {};
    }

    const clearAuxTableCache = () => {
        cache = {};
        LocalStorageService.removeItem(cacheKey);
    };

    const saveAuxTableCache = () => {
        LocalStorageService.saveItem(cacheKey, JSON.stringify(cache));
    };

    return {
        clearAuxTableCache,
        fetchAllCountries: async () => {
            if (!cache.countries) {
                try {
                    const response = await axios.get(`/billing/aux-tables/countries`);
                    cache.countries = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }
            return cache.countries;
        },
        fetchAllBillingTypes: async () => {
            if (!cache.billingTypes) {
                try {
                    const response = await axios.get(`/billing/aux-tables/types`);
                    cache.billingTypes = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }
            return cache.billingTypes;
        },

        fetchAllBillingGroups: async () => {
            if (!cache.billingGroups){
                try {
                    const response = await axios.get(`/billing/aux-tables/groups`);
                    cache.billingGroups = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }
            return cache.billingGroups;
        },
        createBillingGroup: async(groupName) => {
            try{
                const response = await axios.post('/billing/aux-tables/groups', groupName);
                clearAuxTableCache();
                return response.data;
            } catch (error){
                throw error;
            }
        },
        updateBillingGroup: async(oldName,newName) => {
            try{
                const requestBody = {
                    oldView: oldName,
                    newView: newName
                };
                if(oldName.name !== newName.name) {
                    const response = await axios.put('/billing/aux-tables/groups', requestBody );
                    clearAuxTableCache();
                    return response.data;
                }
            }catch (error){
                throw error;
            }
        },
        deleteBillingGroup: async(requestBody) => {
            try {
                const response = await axios.delete('/billing/aux-tables/groups', {
                    data: requestBody
                });
                clearAuxTableCache();
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        fetchAllBillingClasses: async () => {
            if (!cache.billingClasses) {
                try {
                    const response = await axios.get(`/billing/aux-tables/classes`);
                    cache.billingClasses = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }

            return cache.billingClasses
        },
        fetchAllSolutions: async () => {
            if (!cache.solutions) {
                try {
                    const response = await axios.get(`/billing/aux-tables/solutions`);
                    cache.solutions = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }

            return cache.solutions
        },
        fetchAllSpecies: async () => {
            if (!cache.species) {
                try {
                    const response = await axios.get(`/billing/aux-tables/species`);
                    cache.species = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }

            return cache.species
        },
        fetchAllPlatforms: async () => {
            if (!cache.platforms) {
                try {
                    const response = await axios.get(`/billing/aux-tables/platforms`);
                    cache.platforms = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }

            return cache.platforms
        },
        fetchAllHwSuppliers: async () => {
            if(!cache.hwSuppliers){
                try {
                    const response = await axios.get(`/billing/aux-tables/hw-suppliers`);
                    cache.hwSuppliers = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }
            return cache.hwSuppliers;
        },
        createHwSupplier: async(hwSupplierName) => {
            try{
                const response = await axios.post('/billing/aux-tables/hw-suppliers', hwSupplierName);
                clearAuxTableCache();
                return response.data;
            } catch (error){
                throw error;
            }
        },
        updateHwSupplier: async(oldName,newName) => {
            try{
                const requestBody = {
                    oldView: oldName,
                    newView: newName
                };
                if(oldName.name !== newName.name){
                    const response = await axios.put(`/billing/aux-tables/hw-suppliers`, requestBody );
                    clearAuxTableCache();
                    return response.data;
                }
            }catch (error){
                throw error;
            }
        },
        deleteHwSupplier: async(requestBody) => {
            try {
                const response = await axios.delete('/billing/aux-tables/hw-suppliers', {
                    data: requestBody
                });
                clearAuxTableCache();
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        fetchAllSaasSalesTypes: async () => {
            if(!cache.saasSalesTypes){
                try {
                    const response = await axios.get(`/billing/aux-tables/saas-sale-types`);
                    cache.saasSalesTypes = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }
            return cache.saasSalesTypes;
        },
        createSaasSaleType: async(saasSaleTypeName) => {
            try{
                const response = await axios.post('/billing/aux-tables/saas-sale-types', saasSaleTypeName);
                clearAuxTableCache();
                return response.data;
            } catch (error){
                throw error;
            }
        },
        updateSaasSaleType: async(oldName,newName) => {
            try{
                const requestBody = {
                    oldView: oldName,
                    newView: newName
                };
                if(oldName.name !== newName.name) {
                    const response = await axios.put(`/billing/aux-tables/saas-sale-types`, requestBody );
                    clearAuxTableCache();
                    return response.data;
                }
            }catch (error){
                throw error;
            }
        },
        deleteSaasSaleType: async(requestBody) => {
            try {
                const response = await axios.delete('/billing/aux-tables/saas-sale-types', {
                    data: requestBody
                });
                clearAuxTableCache();
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        fetchPeriods: async () => {
            if (!cache.periods) {
                try {
                    const response = await axios.get(`/billing/aux-tables/periods`);
                    cache.periods = response.data;
                    saveAuxTableCache();
                } catch (error) {
                    throw error;
                }
            }
            return cache.periods
        }
    }
})();

export default AuxTableService;