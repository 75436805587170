const LocalStorageService = {

    getItem: (key) => {
        return localStorage.getItem(key);
    },
    saveItem: (key, data) => {
        localStorage.setItem(key, JSON.stringify(data));
    },
    removeItem: (key) => {
        localStorage.removeItem(key);
    }
}

export default LocalStorageService;