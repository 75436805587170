import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import React from "react";
import LatestCriticalEventsList from "./LatestCriticalEventsList";

const DisconnectedTerminalsContainer = () => {
    const {t} = useTranslation()
    return (
        <>
            <Typography variant={'h5'} mb={2}>{t('dashboard.criticalEvents.title')}</Typography>
            <LatestCriticalEventsList />
        </>
    )
}

export default DisconnectedTerminalsContainer