import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    Typography
} from "@mui/material";

const AuxTablesModal = ({ t, open, onClose, onSubmit, isEditMode, tabValue, entryName, oldName }) => {
    const [currentName, setCurrentName] = useState(entryName);
    const [tabType, setTabType] = useState(tabValue);

    useEffect(() => {
        setCurrentName(entryName);
        setTabType(tabValue);
    }, [isEditMode, entryName, tabValue]);

    const handleSubmit = () => {
        if (currentName.trim() && tabType !== null) {
            onSubmit(oldName, currentName, tabType);
            onClose();
        }
    };

    const modalTitleMap = {
        0: isEditMode ? t('billing.auxTables.editGroup') : t('billing.auxTables.createGroup'),
        1: isEditMode ? t('billing.auxTables.editHwSupplier') : t('billing.auxTables.createHwSupplier'),
        2: isEditMode ? t('billing.auxTables.editSaasSaleType') : t('billing.auxTables.createSaasSaleType'),
    };
    const modalTabEntryNameMap = {
        0: t('billing.auxTables.group'),
        1: t('billing.auxTables.hwSupplier'),
        2: t('billing.auxTables.saasSaleType'),
    };

    const isSubmitDisabled = currentName.trim() === '' || currentName === oldName;

    return (
        <>
            <Modal open={open}
                   onClose={onClose}
                   sx={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}
            >
                <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%', borderRadius: 2, boxShadow: 24 }}>
                    <Typography variant="h3" align="center" sx={{m: 2}}>
                        {modalTitleMap[tabType]}
                    </Typography>

                    <TextField
                        label={modalTabEntryNameMap[tabType]}
                        value={currentName}
                        onChange={(e) => setCurrentName(e.target.value)}
                        fullWidth
                        sx={{ m: 1}}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained"
                                onClick={handleSubmit}
                                sx={{ marginTop: 2, m: 1, p:2, minWidth: 120}}
                                disabled={isSubmitDisabled}
                        >
                            {t('generic.save')}
                        </Button>
                        <Button variant="outlined"
                                onClick={onClose}
                                sx={{
                                    marginTop: 2,
                                    m: 1,
                                    p:2,
                                    minWidth: 120,
                                    backgroundColor: 'gray',
                                    color: 'white'}}
                        >
                            {t('generic.cancel')}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default AuxTablesModal;
