import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Modal, TextField, Typography} from "@mui/material";
import SelectField from "../../form/SelectField";
import {NumericFormat} from "react-number-format";
import {useEffect, useState} from "react";

const BillingPlanModal = ({ open, handleClose, isEditing, plan, products, periods, saasSaleTypes, handleSave }) => {
    const {t} = useTranslation()
    const [currentPlan, setCurrentPlan] = useState(plan);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    useEffect(() => {
        setCurrentPlan(plan);
    }, [plan]);

    useEffect(() => {
        validateForm();
        // eslint-disable-next-line
    }, [currentPlan]);

    const validateForm = () => {
        const requiredFields = ['code', 'description', 'product', 'saasSaleType', 'period'];
        const isFormValid = requiredFields.every(field => currentPlan[field] && currentPlan[field].trim() !== '');
        setIsSaveEnabled(isFormValid);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedPlan = { ...currentPlan, [name]: value };

        if (name === 'price' || name === 'discountPercentage' || name === 'period') {
            updatedPlan.valueMonthly = calculateMonthlyValue(updatedPlan);
            updatedPlan.valueYearly = calculateYearlyValue(updatedPlan);
        }

        setCurrentPlan(updatedPlan);
    };

    const handleCurrencyChange = (name, value) => {
        const updatedPlan = { ...currentPlan, [name]: parseFloat(value) };
        updatedPlan.valueMonthly = calculateMonthlyValue(updatedPlan);
        updatedPlan.valueYearly = calculateYearlyValue(updatedPlan);
        setCurrentPlan(updatedPlan);
    };

    const calculateMonthlyValue = (plan) => {
        const price = parseFloat(plan.price) || 0;
        const discount = parseFloat(plan.discountPercentage) || 0;
        const discountedPrice = price * (1 - discount / 100);

        const monthlyValue = plan.period === 'Annual' ? discountedPrice / 12 : discountedPrice;
        return parseFloat(monthlyValue.toFixed(2));
    };

    const calculateYearlyValue = (plan) => {
        const price = parseFloat(plan.price) || 0;
        const discount = parseFloat(plan.discountPercentage) || 0;
        const discountedPrice = price * (1 - discount / 100);

        const yearlyValue = plan.period === 'Monthly' ? discountedPrice * 12 : discountedPrice;
        return parseFloat(yearlyValue.toFixed(2));
    };

    const handleSaveClick = () => {
        const planToStore = { ...currentPlan };
        if (planToStore.discountPercentage !== null && planToStore.discountPercentage !== undefined) {
            planToStore.discountPercentage = planToStore.discountPercentage / 100;
        }
        handleSave(currentPlan);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: '50%', borderRadius: 2, boxShadow: 24 }}>
                <Typography variant={"h3"} sx={{ marginBottom: 2, textAlign: 'center' }}>
                    {isEditing ? t('billing.plans.editPlan') : t('billing.plans.newPlan')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="code"
                            label={t('billing.plans.code')}
                            value={currentPlan.code}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={isEditing}
                            sx={{m: 1}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="description"
                            label={t('billing.plans.description')}
                            value={currentPlan.description}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{m: 1}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="product"
                            label={t('billing.plans.product')}
                            value={currentPlan.product}
                            handleChange={handleInputChange}
                            items={products.map(product => ({
                                value: product.code,
                                text: product.name,
                            }))}
                            fullWidth
                            sx={{m: 1}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="saasSaleType"
                            label={t('billing.plans.saasSaleType')}
                            value={currentPlan.saasSaleType}
                            handleChange={handleInputChange}
                            items={saasSaleTypes.map(saasSaleType => ({
                                value: saasSaleType.name,
                                text: saasSaleType.name,
                            }))}
                            fullWidth
                            sx={{m: 1}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField
                            name="period"
                            label={t('billing.plans.period')}
                            value={currentPlan.period}
                            handleChange={handleInputChange}
                            items={periods.map(period => ({
                                value: period.name,
                                text: period.name,
                            }))}
                            fullWidth
                            sx={{m: 1}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumericFormat
                            customInput={TextField}
                            name="price"
                            label={t('billing.plans.price')}
                            value={currentPlan.price}
                            onValueChange={(values) => handleCurrencyChange('price', values.value)}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="€ "
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumericFormat
                            customInput={TextField}
                            name="discountPercentage"
                            label={t('billing.plans.discountPercentage')}
                            value={currentPlan.discountPercentage}
                            onValueChange={(values) => handleCurrencyChange('discountPercentage', values.value)}
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" %"
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumericFormat
                            disabled={true}
                            customInput={TextField}
                            name="valueMonthly"
                            label={t('billing.plans.valueMonthly')}
                            value={currentPlan.valueMonthly}
                            onValueChange={(values) => handleCurrencyChange('valueMonthly', values.value)}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="€ "
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumericFormat
                            disabled={true}
                            customInput={TextField}
                            name="valueYearly"
                            label={t('billing.plans.valueYearly')}
                            value={currentPlan.valueYearly}
                            onValueChange={(values) => handleCurrencyChange('valueYearly', values.value)}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="€ "
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="observations"
                            label={t('billing.plans.observations')}
                            value={currentPlan.observations}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{m: 1}}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ gap: 4}}>
                    <Button variant="contained" color="primary" onClick={handleSaveClick} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120}} disabled={!isSaveEnabled}>
                        {t('generic.save')}
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ marginTop: 2, m: 1, p:2, minWidth: 120, backgroundColor: 'gray',
                        color: 'white'}}>
                        {t('generic.cancel')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default BillingPlanModal;