import React from "react";
import { formatCurrency } from "../../../utils/CurrencyUtil";

export function getColumns(t) {
    const isTotalsRow = (row) => row.id === 'totals';

    const baseColumns = [
        {
            name: t('billing.saas.farm'),
            selector: row => row.farmName,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.farmName}</div>,
            grow: 2,
        },
        {
            name: t('billing.saas.group'),
            selector: row => row.group,
            sortable: true,
            grow: 2,
        },
        {
            name: t('billing.saas.plan'),
            cell: row => (<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.planName}</div>
                <div style={{ fontWeight: 'bold', fontSize: '14px'}}>{row.period}</div>
            </div>),
            selector: row => row.planName,
            sortable: true,
            grow: 2,
        },
        {
            name: t('billing.saas.beginDate'),
            selector: row => row.beginDate ? new Date(row.beginDate).toLocaleDateString() : '-',
            sortFunction: (a, b) => new Date(a.beginDate) - new Date(b.beginDate),
            sortable: true,
            grow: 2,
        },
        {
            name: t('billing.saas.endDate'),
            selector: row => row.endDate ? new Date(row.endDate).toLocaleDateString() : '-',
            sortFunction: (a, b) => new Date(a.endDate) - new Date(b.endDate),
            sortable: true,
            grow: 2,
        },
        {
            name: t('billing.saas.netValue'),
            selector: row => row.netValue ? row.netValue : '-',
            sortable: true,
            cell: row => row.netValue != null ? formatCurrency(row.netValue) : '-',
            grow: 2,
        }
    ];

    const monthlyColumns = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ].map(month => ({
        name: month,
        selector: row => row.monthlyValues[month.toLowerCase()],
        sortable: false,
        cell: row =>  <div style={{ fontWeight: isTotalsRow(row) ? 'bold' : 'normal' }}>
            {formatCurrency(row.monthlyValues[month.toLowerCase()] || 0)}
        </div>,
        grow: 1,
    }));

    const totalColumn = {
        name: t('generic.total'),
        selector: row => row.total,
        sortable: true,
        cell: row => <div style={{ fontWeight: isTotalsRow(row) ? 'bold' : 'normal' }}>
            {formatCurrency(row.total)}
        </div>,
        grow: 2,
    };

    return [...baseColumns, ...monthlyColumns, totalColumn];
}