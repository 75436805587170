import React, {createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from "../services/AuthService";
import LanguageService from "../services/LanguageService";
import AuxTableService from "../services/AuxTableService";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const scheduleTokenRefresh = useCallback((token, refreshToken) => {
        const jwtExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000;
        const timeout = jwtExpiration - Date.now() - 60000; // Refresh 1 minute before expiry

        setTimeout(async () => {
            try {
                const newToken = await AuthService.refreshAccessToken();
                scheduleTokenRefresh(newToken, refreshToken);
            } catch (error) {
                logout();
            }
        }, timeout);
    }, []);

    useEffect(() => {
        const storedUser = localStorage.getItem('loggedUser');
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');

        const storedLanguage = localStorage.getItem('userLanguage');
        if (storedLanguage) {
            LanguageService.setUserLanguage(storedLanguage);
        } else {
            LanguageService.setDefaultLanguage();
        }

        if (storedUser && token && refreshToken) {
            setUser(JSON.parse(storedUser));
            setIsAuthenticated(true);
            scheduleTokenRefresh(token, refreshToken);
        }
        setLoading(false)
    }, [scheduleTokenRefresh]);

    const login = async (username, password) => {
        try {
            const data = await AuthService.login(username, password);
            setUser(data);
            setIsAuthenticated(true);
            scheduleTokenRefresh(data.token, data.refreshToken);
            navigate('/');
        } catch (error) {
            setIsAuthenticated(false);
            if (error.response) {
                throw new Error(`${error.response.data}`);
            } else {
                throw new Error('Network error or server is not responding');
            }
        }
    };

    const logout = () => {
        AuthService.logout();
        AuxTableService.clearAuxTableCache()
        setIsAuthenticated(false);
        setUser(null);
    };

    const hasRole = (role) => user?.roles?.includes(role);

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, hasRole, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};