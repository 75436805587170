import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, TextField, Tabs, Tab, Snackbar, Alert } from "@mui/material";
import DataTableComponent from "../../dataTable/DataTableComponent";
import { getColumns } from "./config";
import AuxTableService from "../../../services/AuxTableService";
import { Add } from "@mui/icons-material";
import AuxTablesModal from "./AuxTablesModal";
import DeleteDialog from "../../form/Dialog/DeleteDialog";
import Loading from "../../Loading";

const BillingAuxTablesContainer = () => {
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [entryName, setEntryName] = useState('');
    const [oldName, setOldName] = useState('');
    const [groups, setGroups] = useState([]);
    const [hwSuppliers, setHwSuppliers] = useState([]);
    const [saasSaleTypes, setSaasSaleTypes] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [genericFilter, setGenericFilter] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        fetchGroups();
        fetchHwSuppliers();
        fetchSaasSaleTypes();
    }, []);

    const fetchGroups = async () => {
        try {
            setLoading(true);
            const response = await AuxTableService.fetchAllBillingGroups();
            setGroups(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching groups:', error);
        }finally {
            setLoading(false);
        }
    };

    const fetchHwSuppliers = async () => {
        try {
            const response = await AuxTableService.fetchAllHwSuppliers();
            setHwSuppliers(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching hardware suppliers:', error);
        }
    };

    const fetchSaasSaleTypes = async () => {
        try {
            const response = await AuxTableService.fetchAllSaasSalesTypes();
            setSaasSaleTypes(response.sort((a, b) => a.name.localeCompare(b.name)));
        } catch (error) {
            console.error('Error fetching SaaS sale types:', error);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleEditClick = (item) => {
        setOldName(item.name);
        setEntryName(item.name);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleAddNewClick = useCallback(() => {
        setIsEditing(false);
        setEntryName('');
        setOldName('');
        setModalOpen(true);
    }, []);

    const handleDeleteClick = (item) => {
        setSelectedEntry(item);
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (!selectedEntry) return;

        try {
            switch (tabValue) {
                case 0:
                    await AuxTableService.deleteBillingGroup(selectedEntry);
                    const updatedGroups = await AuxTableService.fetchAllBillingGroups();
                    setGroups(updatedGroups.sort((a, b) => a.name.localeCompare(b.name)));
                    break;
                case 1:
                    await AuxTableService.deleteHwSupplier(selectedEntry);
                    const updatedSuppliers = await AuxTableService.fetchAllHwSuppliers();
                    setHwSuppliers(updatedSuppliers.sort((a, b) => a.name.localeCompare(b.name)));
                    break;
                case 2:
                    await AuxTableService.deleteSaasSaleType(selectedEntry);
                    const updatedSaleTypes = await AuxTableService.fetchAllSaasSalesTypes();
                    setSaasSaleTypes(updatedSaleTypes.sort((a, b) => a.name.localeCompare(b.name)));
                    break;
                default:
                    break;
            }
            setSnackbarMessage(`${selectedEntry.name}` + t('billing.auxTables.deleteSuccess'));
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error deleting entry:', error);
            setSnackbarMessage(t('billing.auxTables.errorMessage'));
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setDeleteDialogOpen(false);
        }
    };

    const handleClearFilters = () => {
        setGenericFilter('');
    };

    const filterData = useCallback(() => {
        let filtered = [];

        switch (tabValue) {
            case 0:
                filtered = groups.filter(item =>
                    item.name.toLowerCase().includes(genericFilter.toLowerCase())
                );
                break;
            case 1:
                filtered = hwSuppliers.filter(item =>
                    item.name.toLowerCase().includes(genericFilter.toLowerCase())
                );
                break;
            case 2:
                filtered = saasSaleTypes.filter(item =>
                    item.name.toLowerCase().includes(genericFilter.toLowerCase())
                );
                break;
            default:
                break;
        }

        setFilteredData(filtered);
    }, [genericFilter, tabValue, groups, hwSuppliers, saasSaleTypes]);

    useEffect(() => {
        filterData();
    }, [filterData]);

    const createNewObject = (name) => ({
        name: name
    });

    const handleModalSubmit = async (oldName, newName, tabType) => {
        try {
            const operationsMap = {
                0: {
                    create: AuxTableService.createBillingGroup,
                    update: AuxTableService.updateBillingGroup,
                    fetch: AuxTableService.fetchAllBillingGroups,
                    set: setGroups,
                },
                1: {
                    create: AuxTableService.createHwSupplier,
                    update: AuxTableService.updateHwSupplier,
                    fetch: AuxTableService.fetchAllHwSuppliers,
                    set: setHwSuppliers,
                },
                2: {
                    create: AuxTableService.createSaasSaleType,
                    update: AuxTableService.updateSaasSaleType,
                    fetch: AuxTableService.fetchAllSaasSalesTypes,
                    set: setSaasSaleTypes,
                },
            };

            const { create, update, fetch, set } = operationsMap[tabType];

            if (!isEditing) {
                await create(createNewObject(newName));
                setSnackbarMessage(`${newName} ${t('billing.auxTables.creationSuccess')}`);
            } else {
                await update(createNewObject(oldName), createNewObject(newName));
                setSnackbarMessage(`${oldName} ${t('billing.auxTables.updateSuccess')} ${newName}`);
            }

            const updatedData = await fetch();
            set(updatedData.sort((a, b) => a.name.localeCompare(b.name)));

        } catch (error) {
            console.error('Error adding new entry:', error);
            setSnackbarMessage(t('billing.auxTables.errorMessage'));
            setSnackbarSeverity('error');
        }

        setSnackbarOpen(true);
        setModalOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const renderTabContent = (tabValue) => {
        const tableConfig = [
            {
                key: 0,
                label: 'billing.auxTables.tabGroups',
            },
            {
                key: 1,
                label: 'billing.auxTables.tabHwSuppliers',
            },
            {
                key: 2,
                label: 'billing.auxTables.tabSaasSaleTypes',
            },
        ];

        const selectedConfig = tableConfig.find(config => config.key === tabValue);
        return (
            <DataTableComponent
                columns={getColumns(
                    t,
                    selectedConfig.label,
                    handleEditClick,
                    handleDeleteClick,
                )}
                data={filteredData}
            />
        );
    };

    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, flexWrap: 'wrap', flexDirection: 'column' }}>
                    <TextField
                        label={t('generic.search')}
                        value={genericFilter}
                        onChange={(e) => setGenericFilter(e.target.value)}
                        fullWidth
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleClearFilters}>
                            {t('generic.clearFilter')}
                        </Button>
                    </Box>
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewClick}
                    startIcon={<Add />}
                    sx={{ padding: '10px', fontSize: '16px' }}
                >
                    {t('generic.addNew')}
                </Button>
            </Box>

            {loading ? (
                <Loading />
            ) : (
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="SaaS Billing Tabs">
                        <Tab label={t('billing.auxTables.tabGroups')} />
                        <Tab label={t('billing.auxTables.tabHwSuppliers')} />
                        <Tab label={t('billing.auxTables.tabSaasSaleTypes')} />
                    </Tabs>
                    <Box>
                        {renderTabContent(tabValue)}
                    </Box>
                </Box>
                )
            }

            <AuxTablesModal
                t={t}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleModalSubmit}
                isEditMode={isEditing}
                tabValue={tabValue}
                entryName={entryName}
                oldName={oldName}
            />

            <DeleteDialog
                open={deleteDialogOpen}
                handleClose={() => setDeleteDialogOpen(false)}
                handleDelete={handleConfirmDelete}
                title={t('billing.auxTables.deleteTitle')}
                text={`"${selectedEntry?.name}"${t('billing.auxTables.deleteMessage')}`}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BillingAuxTablesContainer;
